import React from "react";
import styles from "./CustomDragPreview.module.css";
import { TypeIcon } from "./TypeIcon";

export const CustomDragPreview = (props) => {
    const item = props.monitorProps.item;
    const colorStyle = item.data?.fileType ? styles[item.data.fileType] : "";
    return (
        <div
            className={`${styles.root} ${colorStyle}`}
            data-testid="custom-drag-preview">
            <div className={styles.icon}>
                <TypeIcon droppable={item.droppable} fileType={item?.data?.fileType} />
            </div>
            <div className={styles.label}>{item.text}</div>
        </div>
    );
};
