import React from "react";
import Collapsible from "../Collapsible";
import WYSIWYGInputform from "../../infolets/credential/archived/WYSIWYGInputformRFF"
import NoteIcon from '@mui/icons-material/EditNote';

const AddNoteForm = ({ collapsibleConfig,useTab, handleError}) => {
  if(!useTab){
  collapsibleConfig.instructions = "Sometimes you just need or want to leave a longer text or note about this credential.  This is for the occasion. ";
  collapsibleConfig.button.label = ""
  collapsibleConfig.button.description = "Optional, for long text note about this credential.";
  collapsibleConfig.button.icon = <NoteIcon/>;
  collapsibleConfig.labels.componentHeader="Note";
  collapsibleConfig.labels.componentLabel="Add Note";
  }
  return (
    <>
   {useTab ? (
        <div>
          <WYSIWYGInputform handleError={handleError} />
        </div>
      ) : (
        <Collapsible config={collapsibleConfig}>
        <WYSIWYGInputform />
      </Collapsible>
      )}
   </>
   
  );
};

export default AddNoteForm;
