import React from "react";
import {
  Box,
  TextField,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { AddCircle, RemoveCircle, Delete } from "@mui/icons-material";
import { Form, Field, useFormState, useForm } from "react-final-form";
import {Util} from "../../../lib/util/Util";

const config = {
  header: {
    title: "Manage Credentials",
    addTooltip: "Add Credential",
    removeAllTooltip: "Remove All Credentials",
    removeTooltip: "Remove Credential",
    emptyMessage: "Click the '+' button to add credential information such as user ID.",
  },
  entriesConfig:{ name: "Name", value: "Value" },
  field: {
    name: "credentials"
  },
  entryLabelPrefix: "Entry",
};
const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 2,
  },
  recordBox: {
    mb: 3,
    p: 2,
    pt: 6,
    border: "1px solid #ccc",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    position: "relative",
  },
  entryLabel: {
    position: "absolute",
    top: -10,
    left: 8,
    background: "#fff",
    px: 1,
  },
  removeIcon: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  emptyMessage: {
    mt: 2,
  },
  removeAllButton: {
    color: "red",
  },
};
export const getConfig = () => {
  return Util.JSON_COPY(config);
}
export const getStyles = () => {
  return Util.JSON_COPY(styles);
}
const KeyValueEntriesWiz = ({ config = getConfig(), styles = getStyles()}) => {
  const { values } = useFormState();
 // const { submitting, pristine, valid, values } = useFormState();
  const form = useForm();
  const fieldsConfig = config.entriesConfig;
  const handleAddRecord = (form) => {
    const currentRecrods = values[config.field.name] || [];
    const newEntry = Object.keys(config.entriesConfig).reduce((acc, key) => {
      acc[key] = ""; // Initialize with empty string or any default value
      return acc;
    }, {});
  
    form.change(config.field.name, [...currentRecrods, newEntry]);
  };

  const handleRemoveRecord = (form, index) => {
    const currentRecrods = values[config.field.name] || [];
    const updatedRecords = currentRecrods.filter((_, i) => i !== index);
    form.change(config.field.name, updatedRecords);
  };

  const handleRemoveAllRecords = (form) => {
    form.change(config.field.name, []);
  };

  const updateRecord = (form, index, field, value) => {
    const currentRecrods = values[config.field.name] || [];
    const updatedRecords = [...currentRecrods];
    updatedRecords[index][field] = value;
    form.change(config.field.name, updatedRecords);
  };

  const generateFormFields = (config, record, index, form) => {

    return Object.keys(config.entriesConfig).map((key) =>  { 

      return (
      <TextField
        key={key}
        label={config.entriesConfig[key]}
        value={record[key] || ""}
        fullWidth
        onChange={(e) =>
          updateRecord(form, index, key, e.target.value)
        }              
       
      />
    )});
  };
  return (
    <Box sx={{ width: "100%" }}>
      {/* Header with Add and Remove All Buttons */}
      {/* Header Section */}
      <Box sx={styles.container}>
        <Typography variant="h6">{config.header.title}</Typography>
        <Box>
          <Tooltip title={config.header.addTooltip}>
            <IconButton onClick={() => handleAddRecord(form)} color="primary">
              <AddCircle />
            </IconButton>
          </Tooltip>
          <Tooltip title={config.header.removeAllTooltip}>
            <IconButton
              onClick={() => handleRemoveAllRecords(form)}
              sx={styles.removeAllButton}
              disabled={!(values[config.field.name] || []).length}
            >
              <RemoveCircle />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

  
      <Field name={config.field.name} subscription={{ value: true }}>
        {() =>{
             //in the future a yup object can pass in to check for error
          return !!values[config.field.name] && values[config.field.name].length > 0 ? (
            values[config.field.name].map((record, index) => (
              <Box key={index} sx={styles.recordBox}>
                {/* Entry Label */}
                <Typography variant="subtitle1" sx={styles.entryLabel}>
                  {`${config.entryLabelPrefix} (${index + 1})`}
                </Typography>

                {/* Delete Button */}
                <Tooltip title={config.header.removeTooltip}>
                  <IconButton
                    onClick={() => handleRemoveRecord(form, index)}
                    color="secondary"
                    sx={styles.removeIcon}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
                {generateFormFields(config, record, index, form)}
              </Box>
            ))
          ) : (
            <Typography variant="body2" color="textSecondary" sx={styles.emptyMessage}>
              {config.header.emptyMessage}
            </Typography>
          )}
        }
      </Field>
    </Box>
  );
};

export default KeyValueEntriesWiz;
