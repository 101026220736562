import serviceTaskRunner, { services, _callRestfulService as restful } from "../lib/restful/services/restAPIs";
import { CryptoTransform } from "../lib/util/ClientUtil";
import sessionData from "../lib/session/sessionData.json"
import { Field, useFormState, useForm } from "react-final-form";
import { SeqCrypto } from "../lib/util/SeqCrypto";
import { useUserSession } from '../lib/session/UserSession';
import {
  getUrlImageType,
  resizeImageDataString,
  base64ToFile,
  reduceImageResolution,
  reduceImageResolutionByHeight
} from "../utils/common";
import { v4 as uuid } from "uuid";

import { ClientMediator } from '@8d-xyz/ui-react_application_f-container';

async function _contextEncrypt(values) {
  let contextEncrypt = null;
  if (!!values.password) {
    let keys = [];
    !!values.secureKey ? keys.push(values.secureKey) : "";
    !!values.challenge && !!values.useChallenge ? keys.push(values.challenge) : "";
    contextEncrypt = await CryptoTransform.SIMPLE_ENCRYPT(values.password, keys);
    values.password = contextEncrypt.ciphertext;
  }
  return contextEncrypt;
}


export const SAVE_ENTITY_IMAGE = async (type, values, callBack) => {

  let urlImageType = await getUrlImageType({urlImage:values.urlImage});
  if (urlImageType != "invalid" && urlImageType.startsWith("url")) {
    //loaded from backend
    //no need to do anything
  } else if (urlImageType != "invalid" && urlImageType.startsWith("dataString")) {
   
    let fileName = type + "_" + values.id;
    let file = base64ToFile(await reduceImageResolution(values.urlImage, 600), fileName)
    const formData = new FormData();
    formData.append('files', file);
    let result = await serviceTaskRunner(services.UPLOAD_FILES)(
      formData, { 'content-type': "multipart/form-data" },
      async (response, callParams) => {
        const data = response.data.payload;
        //console.log("imageUrl");
        //console.log(data[0].url);
        callParams.handleSubmit(data[0].url);
   
        let result = null;
        return data;
      },
      {
        //callBack Parameters
        handleSubmit: callBack
      },
      (error) => {
        //console.log(error)
      }
    );

  }
}
                                          //values, dialogClosing, callBack
export const CREATE_ENTITY = async (type, values, dialogClosing, callBack) => {

  //const { user, logout, login, sessionData, tagEntities, setTagEntities } = useUserSession();
//export const CREATE_ENTITY = async (type, image, id, dialogClosing, callBack) => {

  //if id is available -> update
  //check urlImage if it is url reference or B64 data
  //if url reference image already uploaded
  //if url B64 image needs to be updated: make two calls

  // let values={
  //   urlImage:image,
  //   id: !!id?id:uuid()
  // }
  values.id=!values.id?uuid():values.id;
  //let urlImageType = await getUrlImageType({urlImage:image});
  let urlImageType = await getUrlImageType({urlImage:values.urlImage});
  if (urlImageType != "invalid" && urlImageType.startsWith("url")) {
    //loaded from backend
    //no need to do anything
  } else if (urlImageType != "invalid" && urlImageType.startsWith("dataString")) {
    // needs to upload and replace if needed to
    //if(values.id) exists means replace the image with same urle file name in the if urlImage in db is not blank
    //if(values.id) does not exist means new record create a new entry and get the record id back and use it to further processing
    // values.urlImage = await Promise.all([resizeImageDataString(values.urlImage, 300,300)])[0];
    //values.id = uuid();
    //values.id ="b307e824-0817-4db9-a993-de8d4a285733"

    let fileName = type + "_" + values.id;

    //console.log(fileName)
    // let fileName = values.id;
    let file = base64ToFile(await reduceImageResolution(values.urlImage, 600), fileName)
    const formData = new FormData();
    formData.append('files', file);
    let result = await serviceTaskRunner(services.UPLOAD_FILES)(
      formData, { 'content-type': "multipart/form-data" },
      async (response, callParams) => {
        //callBack function
        //Get return data from the restufl call
        const data = response.data.payload;
        //console.log("data[0][0].url");

        values.urlImage = data[0].url;
        ;
        let result = null;
        //do something
        return data;
      },
      {
        //callBack Parameters
      },
      (error) => {
        //console.log(error)
      }
    );
  
  }

  //all updated

  //encrypt user password 
  //ecryption  password is needed 

  let contextEncrypt = await _contextEncrypt(values);



//alert(user.uid);
  let dataz = await CryptoTransform.TO_BASE64(
    values,
    process.env.APP_ID,
    sessionData.USER_PROFILE.uid);

  if (!!contextEncrypt && contextEncrypt.ivs.length > 0) {
    dataz.ivs = [...dataz.ivs, ...contextEncrypt.ivs]    //add the ivs for client info (securekey and challenge)
  }

  let _AddCredentialTaskRunner = await serviceTaskRunner(services.ADD_ENTITY)(
    type,
    dataz, 
    async (response, callParams) => {
      //callBack function
      //Get return data from the restufl call
      let result = response.data.payload;
      //console.log(result);
      callParams.handleSubmit(result);
      //do something fire event for dialog etc...
    // alert("fire create")
      ClientMediator.broadcastEvent({
        "event_id": `APP-ENTITY_CHANGED`, //EVENT KEY (publihserId+event)
        "payload": {
          "ENTITY": type,
          "ID": result.id,
          "ACTION": "CREATED"
        }
      });
      return result;
    },
    {
      handleSubmit: callBack
    },
    (error) => {
      //console.log(error)
    }
  );

  dialogClosing();
}

export const DELETE_ENTITY = async (entity, callback) => {

  serviceTaskRunner(services.DELETE_ENTITY)(entity.parent.toUpperCase(),
  entity.id, {}, 
    async (response, callParams) => {
        //callBack function
        //Get return data from the restufl call
        const data = response.data.payload;
        let result = null;
        //do something
        //alert("fire delete");
    
        ClientMediator.broadcastEvent({
          "event_id": `APP-ENTITY_CHANGED`, //EVENT KEY (publihserId+event)
          "payload": {
            "ENTITY": data.type,
            "ID":data.id,
            "ACTION":"DELETED"
          }
        });
        return data;
    }, 
    {
    //callBack Parameters
    },
      (error) => {
          //console.log(error)
      }
    );

  callback();
}


export const GET_ORGANIZERS = async (values, callback) => {

  return await serviceTaskRunner(services.GET_ORGANIZERS)(
    values,  
    async (response, callParams) => {
        //callBack function
        //Get return data from the restufl call
        const data = response.data.payload;
        let result = data;
        return result;
    }, 
    {
    //callBack Parameters
    },
      (error) => {
          //console.log(error)
      }
    );


}

export const GET_ENTITY_MEDIA = async (entity_group, file_id, callback) => {

  return  serviceTaskRunner(services.GET_ENTITY_MEDIA)(
    entity_group, file_id, {}, 
    async (response, callParams) => {
       return await callback(response);
    }, 
    {
    //callBack Parameters
    }, 
    (error)=>{
      //console.log(error);
    }
    );
}


export const GET_ORGANIZER_BY_TYPE = async (type, values, callback) => {

  return await serviceTaskRunner(services.GET_ORGANIZER)(type,
    values,  
    async (response, callParams) => {
        //callBack function
        //Get return data from the restufl call
        const data = response.data.payload;
        let result = data;
        return result;
    }, 
    {
    //callBack Parameters
    },
      (error) => {
          //console.log(error)
      }
    );


}

export const SYNC_ENTITY_LIST_ORDER = async (type, from, to, values, callback) => {

  return await serviceTaskRunner(services.SYNC_ENTITY_LIST_ORDER)(
    type, from, to, values, 
    async (response, callParams) => {
        //callBack function
        //Get return data from the restufl call
        const data = response.data.payload;
        let result = data;
        return result;
    }, 
    {
    //callBack Parameters
    }, 
    (error) => {
      //console.log(error)
  }
    );

}
export const GET_ENTITY_RECORD = async (type, id, filter, values, callback) => {
 
  return await serviceTaskRunner(services.GET_ENTITY)(
    type, id, filter, values, 
    async (response, callParams) => {
 
        let result = await CryptoTransform.TO_UTF8(response.data.payload, process.env.APP_ID,
        sessionData.USER_PROFILE.uid);
        return result;
    }, 
    {
    //callBack Parameters
    }, 
    ()=>{}
    );

}
export const UPDATE_ENTITY_RECORD = async (type, values, dialogClosing, callBack) => {

  //if id is available -> update
  //check urlImage if it is url reference or B64 data
  //if url reference image already uploaded
  //if url B64 image needs to be updated: make two calls
  let urlImageType = await getUrlImageType(values);
  //alert(urlImageType);

  if(urlImageType!="invalid" && urlImageType.startsWith("url") ){
    //loaded from backend
    //no need to do anything
  }else if(urlImageType!="invalid" && urlImageType.startsWith("dataString") ){
  // needs to upload and replace if needed to
  //if(values.id) exists means replace the image with same urle file name in the if urlImage in db is not blank
  //if(values.id) does not exist means new record create a new entry and get the record id back and use it to further processing
 // values.urlImage = await Promise.all([resizeImageDataString(values.urlImage, 300,300)])[0];
  //values.id = uuid();
  //values.id ="b307e824-0817-4db9-a993-de8d4a285733"

  let fileName = type+"_"+values.id;

  let file = base64ToFile(await reduceImageResolution(values.urlImage, 600), fileName)
  const formData = new FormData();
    formData.append('files', file); 
    let result = await serviceTaskRunner(services.UPLOAD_FILES)(
      formData,{'content-type':"multipart/form-data"},
      async (response, callParams) => {
          //callBack function
          //Get return data from the restufl call
          const data = response.data.payload;
          //console.log("data[0][0].url");
          
          values.urlImage = data[0].url;
      
         
         let result = null;
            //do something
          return data;
      },
      {
      //callBack Parameters
      },
      (error)=>{
        console.log(error)
      }
      );
}



 let contextEncrypt =  await _contextEncrypt(values);

  let dataz = await CryptoTransform.TO_BASE64(
      values, 
      process.env.APP_ID,
      sessionData.USER_PROFILE.uid);
      if(!!contextEncrypt && contextEncrypt.ivs.length>0){
        dataz.ivs=[...dataz.ivs,...contextEncrypt.ivs]    //add the ivs for client info (securekey and challenge)
        }
  
    await serviceTaskRunner(services.UPDATE_ENTITY)(
          type, values.id, dataz, 
          async (response, callParams) => {

          //callBack function
          //Get return data from the restufl call
          let result = response.data.payload;
          //console.log(result);
          callParams.handleSubmit(result);
          // alert("fire update")
          ClientMediator.broadcastEvent({
            "event_id": `APP-ENTITY_CHANGED`, //EVENT KEY (publihserId+event)
            "payload": {
              "ENTITY": type,
              "ID":result.id,
              "ACTION":"UPDATED"
            }
          });
          //do something fire event for dialog etc...
          return result;
      },
      {
          //callBack Parameters
          handleSubmit: callBack
      },
      (error) => {
          console.log(error)
      }
  );
  dialogClosing();
}



export const CLONE_ENTITY_RECORD = async (type, id, values, callback) => {

   serviceTaskRunner(services.COPY_ENTITY)(
    type, id, values, 
    async (response, callParams) => {
        //callBack function
        //Get return data from the restufl call
        let result = response.data.payload;
          //console.log(result);
          callParams.handleSubmit(result);
          //alert("fire clone")
          ClientMediator.broadcastEvent({
            "event_id": `APP-ENTITY_CHANGED`, //EVENT KEY (publihserId+event)
            "payload": {
              "ENTITY": "PASSWROD",
              "ID":result.id,
              "ACTION":"CLONED"
            }
          });
        //do something
        return result;
    }, 
    {
      handleSubmit: callback
    }, 
    (error) => {
      console.error(error)
  }
);
   


}
