import React, { useState, useEffect, useCallback } from "react";
import { Form,FormSpy,useFormState } from "react-final-form";
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Button,
} from "@mui/material";
import eventDispatcher from "../../events/dispatcher";
import ImageCapture from "../common/form/ImageCapture";
import VerticalTabFormWiz from "../common/form/VerticalTabFormWiz";
import infoletDefs from "./infoletDefs";
import useFormData from "../../lib/util/useFormData";
import {genEntityImageUrl} from "../../utils/common";
export const getStyles = () => {
  return {
    card: {
      maxWidth: 600,
      pt: 0,
      marginTop: "12px",
      marginBottom: "25px",
      backgroundColor: "white",
      boxShadow: "0 2px 10px rgba(6, 81, 237, 0.3)",
      borderRadius: "0.375rem",
      color: "#333",

    },
    box: {
      position: "relative",
      height: 300,
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      border: "2px dashed #ccc",
    },
    boxWithImage: {
      border: "none",
    },
    cardMedia: {
      height: "300px",
      width: "auto",
      p: "20px",
      objectFit: "contain",
    },
    addIcon: {
      fontSize: 80,
      color: "#ccc",
    },
    iconButton: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "#fff",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      },
    },
    errorText: {
      textAlign: "center",
      mt: 1,
    },
    description: {
      mt: 2,
    },
    submitButton: {
      margin: 2,
    },
  };
}

                       

//const Infolet = ({ node, onSubmit, styles, session, forms = [], dataSchema, dataModel }) => {
  const Infolet = ({ node, onSubmit, type, styles=getStyles(), consoleOut=false}) => {
  const infoletConfig = infoletDefs(type);
  const dataModelName = infoletConfig.form.dataModelName;
  const { formData, updateFormData,  getFormValidationCombineSchema } = useFormData(infoletConfig.form.modelDefintion);
  const validate = getFormValidationCombineSchema([dataModelName]).validate;

  const {tagEntities, setTagEntities } = infoletConfig.session;
  const [activeTab, setActiveTab] = useState(0); // Current tab index
  const [errorState, setErrorState] = useState(false);
  const forms= infoletConfig.form.formFields;
  const formImageFieldName= infoletConfig.form.formImageFieldName;
  const [debug, setDebug] = useState(consoleOut);
  const [imageUrl, setImageUrl] = useState(null);
  const [formValues, setFormValues] = useState({}); // ✅ Store form values locally
  styles={...styles,...infoletConfig.form.widget};
  
  const handleNext = () => {
    setActiveTab((prev) => prev + 1);
  };

  const handlePrev = () => {
    // Move to the previous tab
    setActiveTab((prev) => prev - 1);
  };
  const handleError = useCallback((hasError) => {
    setErrorState(hasError);

  }, []);


  
    useEffect(() => {
      const fetchImage = async () => {
        //console.log("Fetching image for node:", node);
        //console.log("image Url", formImageFieldName);
        if (node && node.id && node.data.urlImage === "U") {
          setImageUrl(await genEntityImageUrl( node.parent.toUpperCase(),node.id));

        //  try {
        //     //console.log("Requesting image for", node.id);
        //     const response = await eventDispatcher.GET_ENTITY_MEDIA(
        //       node.parent.toUpperCase(),
        //       node.id,
        //       async (response) => {
        //         //console.log("Received API Response:", response);
        //         if (!response || !response.data) {
        //           console.warn("No image data received from API.");
        //           return;
        //         }

        //         // ✅ Convert response to a Blob
        //         const blob = new Blob([response.data], { type: response.headers["content-type"] });
        //         //console.log("Blob Created:", blob);

        //         // ✅ Generate Blob URL
        //         const url = URL.createObjectURL(blob);
        //         //console.log("Generated Blob URL:", url);
        //         setImageUrl(url); // ✅ Save to state
        //       }
        //     );
        //   } catch (error) {
        //     console.error("Error fetching image:", error);
        //   }
        } else if (formValues[formImageFieldName]) {
      
          setImageUrl(null); // ✅ Use form values if no node
        }
      };

      fetchImage();
    }, [node]); // ✅ Fetch when dependencies change

  useEffect(() => {
    const fetchData = async () => {
      if (!tagEntities) {
        let entities = await eventDispatcher.GET_ORGANIZER_BY_TYPE("tags", {}, () => { });
        setTagEntities(entities);
      }
      if (node?.id) {
        const entityRecord = await eventDispatcher.GET_ENTITY_RECORD(
          node.parent.toUpperCase(),
          node.id,
          "all",
          {},
          () => { }
        );
        updateFormData(dataModelName, entityRecord);
      }
    };

    fetchData(); // Call the function inside useEffect

  }, []); // Dependency array

  return (
    <Form
      initialValues={formData[dataModelName]}
      keepDirtyOnReinitialize={true}
      validate={validate}
      onSubmit={(values) => {
        //console.log("Form submitted with values:", values);
        //update this in case urlImage is changed
       // node.data.urlImage=(values.urlImage==null||values.urlImage==="")?"":"U";
        onSubmit(values);
      }}
      render={({ handleSubmit, submitting, pristine, valid, values }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault();

            // Stop default submission behavior
            //handleSubmit() 
          }}
        > {/* ✅ Tracks form values and updates state */}
        <FormSpy subscription={{ values: true }}>
          {({ values }) => setFormValues(values)}
        </FormSpy>

          <Card sx={styles.card}>
            <CardHeader sx={{ 'padding-left': '25px', 'padding-right': '25px' }}
              title={
                <ImageCapture
                config={{ fieldName: formImageFieldName }}
                urlImage={imageUrl} // ✅ Uses state instead of calling API in JSX
                id={node ? node.id : values.id} 
                styles={styles}
                values={values} 
              />
              }
            >
            </CardHeader>
            <CardContent>
              <VerticalTabFormWiz
                handlePrev={handlePrev}
                handleNext={handleNext}
                handleError={handleError}
                setActiveTab={setActiveTab}
                activeTab={activeTab}
                forms={forms}
              />
              {/* <CardForm /> */}
              <Box sx={{ mt: 2, display: "flex", gap: 2 }}>
                {activeTab > 0 && (
                  <Button variant="outlined" onClick={handlePrev}>
                    Back
                  </Button>
                )}
                {activeTab < forms.length - 1 ? (
                  <Button
                    type="button"
                    variant="contained"
                    disabled={errorState}
                    onClick={() => handleNext()}
                  >
                    Next
                  </Button>
                ) : (

                  <Button
                    type="button" // This prevents auto-submission
                    variant="contained"
                    color="primary"
                    disabled={submitting || pristine || !valid}
                    onClick={() => {
                      //console.log("Submit button clicked");
                      handleSubmit(); // Now, handleSubmit is ONLY called here
                    }}
                  >
                    Submit
                  </Button>
                )}
              </Box>
            </CardContent>
          </Card>
          {debug && 
          <FormSpy subscription={{ pristine: true, values: true }}>
            {({ pristine, submitting, values }) => (<>
              <pre>{JSON.stringify({ pristine, values }, null, 2)}</pre>
              <pre>{JSON.stringify({ submitting, values }, null, 2)}</pre>
              </>
            )}
          </FormSpy>
        
        }
        </form>
      )}
    />
  );
};

export default Infolet;
