import { Typography } from "@material-tailwind/react";
import officeWork from "../../assets/office.jpg";
import digital from "../../assets/digital.webp";
import Footer from "../../components/common/Footer";

export function AboutUs() {
  const features = [
    "Accessible - Use it anywhere, on any device, without restrictions",
    "Secure - Your data is encrypted and private—no intrusive tracking",
    "Simple - An intuitive, hassle-free experience that works for you",
    "Efficient - Save time while staying protected",
  ];

  return (
    <>
   <div className="flex flex-col min-h-screen relative"
  style={{
    backgroundImage: `url(${digital})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: "100%",
  }}
>
  {/* Background Overlay */}
  <div className="absolute inset-0 bg-white opacity-50 z-0"></div>

  {/* Main Content */}
  <main className="flex-1 relative z-10">
    <section className="p-8 flex justify-center text-white">
      <div className="relative mx-auto max-w-screen-md">
        <img
          src={officeWork}
          alt="team work"
          className="mb-4 h-[28rem] w-full rounded-xl object-cover"
        />
        <Typography
          variant="h2"
          color="blue-gray"
          className="my-4 font-black text-4xl !leading-snug"
        >
          About Us
        </Typography>
        <Typography className="font-normal !text-gray-800">
        We believe that securing your digital life has never been more important.
As our world becomes increasingly connected, managing and protecting information has become more challenging than ever.
  
That’s why we built E-DAx — a digital information management system founded on four core principles.
          <ul className="pl-10 mt-10 mb-10 space-y-3">
            {features.map((feature, index) => (
              <li key={index} className="flex items-start gap-3 text-gray-700">
                <span>{feature}</span>
              </li>
            ))}
          </ul>
          Our mission is to ensure that everyone can securely and effortlessly manage their digital life — without complexity or compromise.
We welcome you to join us on this journey.
        </Typography>
      </div>
    </section>
  </main>

 
  
</div>
 {/* Footer (Always at Bottom) */}
<footer className="mt-auto">
    <Footer />
  </footer>
  </>
  );
}

export default AboutUs;
