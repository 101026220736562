/**
 * Converts a string to an ArrayBuffer.
 * @param {string} str - The string to convert.
 * @returns {ArrayBuffer}
 */
function stringToArrayBuffer(str) {
    return new TextEncoder().encode(str);
}

/**
 * Converts an ArrayBuffer to a string.
 * @param {ArrayBuffer} buffer - The ArrayBuffer to convert.
 * @returns {string}
 */
function arrayBufferToString(buffer) {
    return new TextDecoder().decode(buffer);
}

/**
 * Generates a random AES-GCM encryption key.
 * @returns {Promise<CryptoKey>}
 */
async function generateKey() {
    return crypto.subtle.generateKey(
        { name: "AES-GCM", length: 256 },
        true,
        ["encrypt", "decrypt"]
    );
}
async function createCustomKey(keyMaterial) {
    // Check if keyMaterial is a valid Uint8Array and has the correct length
    if (!(keyMaterial instanceof Uint8Array)) {
        throw new Error("Key material must be a Uint8Array.");
    }

    if (keyMaterial.byteLength !== 16 && keyMaterial.byteLength !== 32) {
        throw new Error("AES key must be 128 bits (16 bytes) or 256 bits (32 bytes).");
    }

    // Import the key for AES-GCM
    return await crypto.subtle.importKey(
        "raw",               // Raw key material
        keyMaterial,         // Validated key material
        { name: "AES-GCM" }, // Algorithm name
        true,                // Extractable (can be exported or not)
        ["encrypt", "decrypt"] // Key usages
    );
}
/**
 * Encrypts data using a given AES-GCM key.
 * @param {ArrayBuffer} data - The plaintext or intermediate ciphertext to encrypt.
 * @param {CryptoKey} key - The encryption key.
 * @returns {Promise<{ ciphertext: ArrayBuffer, iv: Uint8Array }>}
 */
async function encrypt(data, key) {
    const iv = crypto.getRandomValues(new Uint8Array(12)); // AES-GCM requires a 12-byte IV
    const ciphertext = await crypto.subtle.encrypt(
        { name: "AES-GCM", iv },
        key,
        data
    );
    return { ciphertext, iv };
}

/**
 * Decrypts data using a given AES-GCM key.
 * @param {ArrayBuffer} ciphertext - The encrypted data to decrypt.
 * @param {CryptoKey} key - The decryption key.
 * @param {Uint8Array} iv - The initialization vector used during encryption.
 * @returns {Promise<ArrayBuffer>}
 */
async function decrypt(ciphertext, key, iv) {
    return crypto.subtle.decrypt(
        { name: "AES-GCM", iv },
        key,
        ciphertext
    );
}

/**
 * Encrypts a message sequentially with four distinct keys.
 * @param {string} message - The plaintext message to encrypt.
 * @param {CryptoKey[]} keys - An array of 4 distinct AES-GCM keys.
 * @returns {Promise<{ ciphertext: string, ivs: string[] }>}
 */
async function encryptSequentially(message, keys) {
    let data = stringToArrayBuffer(message);
    const ivs = [];

    for (const key of keys) {
        const { ciphertext, iv } = await encrypt(data, key);
        data = ciphertext; // Use the output as input for the next step
        ivs.push(btoa(String.fromCharCode(...iv))); // Store the IV for each step
    }

    return {
        ciphertext: btoa(String.fromCharCode(...new Uint8Array(data))), // Final encrypted data
        ivs
    };
}

/**
 * Decrypts a message sequentially with four distinct keys.
 * @param {string} ciphertext - The encrypted message in Base64.
 * @param {CryptoKey[]} keys - An array of 4 distinct AES-GCM keys (reverse order of encryption).
 * @param {string[]} ivs - An array of IVs in Base64 (reverse order of encryption).
 * @returns {Promise<string>} - The decrypted plaintext message.
 */
async function decryptSequentially(ciphertext, keys, ivs) {
  
     let data = Uint8Array.from(atob(ciphertext), c => c.charCodeAt(0)).buffer;
   // let data = new Uint8Array(ciphertext);
    for (let i = keys.length - 1; i >= 0; i--) {
        const iv = Uint8Array.from(atob(ivs[i]), c => c.charCodeAt(0));
        //const iv = new Uint8Array(ivs[i]);
        data = await decrypt(data, keys[i], iv); // Decrypt each step in reverse order
    }

    return arrayBufferToString(data);
}

// Example Usage
// (async () => {
//     // Generate 4 distinct keys
//     const keys = [uid, masterKey, secureKey, challenge ];

//     const password = "mypassword";

//     // Encrypt the message with the four keys sequentially
//     const { ciphertext, ivs } = await encryptSequentially(message, keys);
//     //console.log("Encrypted Ciphertext:", ciphertext);
//     //console.log("Initialization Vectors (IVs):", ivs);

//     // Decrypt the message with the four keys in reverse order
//     const decryptedMessage = await decryptSequentially(ciphertext, keys, ivs);
//     console.log("Decrypted Message:", decryptedMessage);
// })();
// // Example Usage
// (async () => {
//     // Generate 4 distinct keys
//     const keys = await Promise.all([generateKey(), generateKey(), generateKey(), generateKey()]);

//     const message = "This is a sequential encryption test.";

//     // Encrypt the message with the four keys sequentially
//     const { ciphertext, ivs } = await encryptSequentially(message, keys);
//     console.log("Encrypted Ciphertext:", ciphertext);
//     console.log("Initialization Vectors (IVs):", ivs);

//     // Decrypt the message with the four keys in reverse order
//     const decryptedMessage = await decryptSequentially(ciphertext, keys, ivs);
//     console.log("Decrypted Message:", decryptedMessage);
// })();





export const SeqCrypto = {
    KEYGEN: createCustomKey,
    ENCRYPT:encryptSequentially,
    DECRYPT:decryptSequentially
   
}