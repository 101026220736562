import React, { useState, useEffect } from "react";
import _ from "lodash";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { NativeTypes } from "react-dnd-html5-backend";
import {
  Tree,
  MultiBackend,
  getBackendOptions,
  DndProvider,
  getDescendants,
  isAncestor,
  hasChildNodes,
} from "@minoru/react-dnd-treeview";
import { Placeholder } from "./Placeholder";
// import { CustomNode } from "./CustomNode";
//import { CustomNode } from "./EditableCustomNode";
import { CustomNode } from "./XEditableCustomNode";
import { CustomDragPreview } from "./CustomDragPreview";
import { MultipleDragPreview } from "./MultipleDragPreview";
import styles from "./App.module.css";
//import SampleData from "../../../config/organizer_data.json";
import { v4 as uuid } from "uuid";
import eventDispatcher  from "../../../events/dispatcher";
import LoadingDialog, { setMessage } from "../../../demo/platform/WaitDialog";
function  App({...props}) {
  ////console.log(props.nodes)
  const [tree, setTree] = useState([]);
  const [refresh, setRefresh] = useState(null);
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isCtrlPressing, setIsCtrlPressing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    const fetchOrganizerByType = async () => {
      try {
       let data = await props.nodes;
       const entries = Object.entries(data);

       // Sort the entries by the `index` property of the value
       const sortedEntries = entries.sort(([, valueA], [, valueB]) => valueA.index - valueB.index);
       
       // Convert back to an object
       const sortedObject = Object.fromEntries(sortedEntries);

       //console.log(Object.values(sortedObject));

       setTree(Object.values(sortedObject));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchOrganizerByType();
  }, []);


  /*
  * set up multi selected
  */
  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key.toLowerCase() === "escape") {
        setSelectedNodes([]);
      } else if (e.ctrlKey || e.metaKey) {
        setIsCtrlPressing(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key.toLowerCase() === "control" || e.key.toLowerCase() === "meta") {
        setIsCtrlPressing(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const handleDelete = async (node) => {
    setIsLoading(true);
   await eventDispatcher.DELETE_ENTITY(
    node,
    ()=>{ setTree(removeSubtree(tree, node.id))}
  );
  setIsLoading(false);
  };



  // Function to remove subtree
  const removeSubtree = (nodes, nodeIdToRemove) => {
    const nodesToRemove = new Set();

    // Helper function to find the children of a specific node
    const findChildren = (nodeId) => {
      return nodes.filter(node => node.parent === nodeId);
    }

    // Helper function to traverse the tree and collect all nodes to be removed
    const traverseAndCollect = (nodeId) => {
      nodesToRemove.add(nodeId);
      const children = findChildren(nodeId);
      children.forEach(child => traverseAndCollect(child.id));
    }

    // Collect the ids of the subtree starting from the given node
    traverseAndCollect(nodeIdToRemove);

    // Filter out the nodes that are in the subtree
    const filteredNodes = nodes.filter(node => !nodesToRemove.has(node.id));

    return filteredNodes;
  }


  /* 
   *Handle Node Label node 
   */

  const handleTextChange = (id, value) => {
    //setRefresh(true);

    //remove need to loop through large data set of data
    const newTree = tree.map((node) => {
      if (node.id === id) {
        return {
          ...node,
          text: value
        };
      }

      return node;
    });
    setTree(newTree);
  };


  /* 
 *Handle Single Select
 */
  const handleSingleSelect = (node) => {
    setSelectedNodes([node]);
  };
  /* 
 *Handle multiple Selected nodes 
 */
  const handleMultiSelect = (clickedNode) => {
    const selectedIds = selectedNodes.map((n) => n.id);

    // ignore if the clicked node is already selected
    if (selectedIds.includes(clickedNode.id)) {
      return;
    }

    // ignore if ancestor node already selected
    if (
      selectedIds.some((selectedId) =>
        isAncestor(tree, selectedId, clickedNode.id)
      )
    ) {
      return;
    }

    let updateNodes = [...selectedNodes];

    // if descendant nodes already selected, remove them
    updateNodes = updateNodes.filter((selectedNode) => {
      return !isAncestor(tree, clickedNode.id, selectedNode.id);
    });

    updateNodes = [...updateNodes, clickedNode];
    setSelectedNodes(updateNodes);
  };

  const handleClick = (e, node) => {


    //alert(node.id)


    if (e.ctrlKey || e.metaKey) {
      handleMultiSelect(node);
    } else {
      handleSingleSelect(node);
    }
  };


  /* 
 *Handle draging 
 */

  const handleDragStart = (node) => {
    const isSelectedNode = selectedNodes.some((n) => n.id === node.id);
    setIsDragging(true);

    if (!isCtrlPressing && isSelectedNode) {
      return;
    }

    if (!isCtrlPressing) {
      setSelectedNodes([node]);
      return;
    }

    if (!selectedNodes.some((n) => n.id === node.id)) {
      setSelectedNodes([...selectedNodes, node]);
    }
  };

  const handleDragEnd = () => {
    setIsDragging(false);
    setIsCtrlPressing(false);
    setSelectedNodes([]);
  };


  // Function to copy subtree and assign new UUIDs
  const copySubtree = (nodes, nodeIdToCopy, newParentId) => {
    const originalNodes = JSON.parse(JSON.stringify(nodes));
    const subtreeNodes = [];
    const idMap = {};

    // Helper function to find the children of a specific node
    const findChildren = (nodeId) => {
      return originalNodes.filter(node => node.parent === nodeId);
    }

    const traverseAndCopy = (nodeId, currentParentId) => {
      const nodeToCopy = originalNodes.find(node => node.id === nodeId);
      if (!nodeToCopy) return;

      const newId = uuid();
      idMap[nodeId] = newId;

      // Copy the current node and assign the new parent and ID
      subtreeNodes.push({
        ...nodeToCopy,
        id: newId,
        parent: currentParentId,
        text: nodeToCopy.text + " Copy"
      });

      // Process children of the current node
      const children = findChildren(nodeId);
      children.forEach(child => traverseAndCopy(child.id, newId));
    }

    // Start copying from the specified node and attach to the new parent
    traverseAndCopy(nodeIdToCopy, newParentId);
    return subtreeNodes;
  }
  const handleCopy = async (targetNode) => {
    // Example: Copy subtree of "Folder 1" (id: "1") and attach it to new parent "Folder 2" (id: "4")
  
    setIsLoading(true);
    await eventDispatcher.CLONE_ENTITY_RECORD(targetNode.id,targetNode.parent.toUpperCase(),{},async (node)=>{

     // const newSubtree = copySubtree(tree, targetNode.id, targetNode.parent);
      //const newTree = [...tree, ...newSubtree];
      setTree([...tree, node]);
    //  //console.log(newTree);


    });
    setIsLoading(false);
  }

  const handleDrop = async (newTree, options) => {
    const { dropTargetId, monitor,dragSourceId, dragSource, dropTarget,destinationIndex} = options;
    const itemType = monitor.getItemType();
    const entityChildMap = {
      Credentials: "password",
      Users: "user",
      Accounts: "account",
      Groups: "group"
    }

    let newTreeX = null;
    if (NativeTypes.TEXT == itemType) {
      let payload = JSON.parse(monitor.getItem().text);
      let type = payload.type;
      let newNewNodes = null;
      if (entityChildMap[dropTargetId] !== type) return;
        newNewNodes = newTree.map((node) => {
        if (dropTargetId === node.id) {
          return {
            ...node,
            "id": uuid(),
            "parent": node.id,
            "droppable": false,
            "text": "new node",
            "data": {
              "fileType": entityChildMap[node.id],
              "fileSize": "0.5MB"
            }
          }
        }
      }).filter((node) => node != null);
      newTreeX = [...newTree, ...newNewNodes];
    } else {
      let selectedNodeMap={};
      selectedNodes.forEach((node) => {
        return selectedNodeMap[node.id]=node.index
      });
      setIsLoading(true);
       await eventDispatcher.SYNC_ENTITY_LIST_ORDER(
        dragSource.parent.toUpperCase(),
        dragSource.index, 
        destinationIndex, 
        Object.keys(selectedNodeMap), ()=>{}) ;
        setIsLoading(false);
       newTreeX = newTree.map((node) => {
        if (selectedNodes.some((selectedNode) => selectedNode.id === node.id)) {
          let updatedNode = {
            ...node,
            parent: dropTargetId,
          };
          return updatedNode;
        }

        return node;
      });
    }
    setTree(() => newTreeX);
    setSelectedNodes([]);
  };

  return (
<>
      <Tree
        tree={tree} //set the tree
        rootId={"0"} //located the starting position in the tree - this can be optimized by passing in all the children node
        classes={{
          root: styles.treeRoot,
          dropTarget: styles.dropTarget,
          draggingSource: styles.draggingSource,
          placeholder: styles.placeholderContainer,
        }}
        sort={false}
        onDrop={handleDrop}
        onDragEnd={handleDragEnd}
        canDrop={(tree, options) => {
          if (options.dragSource?.parent === options.dropTargetId) {
            return true;
          } else if (
            selectedNodes.some(
              (selectedNode) => selectedNode.id === options.dropTargetId
            )
          ) {
            return false;
          } else if (

            (
              (options.dragSource != null && options.dropTarget != null) &&
              (
                (options.dragSource.parent === "0" && options.dropTarget.parent === "0") //same parent root 
                || ((options.dragSource.data != null && options.dropTarget.data != null) && (options.dragSource.data.fileType != options.dropTarget.data.fileType)) //same type
              )
            )
            ||
            (options.dropTarget == null && options.dropTargetId === "0" && options.dragSource.parent !== "0") //droping between the root nodes
          ) {
            return false;
          }

        }}
        extraAcceptTypes={[NativeTypes.FILE, NativeTypes.TEXT, NativeTypes.HTML]}
        //render a dynamic generated react component
        render={(node, options) => {
          //find the node selected
          const selected = selectedNodes.some(
            (selectedNode) => selectedNode.id === node.id
          );
          return (
            <CustomNode
              node={node}
              tree={tree}
              setTree={setTree}  //pass in tree
              path={props.path}

              {...options}
              isSelected={selected}
              // onSelect={handleSelect}
              isDragging={selected && isDragging}
              onClick={handleClick}
              onTextChange={handleTextChange}
              onDelete={handleDelete}
              onCopy={handleCopy}
            />

          );
        }}
        insertDroppableFirst={false}
        dropTargetOffset={5}
        onDragStart={handleDragStart}
        enableAnimateExpand={true}
        initialOpen={false}
        dragPreviewRender={(monitorProps) => {
          if (selectedNodes.length > 1) {
            return <MultipleDragPreview dragSources={selectedNodes} />;
          }
          return <CustomDragPreview monitorProps={monitorProps} />;
        }}
        placeholderRender={(node, { depth }) => (
          <Placeholder node={node} depth={depth} />
        )}
      />
      <LoadingDialog open={isLoading} message={'Processing, please wait...'} />
   
    </>
  );
}

export default App;
