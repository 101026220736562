import React from "react";
import { Typography, Button } from "@material-tailwind/react";
import { CheckCircle } from "@mui/icons-material";
import digital from "../../assets/digital.webp";
import logo from "../../assets/logo_200.png";
import Footer from "../../components/common/Footer";
import { Link } from "react-router-dom";

export function Splash() {
  const features = [
    "Streamline Your Life: Keep all your data—contacts, accounts, credentials information, etc.—in one centralized, easy-to-access system.",
    "Stay Secure: Protect your sensitive information with top-notch encryption and secure cloud storage with end-to-encryption ensuring peace of mind at all times. Your data stays private, always.",
    "Passwords Not Stored: Your passwords stay with you; they do not come to us, and we don't store your passwords.",
    "No Intrusive Monitoring: Your data stays yours—no tracking and unwanted prompting.",
    "Boost Productivity: Effortlessly manage and search your data with an intuitive interface. Reduce the time spent searching for important details and free yourself to focus on what is more meaningful.",
    "Access Anywhere, Anytime: Have your information across your devices—smartphones, tablets, and laptops—for seamless access.",
    "Save Time: Eliminate the hassle of switching between apps with integrated features.",
    "Reduce Clutter: Organize your life with custom tags, search options, and intuitive categorization, reducing mental overload.",
    // "Long-Term Access: Save important data for long-term access, making it easier to reference past information and documents.",
    "Eco-Friendly: Reduce paper waste by managing your personal information digitally, contributing to a greener future.",
    "Future-Proof: Scalable and adaptable, our system evolves with you, offering new features and integrations as technology advances.",
    "Simple Usage: No hidden fees, no gimmicks.",
  ];

  return (
    <div className="flex flex-col min-h-screen">
      {/* Background section that wraps content height */}
      <section
        className="relative bg-cover bg-center bg-no-repeat  w-full"
        style={{ backgroundImage: `url(${digital})` }}
      >
        {/* Content Overlay, not full-screen */}
        <div className="min-h-screen bg-black bg-opacity-80 w-full h-full flex items-center justify-center">
          <div className="px-6 py-16 w-full max-w-6xl mx-auto">
            <div className="flex justify-center">
              <Link to="/about">
                <img src={logo} alt="About E-Dax" className="mb-10 h-[5rem]" />
              </Link>
            </div>

            <Typography
              variant="h3"
              color="white"
              className="lg:!text-4xl !text-3xl text-center"
            >
              Unlock Simplicity in Securing and Managing Your Information
            </Typography>

            <Typography
              variant="lead"
              className="mb-12 mt-7 mx-auto text-gray-400 w-full max-w-5xl text-center"
            >
              Take control of your information, hassle-free—no commitments, no
              limitations. Just peace of mind. Try our Freemium Subscription—on us!
            </Typography>

            <div className="mb-10 mt-3 mx-auto text-white w-full">
              <ul className="space-y-3 text-left px-4 sm:px-6 md:px-12 lg:px-24">
                {features.map((feature, index) => (
                  <li
                    key={index}
                    className="flex items-start gap-3 text-gray-400"
                  >
                    <CheckCircle
                      className="text-orange-700 mt-1"
                      fontSize="medium"
                    />
                    <span>{feature}</span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex justify-center">
              <Button color="red" className="w-full max-w-xs">
                <Link to="/plans">Compare Plans</Link>
              </Button>
            </div>
          </div>
        </div>
      </section>

      {/* Footer always sticks to the bottom of the page */}
      <footer className="bg-black text-white mt-auto">
        <Footer />
      </footer>
    </div>
  );
}

export default Splash;
