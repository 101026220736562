
import { useApiRequest, apiService, setPathParameters } from "@8d-xyz/ui-react_restful_service-lib";
import sessionData from "../../session/sessionData.json";
let USER_ID_TOKEN = sessionData.SESSION?.authToken;
let APP_VERSION=process.env.APP_VERSION
let APP_ID=process.env.APP_ID
let APP_TOKEN=process.env.APP_TOKEN
let APP_NAME=process.env.APP_NAME

const customRefreshTokenFn = async () => "custom token refresh function";
const getSessionData =  ()=>{
  return sessionData;
}
const apiServiceInstance = new apiService({
  baseURL: process.env.API_BASE_URL, // Main API base URL
  refreshBaseURL: process.env.API_TOKEN_REFRESH_TOKEN_BASE_URL, // Different base URL for refresh token
  refreshTokenEndpoint: process.env.API_ACCESS_REFRESH_TOKEN_ENDPONT, // Refresh token endpoint
  retryCount: process.env.API_RETRIES, // Retry up to 2 times
  retryInterval: process.env.API_RETRY_INTERVAL, // 1.5 seconds between retries
  refreshTokenFn: customRefreshTokenFn, // Custom refresh token function
  session: getSessionData,
  headers: {
    "x-app-version": `${APP_VERSION}`,
    "x-app-id": `${APP_ID}`,
    "x-app-token": `${APP_TOKEN}`,
    "x-app-name": `${APP_NAME}`
  }
 
});
 

//Generate the code based from the api meta definition file
//TODO: Add link to the code gen document
const apiEndpoints = {
  GET_TOKEN_BY_ID: process.env.API_TOKEN_GET_TOKEN_BY_ID_ENDPONT,
  CREATE_NEW_ENTRY: process.env.CREATE_NEW_ENTRY_ENDPOINT,
  GET_NEW_ENTRY: process.env.GET_NEW_ENTRY_ENDPOINT,
  UPDATE_NEW_ENTRY: process.env.UPDATE_NEW_ENTRY_ENDPOINT,
  DELETE_NEW_ENTRY: process.env.DELETE_NEW_ENTRY_ENDPOINT,
  UPLOAD_FILES: process.env.UPLOAD_FILES_ENDPOINT,

  SIGN_UP: process.env.SIGN_UP_ENDPOINT,
  SIGN_IN: process.env.SIGN_IN_ENDPOINT,
  ACCOUNT_RECOVER: process.env.ACCOUNT_RECOVER_ENDPOINT,
  UPDATE_ACCOUNT_PROFILE: process.env.UPDATE_ACCOUNT_PROFILE_ENDPOINT,



  ADD_ENTITY: process.env.ADD_ENTITY_ENDPOINT,
  DELETE_ENTITY: process.env.DELETE_ENTITY_ENDPOINT,
  UPDATE_ENTITY: process.env.UPDATE_ENTITY_ENDPOINT,
  GET_ENTITY: process.env.GET_ENTITY_ENDPOINT,
  GET_ENTITY_LIST: process.env.GET_ENTITY_LIST_ENDPOINT,
  GET_ORGANIZER: process.env.GET_ORGANIZER_ENDPOINT,
  GET_ORGANIZERS: process.env.GET_ORGANIZERS_ENDPOINT,
  SYNC_ENTITY_LIST_ORDER: process.env.SYNC_ENTITY_LIST_ORDER_ENDPOINT,
  COPY_ENTITY: process.env.COPY_ENTITY_ENDPOINT,
  GET_ENTITY_MEDIA: process.env.GET_ENTITY_MEDIA_ENDPOINT,
  
  STRIPE_CALL_BACK: process.env.STRIPE_CALL_BACK_ENDPOINT,
  CUSTOMER_PORTAL: process.env.CUSTOMER_PORTAL_ENDPOINT,
  CUSTOMER_CHECKOUT: process.env.CUSTOMER_CHECKOUT_ENDPOINT,

}
export const _setPathParameters = setPathParameters;
export { apiEndpoints, apiServiceInstance };
