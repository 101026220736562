import React from 'react';
import { Field, Form } from 'react-final-form';
import * as Yup from 'yup';
import useFormData from "../../lib/util/useFormData";
import testCentralizedDataModel from "../../config/CentralizedDataModel"
import { useUserSession } from '../../lib/session/UserSession';


let config = {
    "form": {
      "name": "Contact Us",
      "email": "Email",
      "subject": "Subject",
      "message": "Message",
      "submitButton": "Send"
    },
    "placeholder": {
      "name": "Enter your name",
      "email": "Enter your email",
      "subject": "Enter subject",
      "message": "Enter your message"
    },
    "errorMessages": {
      "required": "This field is required",
      "invalidEmail": "Invalid email format"
    },
    "styles": {
      "formContainer": {
        "marginTop": "1rem",
        "marginBottom": "1.5rem"
      },
      // "formGrid": {
      //   "display": "grid",
      //   "gridTemplateColumns": "1fr 1fr",
      //   "gap": "4rem",
      //   "padding": "2rem",
      //   "maxWidth": "4xl",
      //   "margin": "0 auto",
      //   "backgroundColor": "white",
      //   "boxShadow": "0 2px 10px rgba(6, 81, 237, 0.3)",
      //   "borderRadius": "0.375rem",
      //   "color": "#333",
      //   "fontFamily": "sans-serif"
      // },
      "formGrid": {
        "display": "grid",
        "gridTemplateColumns": "1fr",
        "gap": "4rem",
        "padding": "2rem",
        "paddingTop": "1rem",
        "maxWidth": "4xl",
        "margin": "0 auto",
        "backgroundColor": "white",
        "boxShadow": "0 2px 10px rgba(6, 81, 237, 0.3)",
        "borderRadius": "0.375rem",
        "color": "#333",
        "fontFamily": "sans-serif"
      },
      "heading": {
        "fontSize": "2rem",
        "fontWeight": "800"
      },
      "subHeading": {
        "fontSize": "0.875rem",
        "color": "#b0b0b0",
        "marginTop": "0.75rem"
      },
      "input": {
        "width": "100%",
        "padding": "0.625rem 1rem",
        "borderRadius": "0.375rem",
        "border": "1px solid #ccc",
        "fontSize": "0.875rem",
        "outlineColor": "#007bff"
      },
      "textarea": {
        "width": "100%",
        "padding": "0.625rem 1rem",
        "borderRadius": "0.375rem",
        "border": "1px solid #ccc",
        "fontSize": "0.875rem",
        "outlineColor": "#007bff"
      },
      "button": {
        "backgroundColor": "#007bff",
        "color": "white",
        "fontWeight": "600",
        "borderRadius": "0.375rem",
        "padding": "0.625rem 1rem",
        "width": "100%",
        "cursor": "pointer"
      },
      "buttonHover": {
        "backgroundColor": "#0062cc"
      },
      "errorText": {
        "color": "#f44336",
        "fontSize": "0.875rem",
        "marginTop": "0.25rem"
      }
    }
  };
const schema = Yup.object().shape({
  name: Yup.string().required(config.errorMessages.required),
  email: Yup.string().email(config.errorMessages.invalidEmail).required(config.errorMessages.required),
  subject: Yup.string().required(config.errorMessages.required),
  message: Yup.string().required(config.errorMessages.required)
});

const ContactForm = ({dialogOn}) => {
  const dataModelName = "contactInfo";
  const { formData, updateFormData,  getFormValidationCombineSchema } = useFormData(testCentralizedDataModel);
  let contactUsEmail =`mailto: ${process.env.CONTACT_US_EMAIL}`;
  const validate = getFormValidationCombineSchema([dataModelName]).validate;
  const onSubmit = async (values) => {
    // Handle form submission here
    //console.log('Form Submitted', values);
    alert(JSON.stringifyvalues);
    dialogOn((prev)=>false);
  };

  return (
    <div style={config.styles.formContainer}>
      <div style={config.styles.formGrid}>
        {/* <div> */}
          {/* <h1 style={config.styles.heading}>{config.form.name}</h1> */}
          <p style={config.styles.subHeading}>
          Need help? Want to share your ideas, needs, or suggestions?
          Please reach out to us directly at <a href={contactUsEmail}>{process.env.CONTACT_US_EMAIL}</a>! 
            </p>
         
          {/* Add other content here if needed */}
        {/* </div> */}

        {/* <Form
          initialValues={formData[dataModelName]}
          keepDirtyOnReinitialize={true}
          onSubmit={onSubmit}
          //  validate={yupResolver(schema)}
          validate={validate}
          
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <form onSubmit={
                handleSubmit
              } className="ml-auto space-y-4">
              <Field name="name">
                {({ input, meta }) => (
                  <div>
                    <input
                      {...input}
                      type="text"
                      placeholder={config.placeholder.name}
                      style={config.styles.input}
                    />
                    {meta.touched && meta.error && <span style={config.styles.errorText}>{meta.error}</span>}
                  </div>
                )}
              </Field>

              <Field name="email">
                {({ input, meta }) => (
                  <div>
                    <input
                      {...input}
                      type="email"
                      placeholder={config.placeholder.email}
                      style={config.styles.input}
                    />
                    {meta.touched && meta.error && <span style={config.styles.errorText}>{meta.error}</span>}
                  </div>
                )}
              </Field>

              <Field name="subject">
                {({ input, meta }) => (
                  <div>
                    <input
                      {...input}
                      type="text"
                      placeholder={config.placeholder.subject}
                      style={config.styles.input}
                    />
                    {meta.touched && meta.error && <span style={config.styles.errorText}>{meta.error}</span>}
                  </div>
                )}
              </Field>

              <Field name="message">
                {({ input, meta }) => (
                  <div>
                    <textarea
                      {...input}
                      placeholder={config.placeholder.message}
                      rows="6"
                      style={config.styles.textarea}
                    />
                    {meta.touched && meta.error && <span style={config.styles.errorText}>{meta.error}</span>}
                  </div>
                )}
              </Field>

              <button
                type="submit"
                disabled={submitting || pristine}
                style={config.styles.button}
                onMouseEnter={(e) => e.target.style.backgroundColor = config.styles.buttonHover.backgroundColor}
                onMouseLeave={(e) => e.target.style.backgroundColor = config.styles.button.backgroundColor}
              >
                {config.form.submitButton}
              </button>
            </form>
          )}
        /> */}
      </div>
    </div>
  );
};

export default ContactForm;
