const privacyPolicy = `
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            color: #333;
        }
        h1 {
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 22px;
            font-weight: bold;
            margin-top: 30px;
            margin-bottom: 10px;
            border-bottom: 2px solid #ddd;
            padding-bottom: 5px;
        }
        p {
            font-size: 16px;
            margin-bottom: 15px;
        }
        ul {
            padding-left: 25px;
            margin-bottom: 15px;
        }
        li {
            margin-bottom: 8px;
        }
        a {
            color: #0078d4;
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
        }
        hr {
            border: 0;
            height: 1px;
            background: #ddd;
            margin: 20px 0;
        }
    </style>
<p/>
    <p><strong>Effective Date:</strong> March 16, 2025</p>
    <p><strong>Last Updated:</strong> March 16, 2025</p>

    <p>Welcome to <strong>E-Dax</strong> (“Service”), a personal information management application provided by <strong>Ireva Corp.</strong> (“Company”, “we”, “us”, or “our”).</p>

    <p>We respect your privacy. This Privacy Policy explains how we collect, use, share, and protect your personal information when you use our Service.</p>

    <hr />

    <h2>1. Information We Collect</h2>
    <p>We collect different types of personal data when you use our Service:</p>
    <ul>
        <li><strong>Account Information:</strong> Your name, email address, and login credentials.</li>
        <li><strong>Personal Data:</strong> Any data you voluntarily store in the application, such as notes, contacts, or reminders.</li>
        <li><strong>Usage Data:</strong> Information about your interactions with the Service, including logs, timestamps, and device information.</li>
        <li><strong>Cookies and Tracking Technologies:</strong> We may use cookies, web beacons, and similar technologies to improve functionality and user experience.</li>
        <li><strong>Payment Information:</strong> If you purchase a subscription, we may collect payment details (processed securely by third-party providers).</li>
    </ul>

    <h2>2. How We Use Your Information</h2>
    <p>We use your personal data for the following purposes:</p>
    <ul>
        <li>To provide, operate, and maintain the Service.</li>
        <li>To personalize your experience and improve usability.</li>
        <li>To enhance security, detect fraud, and prevent unauthorized access.</li>
        <li>To comply with legal and regulatory obligations.</li>
        <li>To send important service updates, security alerts, and transactional emails.</li>
    </ul>

    <h2>3. How We Share Your Information</h2>
    <p>We do not sell your personal data. However, we may share it under the following conditions:</p>
    <ul>
        <li><strong>With Service Providers:</strong> Third-party vendors assisting in analytics, security, and payment processing.</li>
        <li><strong>For Legal Reasons:</strong> If required by law, legal process, or governmental request.</li>
        <li><strong>During Business Transfers:</strong> If our company undergoes a merger, acquisition, or asset sale.</li>
    </ul>

    <h2>4. How Long We Retain Your Data</h2>
    <p>We retain your data for as long as necessary to provide the Service or comply with legal requirements. You may request deletion of your data at any time.</p>

    <h2>5. Your Rights and Choices</h2>
    <p>Depending on your location, you may have the following rights:</p>
    <ul>
        <li><strong>Access and Correction:</strong> Request access to your personal data and correct inaccuracies.</li>
        <li><strong>Data Deletion:</strong> Request deletion of your personal information, subject to legal retention obligations.</li>
        <li><strong>Opt-Out:</strong> Manage your marketing preferences or opt out of non-essential data collection.</li>
    </ul>

    <h2>6. Security of Your Information</h2>
    <p>We implement industry-standard security measures to protect your data, including encryption and access controls. However, no system is completely secure, and users should take precautions to protect their accounts.</p>

    <h2>7. Cookies and Tracking Technologies</h2>
    <p>We use cookies and similar tracking technologies to enhance user experience. You can manage your cookie preferences through your browser settings.</p>

    <h2>8. Children's Privacy</h2>
    <p>Our Service is not intended for children under 13. If we learn that we have collected personal data from a child under 13, we will delete it.</p>

    <h2>9. Third-Party Links and Services</h2>
    <p>Our Service may include links to third-party websites or integrations. We are not responsible for their privacy practices and encourage you to review their policies.</p>

    <h2>10. Changes to This Privacy Policy</h2>
    <p>We may update this Privacy Policy periodically. If we make significant changes, we will notify users through the Service or via email.</p>

    <h2>11. Contact Information</h2>
    <p>If you have any questions about this Privacy Policy, please contact us:</p>
    <p><strong>Email:</strong> <a href="mailto:contact_us@e-dax.com">support@[yourcompany].com</a></p>
    <p><strong>Address:</strong>3370 Prince Street #CA12, Flushing, NY 11354</p>

    <h2>Acknowledgment</h2>
    <p>By using <strong>E-Dax</strong>, you acknowledge that you have read, understood, and agreed to this Privacy Policy.</p>
`;

export default privacyPolicy;
