import {
    CREATE_ENTITY, 
    DELETE_ENTITY,
    GET_ORGANIZERS,
    GET_ORGANIZER_BY_TYPE,
    SYNC_ENTITY_LIST_ORDER,
    GET_ENTITY_RECORD,
    UPDATE_ENTITY_RECORD,
    CLONE_ENTITY_RECORD,
    SAVE_ENTITY_IMAGE,
    GET_ENTITY_MEDIA,


} from "./entityManagementHandler";

import {
    UPLOAD_USER_AVATAR,
    UPDATE_ACCOUNT_USER_PROFILE
} from "./userManagementHandler";

import {
    LOGIN,
    SIGNUP,
    PASSWORD_RESET
} from "./userAccessHandler"

import {
    PORTAL_URL,
    WEBHOOK,
    CHECKOUT_URL
} from "./subscriptionHandlers"

export default {
    ADD_ENTITY: CREATE_ENTITY,
    DELETE_ENTITY:DELETE_ENTITY,
    GET_ORGANIZERS: GET_ORGANIZERS,
    GET_ORGANIZER_BY_TYPE:GET_ORGANIZER_BY_TYPE,
    SYNC_ENTITY_LIST_ORDER:SYNC_ENTITY_LIST_ORDER,
    GET_ENTITY_RECORD:GET_ENTITY_RECORD,
    UPDATE_ENTITY_RECORD:UPDATE_ENTITY_RECORD,
    CLONE_ENTITY_RECORD:CLONE_ENTITY_RECORD,
    SAVE_ENTITY_IMAGE:SAVE_ENTITY_IMAGE,
    UPLOAD_USER_AVATAR:UPLOAD_USER_AVATAR,
    UPDATE_ACCOUNT_USER_PROFILE:UPDATE_ACCOUNT_USER_PROFILE,
    LOGIN: LOGIN,
    SIGNUP: SIGNUP,
    PASSWORD_RESET:PASSWORD_RESET,
    BILLING_PORTAL_LINK: PORTAL_URL,
    CHECKOUT_URL: CHECKOUT_URL,
    GET_ENTITY_MEDIA:GET_ENTITY_MEDIA,
}