import React from 'react';
import {
  CircularProgress,
  Box,
  Typography,
  Dialog,
  DialogContent
} from '@mui/material';
import ConstructionIcon from '@mui/icons-material/Construction';

let prompt = "processing";
export const setMessage = (msg) => {
  prompt = msg;
};

const LoadingDialog = ({
  open,
  message = "Please wait, processing...",
  imageSrc = null,
  mode = "loading" // new prop: 'loading' or 'maintenance'
}) => {
  setMessage(message);

  const renderVisual = () => {
    if (imageSrc) {
      return (
        <Box
          component="img"
          src={imageSrc}
          alt="Indicator"
          sx={{ width: 60, height: 60, objectFit: "contain", mb: 2 }}
        />
      );
    }

    if (mode === "maintenance") {
      return (
        <><ConstructionIcon sx={{ fontSize: 60, color: "#f57c00", mb: 2 }} /> Edax is Getting an Upgrade</>
      );
    }

    // default: loading spinner
    return <CircularProgress />;
  };

  return (
    <Dialog open={open} PaperProps={{ style: { borderRadius: 8 } }} sx={{ zIndex: 10000 }}>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
          {renderVisual()}
          <Typography variant="body1" marginTop={2} textAlign="center">
            {prompt}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
