import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Box, Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const DialogWrapper = ({
  mode = 'dialog',
  open = false,
  onClose = () => { },
  children,
  title = 'Title',
  styles = {}
}) => {
  const defaultStyles = {
    dialog: {
      fullWidth: true,
      maxWidth: 'sm',
      p: 0,
      m: 0,

    },
    titleBox: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      P: 0
    },
    closeButton: {
      position: 'absolute',
      color: (theme) => theme.palette.grey[500],
      right: -15,
      top: -10,
    },
    content: {
      m: 0,
      p: 0
    },
    inlineContainer: {
      p: 0,
      m: 0
    },
  };

  const mergedStyles = {
    dialog: { ...defaultStyles.dialog, ...styles.dialog },
    titleBox: { ...defaultStyles.titleBox, ...styles.titleBox },
    closeButton: { ...defaultStyles.closeButton, ...styles.closeButton },
    content: { ...defaultStyles.content, ...styles.content },
    inlineContainer: { ...defaultStyles.inlineContainer, ...styles.inlineContainer },
  };


  if (mode === 'dialog') {

    return (
      <Dialog
        open={open}
        onClose={(event, reason) => {
          //console.log("Dialog closed. Reason:", reason);
          if (reason === "backdropClick") return; // Ignore backdrop clicks
          handleClose();
        }}
        PaperProps={{
          sx: { borderRadius: "16px" }, // Adjust border radius here
        }}
        fullWidth={mergedStyles.dialog.fullWidth}
        maxWidth={mergedStyles.dialog.maxWidth}
        disableEnforceFocus
        disableAutoFocus
      >
        {/* DialogTitle with zero padding */}
        <DialogTitle sx={{ pb: 0, pt: 0, mb: 0, minHeight: "auto" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
              p: 0, // Remove padding
              m: 0, // Remove margins
            }}
          >

            <div style={{ alignSelf: "flex-start", margin: 0, padding: 0, paddingTop: 18 }}>
              <Typography variant="inherit" color="info.darker"  >
                {title}
              </Typography>
              {/* Close button remains at the top right */}
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  top: 10,
                  right: 12,

                }}
              ><CloseIcon />
              </IconButton>
            </div>



          </Box>
        </DialogTitle>

        {/* Completely remove padding between title and content */}
        <DialogContent sx={{ pt: 0, mt: 0, pb: 0 }}>{children}</DialogContent>
      </Dialog>

    );
  }

  return <Box sx={{ border: 1 }}>{children}</Box>;
};

DialogWrapper.propTypes = {
  mode: PropTypes.oneOf(['dialog', 'inline']).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  styles: PropTypes.shape({
    dialog: PropTypes.object,
    titleBox: PropTypes.object,
    closeButton: PropTypes.object,
    inlineContainer: PropTypes.object,
  }),
};

export default DialogWrapper;
