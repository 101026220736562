import { useRef, useState } from "react";

export function isMobileDevice() {
  return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export default function useCameraCapture() {
  const [stream, setStream] = useState(null);
  const [photo, setPhoto] = useState(null);
  const [facingMode, setFacingMode] = useState("environment");
  const [showStartButton, setShowStartButton] = useState(false);
  const videoRef = useRef(null);

  const stopStream = () => {
    if (stream) {
      stream.getTracks().forEach((track) => track.stop());
      setStream(null);
    }
  };

  const startCapture = async () => {
    stopStream();

    if (isMobileDevice()) {
      try {
        const newStream = await navigator.mediaDevices.getUserMedia({
          video: { facingMode },
        });
        setStream(newStream);
        setShowStartButton(true);
        if (videoRef.current) {
          videoRef.current.srcObject = newStream;
        }
        return true;
      } catch (err) {
        console.error("Camera access error:", err);
        return false;
      }
    } else {
      try {
        const newStream = await navigator.mediaDevices.getDisplayMedia({
          video: true,
        });
        const video = document.createElement("video");
        video.srcObject = newStream;
        video.onloadedmetadata = () => {
          video.play();
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
          const imageData = canvas.toDataURL("image/png");
          setPhoto(imageData);
          newStream.getTracks().forEach((track) => track.stop());
        };
        return true;
      } catch (err) {
        console.error("Screen capture error:", err);
        return false;
      }
    }
  };

  const triggerPlayback = () => {
    if (videoRef.current) {
      videoRef.current.load();
      videoRef.current.play().then(() => {
        setShowStartButton(false);
      }).catch((err) => {
        console.error("Video playback failed:", err);
      });
    }
  };

  const takePhoto = () => {
    if (!videoRef.current) return;
    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const dataUrl = canvas.toDataURL("image/png");
    setPhoto(dataUrl);
    stopStream();
  };

  const toggleCamera = () => {
    setFacingMode((prev) => (prev === "environment" ? "user" : "environment"));
    startCapture();
  };

  return {
    videoRef,
    photo,
    stream,
    facingMode,
    showStartButton,
    startCapture,
    stopStream,
    takePhoto,
    toggleCamera,
    triggerPlayback,
  };
}
