import { apiEndpoints, _setPathParameters, apiServiceInstance as apiService } from "./apiService";

import logger from 'loglevel';
logger.setLevel('debug');

const _callCreateNewEntry = async (values) => {
  return await apiService.post(_setPathParameters(apiEndpoints.CREATE_NEW_ENTRY, {}), values);
};

const _callGetNewEntry = async (entryId) => {
  return await apiService.get(_setPathParameters(apiEndpoints.GET_NEW_ENTRY, { entryId }));
};

const _callUpdateNewEntry = async (entryId, values) => {
  return await apiService.put(_setPathParameters(apiEndpoints.UPDATE_NEW_ENTRY, { entryId }), values);
};

const _callDeleteNewEntry = async (values) => {

  return await apiService.delete(_setPathParameters(apiEndpoints.DELETE_NEW_ENTRY, {}), values);
};


const _callUploadFiles = async (values, headers) => {
  //console.log(values);
  //console.log(headers);
  //console.log(_setPathParameters(apiEndpoints.UPLOAD_FILES, {}))
  return await apiService.post(_setPathParameters(apiEndpoints.UPLOAD_FILES, {}), values, headers);
};



export const _callRestfulService = (restful) => {
  switch (restful) {
    case "CREATE_NEW_ENTRY": return _callCreateNewEntry;
    case "GET_NEW_ENTRY": return _callGetNewEntry;
    case "UPDATE_NEW_ENTRY": return _callUpdateNewEntry;
    case "DELETE_NEW_ENTRY": return _callDeleteNewEntry;
    case "UPLOAD_FILES": return _callUploadFiles;
    case "SIGN_UP": return _callSignUp;
    case "SIGN_IN": return _callSignIn;
    case "ACCOUNT_RECOVER": return _callAccountRecover;
    case "UPDATE_ACCOUNT_PROFILE": return _callUpdateAccountProfile;



    case "ADD_ENTITY": return _callAddEntity;
    case "DELETE_ENTITY": return _callDeleteEntity;
    case "UPDATE_ENTITY": return _callUpdateEntity;
    case "GET_ENTITY": return _callGetEntity;
    case "GET_ENTITY_LIST": return _callGetEntityList;
    case "GET_ORGANIZER": return _callGetOrganizer;
    case "GET_ORGANIZERS": return _callGetOrganizers;
    case "SYNC_ENTITY_LIST_ORDER": return _callSyncEntityListOrder;
    case "COPY_ENTITY": return _callCopyEntity;
    case "GET_ENTITY_MEDIA": return _callGetEntityMedia;


    case "STRIPE_CALL_BACK": return _callStripeCallBack;
    case "CUSTOMER_PORTAL": return _callCustomerPortal;
    case "CUSTOMER_CHECKOUT": return _callCustomerCheckout;
    default: return "SERVICE NOT IMPLEMENTED";
  }
}


// RESTful Service Call Methods

const _callSignUp = async (values) => {
  return await apiService.post(
    _setPathParameters(apiEndpoints.SIGN_UP, {}), values
  );
};

const _callSignIn = async (values) => {
  return await apiService.post(
    _setPathParameters(apiEndpoints.SIGN_IN, {}), values
  );
};

const _callAccountRecover = async (values) => {
  return await apiService.post(
    _setPathParameters(apiEndpoints.ACCOUNT_RECOVER, {}), values
  );
};

const _callUpdateAccountProfile = async (values) => {
  return await apiService.put(
    _setPathParameters(apiEndpoints.UPDATE_ACCOUNT_PROFILE, {}), values
  );
};








const _callAddEntity = async (type, values) => {
  return await apiService.post(
    _setPathParameters(apiEndpoints.ADD_ENTITY, { type }), values
  );
};

const _callDeleteEntity = async (type, id, values) => {
  return await apiService.delete(
    _setPathParameters(apiEndpoints.DELETE_ENTITY, { type, id }), values
  );
};

const _callUpdateEntity = async (type, id, values) => {
  return await apiService.put(
    _setPathParameters(apiEndpoints.UPDATE_ENTITY, { type, id }), values
  );
};

const _callGetEntity = async (type, id, filter, values) => {
  return await apiService.get(
    _setPathParameters(apiEndpoints.GET_ENTITY, { type, id, filter })
  );
};

const _callGetEntityList = async (pagesize, pagenumber, values) => {
  return await apiService.get(
    _setPathParameters(apiEndpoints.GET_ENTITY_LIST, { pagesize, pagenumber })
  );
};


const _callSyncEntityListOrder = async (type, from, to, values) => {
  return await apiService.put(
    _setPathParameters(apiEndpoints.SYNC_ENTITY_LIST_ORDER, { type, from, to }), values
  );
};

const _callCopyEntity = async (id, type, values) => {
  return await apiService.post(
    _setPathParameters(apiEndpoints.COPY_ENTITY, { id, type }), values
  );
};

const _callGetOrganizer = async (type, values) => {
  return await apiService.get(
    _setPathParameters(apiEndpoints.GET_ORGANIZER, { type })
  );
};

const _callGetOrganizers = async (values) => {
  return await apiService.get(
    _setPathParameters(apiEndpoints.GET_ORGANIZERS, {})
  );
};




const _callStripeCallBack = async (values) => {
  return await apiService.post(
    _setPathParameters(apiEndpoints.STRIPE_CALL_BACK, {}), values
  );
};

const _callCustomerPortal = async (values) => {
  return await apiService.post(
    _setPathParameters(apiEndpoints.CUSTOMER_PORTAL, {}), values
  );
};

const _callCustomerCheckout = async (values) => {
  return await apiService.post(
    _setPathParameters(apiEndpoints.CUSTOMER_CHECKOUT, {}), values
  );
};

const _callGetEntityMedia = async (entity_group, file_id, values) => {
  
  return await apiService.get(
    _setPathParameters(apiEndpoints.GET_ENTITY_MEDIA,{ entity_group, file_id }), { responseType: "arraybuffer" } 
  );
};

//enum services -> restService (callRestfulService) or any other services
export const services = {
  CREATE_NEW_ENTRY: "CREATE_NEW_ENTRY",
  GET_NEW_ENTRY: "GET_NEW_ENTRY",
  UPDATE_NEW_ENTRY: "UPDATE_NEW_ENTRY",
  DELETE_NEW_ENTRY: "DELETE_NEW_ENTRY",


  UPLOAD_FILES: "UPLOAD_FILES",
  SIGN_UP: "SIGN_UP",
  SIGN_IN: "SIGN_IN",
  ACCOUNT_RECOVER: "ACCOUNT_RECOVER",
  UPDATE_ACCOUNT_PROFILE: "UPDATE_ACCOUNT_PROFILE",




  ADD_ENTITY: "ADD_ENTITY",
  DELETE_ENTITY: "DELETE_ENTITY",
  UPDATE_ENTITY: "UPDATE_ENTITY",
  GET_ENTITY: "GET_ENTITY",
  GET_ENTITY_LIST: "GET_ENTITY_LIST",
  GET_ORGANIZER: "GET_ORGANIZER",
  GET_ORGANIZERS: "GET_ORGANIZERS",
  SYNC_ENTITY_LIST_ORDER: "SYNC_ENTITY_LIST_ORDER",
  COPY_ENTITY: "COPY_ENTITY",
  GET_ENTITY_MEDIA:"GET_ENTITY_MEDIA",
  
  STRIPE_CALL_BACK: "STRIPE_CALL_BACK",
  CUSTOMER_PORTAL: "CUSTOMER_PORTAL",
  CUSTOMER_CHECKOUT: "CUSTOMER_CHECKOUT",
}



export const pathParameters = {
  CREATE_NEW_ENTRY: "CREATE_NEW_ENTRY",
  GET_NEW_ENTRY: "GET_NEW_ENTRY",
  UPDATE_NEW_ENTRY: "UPDATE_NEW_ENTRY",
  DELETE_NEW_ENTRY: "DELETE_NEW_ENTRY",


  UPLOAD_FILES: "UPLOAD_FILES",
  SIGN_UP: "SIGN_UP",
  SIGN_IN: "SIGN_IN",
  ACCOUNT_RECOVER: "ACCOUNT_RECOVER",

  ADD_CREDENTIAL: "ADD_CREDENTIAL",
  DELETE_CREDENTIAL: "DELETE_CREDENTIAL",
  UPDATE_CREDENTIAL: "UPDATE_CREDENTIAL",
  GET_CREDENTIAL: {
    "id": "id",
    "accessInfo": "accessInfo"
  },

  GET_ORGANIZER: "GET_ORGANIZER",
  GET_ORGANIZERS: "GET_ORGANIZERS",
  SYNC_CREDENTIAL_LIST_ORDER: "SYNC_CREDENTIAL_LIST_ORDER",
  COPY_CREDENTIAL: "COPY_CREDENTIAL",
  GET_ENTITY_MEDIA: "GET_ENTITY_MEDIA"
}


const restfulTasks = (serviceId) => {
  switch (serviceId) {
    case "CREATE_NEW_ENTRY":
      return async (values, callBack, callParams, errorHandler) => {
        try {
          let response = await _callRestfulService(services.CREATE_NEW_ENTRY)(values)
          return callBack(response, callParams);
          //do some work here
        } catch (error) {
          return errorHandler(error);
        }
      };

    case "GET_NEW_ENTRY":
      return async (entryId, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.GET_NEW_ENTRY)(entryId), callParams);
          //do some work here
        } catch (error) {
          return errorHandler(error);
        }
      };
    case "UPDATE_NEW_ENTRY":
      return async (entryId, values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.UPDATE_NEW_ENTRY)(entryId, values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };

    case "DELETE_NEW_ENTRY":
      return async (values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.DELETE_NEW_ENTRY)(values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };
    case "UPLOAD_FILES":
      return async (values, headers, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.UPLOAD_FILES)(values, headers), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };
    case "SIGN_UP":
      return async (values, headers, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.SIGN_UP)(values, headers), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };

    case "SIGN_IN": ;
      return async (values, headers, callBack, callParams, errorHandler) => {
        try {

          return callBack(await _callRestfulService(services.SIGN_IN)(values, headers), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };

    case "ACCOUNT_RECOVER":
      return async (values, headers, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.ACCOUNT_RECOVER)(values, headers), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };

    case "UPDATE_ACCOUNT_PROFILE":
      return async (values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.UPDATE_ACCOUNT_PROFILE)(values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };



    case "ADD_ENTITY":
      return async (type, values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.ADD_ENTITY)(type, values), callParams);
        } catch (error) {
          //console.log(error)
          return errorHandler(error);
        }
      };
    case "DELETE_ENTITY":
      return async (type, id, values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.DELETE_ENTITY)(type, id, values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };
    case "UPDATE_ENTITY":
      return async (type, id, values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.UPDATE_ENTITY)(type, id, values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };
    case "GET_ENTITY":
      return async (type, id, filter, values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.GET_ENTITY)(type, id, filter, values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };
    case "GET_ENTITY_LIST":
      return async (pagesize, pagenumber, values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.GET_ENTITY_LIST)(pagesize, pagenumber, values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };
    case "GET_ORGANIZER":
      return async (type, values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.GET_ORGANIZER)(type, values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };
    case "GET_ORGANIZERS":
      return async (values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.GET_ORGANIZERS)(values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };
    case "SYNC_ENTITY_LIST_ORDER":
      return async (type, from, to, values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.SYNC_ENTITY_LIST_ORDER)(type, from, to, values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };
    case "COPY_ENTITY":
      return async (id, type, values, callBack, callParams, errorHandler) => {
        try {
          return callBack(await _callRestfulService(services.COPY_ENTITY)(id, type, values), callParams);
        } catch (error) {
          return errorHandler(error);
        }
      };
      case "STRIPE_CALL_BACK": 
      return async (values, callBack, callParams, errorHandler) => {
          try {
            return callBack(await _callRestfulService(services.STRIPE_CALL_BACK)(values),callParams);
          } catch (error) {
              return errorHandler(error);
          }
        };
          case "CUSTOMER_PORTAL": 
      return async (values, callBack, callParams, errorHandler) => {
          try {
            return callBack(await _callRestfulService(services.CUSTOMER_PORTAL)(values),callParams);
          } catch (error) {
              return errorHandler(error);
          }
        };
        case "CUSTOMER_CHECKOUT": 
        return async (values, callBack, callParams, errorHandler) => {
            try {
              return callBack(await _callRestfulService(services.CUSTOMER_CHECKOUT)(values),callParams);
            } catch (error) {
                return errorHandler(error);
            }
          };
          case "GET_ENTITY_MEDIA": 
          return async (entity_group, file_id, values, callBack, callParams, errorHandler) => {
              try {
                return callBack(await _callRestfulService(services.GET_ENTITY_MEDIA)(entity_group, file_id, values),callParams);
              } catch (error) {
                  return errorHandler(error);
              }
            };
          

    default: return "No Service Found";
  }
};

export default restfulTasks;
