import React,{useState}from "react";
import { Typography } from "@material-tailwind/react";
import Contact from "../pages/ContactDialog";
export default function Footer() {
  const [showContactUs, setShowContactUs]=useState(false);
  return (<footer className=" pl-4 pr-4 z-10 flex w-full flex-row flex-wrap items-center justify-center gap-y-6 gap-x-12 py-2 text-center md:justify-between bg-gray-900">
       <Typography color="white" className="font-normal">
        {new Date().getFullYear()}&nbsp;&copy;{process.env.FOOTER_ENTITY_COPY_LABEL}
      </Typography>
      <ul className="flex flex-wrap items-center gap-y-2 gap-x-8">
        <li>
          <Typography
            as="a"
            href="/about"
            color="white"
            className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500"
          >
            About Us
          </Typography>
        </li>
        <li>
          <Typography
            onClick={() => setShowContactUs(!showContactUs)}
            color="white"
            className="font-normal transition-colors hover:text-blue-500 focus:text-blue-500 rounded-2xl hover:cursor-pointer"
          >
            Contact Us
          </Typography>
          <Contact showContactUs={showContactUs} setShowContactUs={setShowContactUs} />
        </li>
      </ul>
 
  </footer>
  );
}