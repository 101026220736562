import React from "react";
import { TextField, IconButton, InputAdornment,Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Field, useFormState, useForm } from "react-final-form";
import {Util} from "../../../lib/util/Util";
// Configuration object to hold all literals
const config = {
  fieldName: "fieldName",
  label: "Enter something",
  fullWidth: true,
  margin: "normal",
  variant: "outlined",
  adornmentPosition: "end",
  iconSize: "small",
  clearTip: "Clear Field",
  readOnly: false
};
const styles = {
  backgroundColor: "#FFFFFF", // Input background
  borderRadius: "4px", // Input border radius
  pr: "10px", // Adjust padding
};
export const getConfig=()=>{
  return  Util.JSON_COPY(config);
}
export const getStyles=()=>{
  return  Util.JSON_COPY(styles);
}
const CustomTextField = ({config=getConfig(), styles=getStyles(), handleError}) => {

  const { values } = useFormState();
  const form = useForm();
  
  return (
    <Field name={config.fieldName} keepDirtyOnReinitialize={true}   subscription={{ value: true, pristine: true, error: true, touched: true }}>
      {({ input, meta }) => {
        if(handleError){
        handleError(meta.error);
        }
        return (
          <TextField
            {...input}
            label={config.label}
            fullWidth={config.fullWidth}
            margin={config.margin}
            error={!!meta.error && meta.touched}
            helperText={meta.touched && meta.error}
            variant={config.variant}
            
            InputProps={{
              readOnly: config.readOnly,
              style: {...styles},
              endAdornment: !!values?.[config.fieldName] && (
                <InputAdornment position={config.adornmentPosition}>
                  <Tooltip title={config.clearTip}>
                  <IconButton
                     onClick={() => {
                      form.batch(() => {
                        form.change(config.fieldName, "");
                      });
                    }}
                    edge={config.adornmentPosition}
                    size={config.iconSize}
                  >
                    <CloseIcon />
                  </IconButton>
                  </Tooltip>
                </InputAdornment>
              )
              
            }}
          />
          
        );
      }}
    </Field>
  );
};

export default CustomTextField;
