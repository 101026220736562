import React, { useState, useRef } from "react";
import { v4 as uuid } from "uuid";
import dataModels from "../../../config/CentralizedDataModel"
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import IconButton from "@mui/material/IconButton";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";


import { TypeIcon } from "./TypeIcon";
import { AddDialog } from "./AddDialog";
import ToggleMenu from "./ToggleMenu";
import DialogWrapper from "../../common/DialogWrapper";
import Infolet, { getStyles as getInfoletStyles } from "../../infolets/Infolet";
//import AccountAddCard, { getStyles as getAccountAddCardStyles } from "../../infolets/account/AccountAddCard";
import UserAddCard, { getStyles as getUserAddCardStyles } from "../../infolets/user/archived/UserAddCard";

import GroupAddCard, { getStyles as getGroupAddCardStyles } from "../../infolets/group/archived/GroupAddCard";

//import ContactAddCard, { getStyles as getContactAddCardStyles } from "../../infolets/contact/archived/ContactAddCard";

import AccessInfoWiz from "../../infolets/credential/AccessInfoWiz"
import serviceTaskRunner, { services, _callRestfulService as restful } from "../../../lib/restful/services/restAPIs";
import { CryptoTransform } from "../../../lib/util/ClientUtil";
//added editibility
import AddIcon from '@mui/icons-material/AddCircleOutline';
//import AddIcon from "@mui/icons-material/QueueSharp";
// import EditIcon from "@mui/icons-material/DriveFileRenameOutline";
// import { syncNavigation, syncAdd } from "./NavigatorUtil";
import { useDragOver, getDescendants } from "@minoru/react-dnd-treeview";
import eventDispatcher from "../../../events/dispatcher";
import styles from "./CustomNode.module.css";
import CardPassword from "../draggable/cardPassword";
import CredentialViewCardRFF from "../../infolets/credential/archived/CredentialViewCardRFF"

import LoadingDialog, { setMessage } from "../../../demo/platform/WaitDialog";
import { useUserSession } from '../../../lib/session/UserSession';


export const CustomNode = ({ testIdPrefix = "", ...props }) => {
    const [hover, setHover] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const { id, droppable, data, text } = props.node; //add text in as property
    const [open, setOpen] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [openViewRecord, setViewRecord] = useState(false);
    const { session } = useUserSession();
    //handle editiblity

    const [editEtnryLabelForm, setEditEntryLabel] = useState(false);

    const [entryLabelText, setEntryLabelText] = useState(text);


    const stylesx = {
        card: {
            maxWidth: 600,
            margin: "auto",
            mt: 5,
        },
        box: {
            position: "relative",
            height: 300,
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            border: "2px dashed #ccc",
        },
        boxWithImage: {
            border: "none",
        },
        cardMedia: {
            height: "300px",
            width: "auto",
            p: "20px",
            objectFit: "contain",
        },
        addIcon: {
            fontSize: 80,
            color: "#ccc",
        },
        iconButton: {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            color: "#fff",
            "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.8)",
            },
        },
        errorText: {
            textAlign: "center",
            mt: 1,
        },
        description: {
            mt: 2,
        },
        submitButton: {
            margin: 2,
        },
    };


    const indent = props.depth * 24;
    const anchorRef = useRef(null);


    const handleClick = (e) => {
        props.onClick(e, props.node);
    };


    const handleToggle = (e) => {

        e.stopPropagation();
        props.onToggle(props.node.id); //added for edibiity
    };

    //handle entry lable change
    const handleShowEntryLabelTextInput = () => {
        setEditEntryLabel(true);
    };



    const handleEntryLabelTextCancel = () => {
        setEditEntryLabel(false);
        setHover(false);
    };

    const handleEntryLabelTextChange = (e) => {
        setEntryLabelText(e.target.value);
        setHover(false);
    };

    const handleEntryLabelTextSubmit = () => {
        setEditEntryLabel(false);
        props.node.text = entryLabelText;
        setHover(false);
    };
    const viewRecordInfo = (node) => {
        setViewRecord(true);
    }
    const editRecord = (node) => {
        //  alert(node.data.fileType.toUpperCase())
        setOpenEdit(true);
        // switch (node.data.fileType.toUpperCase()) {
        //     case "PASSWORD": alert("Open Credentials: " + node.id);
        // }
    }

    const dragOverProps = useDragOver(id, props.isOpen, props.onToggle);
    //-------------------

    if (props.isSelected) {
        props.containerRef.current?.classList.add(styles.selected);
    } else {
        props.containerRef.current?.classList.remove(styles.selected);
    }

    if (props.isDragging) {
        props.containerRef.current?.classList.add(styles.dragging);
    } else {
        props.containerRef.current?.classList.remove(styles.dragging);
    }
    //------------------------



    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
        setOpenEdit(false);
        setViewRecord(false)
        setHover(false);
    };

    const handleAddSubmit = (newNode) => {

        //console.log(newNode)
        // let addedNode = {
        //     ...newNode,
        //     id: uuid()
        // };
        props.setTree([...props.tree, newNode]);
        setOpen(false);
        setHover(false);


    };
    const handleUpdateSubmit = (newNode) => {

        const newTree = props.tree.map((oldNode) => {
            if (oldNode.id === newNode.id) {
                return {
                    ...newNode
                };
            }

            return oldNode;
        });
        props.setTree([...newTree]);
        setOpen(false);
        setHover(false);


    };
    const opMeunLabel = (operation, nodeType) => {
        if(operation==="add"){
        switch (nodeType) {
            case "Credentials": return "Add New Credential";
            case "Contacts": return "Add New Contact";
            case "Accounts": return "Add New Account";
            case "Groups": return "Add New Group";
            case "Users": return "Add New Users";
       
        }
        }
        if(operation==="edit"){
         switch (nodeType) {
            case "Credentials": return "Edit Credential";
            case "Contacts": return "Edit Contact";
            case "Accounts": return "Edit Account";
            case "Groups": return "Edit Group";
            case "Users": return "Edit Users";
       
        }
        }

        return null
    }
    return (
        <div
            className={styles.root}
            style={{ paddingInlineStart: indent }}
            data-testid={`${testIdPrefix}custom-node-${id}`}
            onClick={handleClick}
            {...dragOverProps} //added for edibility
            onMouseEnter={() => {
                setHover(true);
            }} //add hover ability
            onMouseLeave={() => {
                setHover(false);
            }} //add hover ability
        >
            <div
                className={`${styles.expandIconWrapper} ${props.isOpen ? styles.isOpen : ""
                    }`}
            >
                {props.node.droppable && (
                    <div onClick={handleToggle}>
                        <ArrowRightIcon />
                    </div>
                )}
            </div>
            <div className={`${props.node.data?.fileType ? styles[props.node.data.fileType] : ""} m-1`}>
                <TypeIcon
                    droppable={droppable}
                    resourceType={data?.resourceType}
                    tagletType={data?.tagletType}
                    assetId={data?.filassetIdeType}
                    fileType={data?.fileType}
                />
            </div>
            <div className={styles.labelGridItem}>

                {editEtnryLabelForm ? (
                    <div className={styles.inputWrapper}>
                        <TextField
                            className={`${styles.textField}  ${styles.nodeInput}`}
                            value={entryLabelText}
                            onChange={handleEntryLabelTextChange}
                        />
                        <IconButton
                            className={styles.editButton}
                            onClick={handleEntryLabelTextSubmit}
                            disabled={entryLabelText === ""}
                        >
                            <CheckIcon className={styles.editIcon} />
                        </IconButton>
                        <IconButton className={styles.editButton} onClick={handleEntryLabelTextCancel}>
                            <CloseIcon className={styles.editIcon} />
                        </IconButton>
                    </div>
                ) : (
                    <div className={styles.hooverWrapper}>
                        <Typography
                            ref={anchorRef}
                            variant="body2"
                            className={styles.nodeLabel}
                        >
                            {props.node.text}
                        </Typography>
                        {opMeunLabel("add",props.node.text) &&

                            <IconButton

                                style={{
                                    color: hover ? "black" : "gray",
                                    transition: "color 0.3s ease",
                                }}

                            >
                                <AddIcon onClick={
                                    () => {
                                        setOpen(true);
                                        // handleCloseDialog();

                                    }}
                                    fontSize="small" />
                            </IconButton>
                        }
                        {open && (
                            <DialogWrapper
                                mode="dialog"
                                open={true}
                                onClose={handleCloseDialog}
                                title={opMeunLabel("add",props.node.text)}
                            >   
                               <Infolet
                                    type={props.node.text}
                                    onSubmit={
                                        async (values) => { 
                                            setIsLoading(true);                          
                                            await eventDispatcher.ADD_ENTITY(props.node.text.toUpperCase(), values, handleCloseDialog, handleAddSubmit);
                                            setIsLoading(false); 
                                        }
                                    }
                                     />
                                    
                                
                            </DialogWrapper>

                        )}
                        {hover && !opMeunLabel("add",props.node.text) && ( //show the menu dots icon
                            <>
                                <ToggleMenu
                                    nodeId={id}
                                    node={props.node}
                                    onDelete={props.onDelete}
                                    onCopy={props.onCopy}
                                    onAdd={setOpen}
                                    onInfo={viewRecordInfo}
                                    //onEdit={handleShowEntryLabelTextInput}
                                    onEdit={editRecord}
                                    setHover={setHover}
                                />
                            </>
                        )}
                        {openViewRecord && !opMeunLabel(props.node.text) && ( //show the menu dots icon
                            <>
                                <DialogWrapper
                                    mode="dialog"
                                    open={true}
                                    onClose={handleCloseDialog}
                                    title={opMeunLabel("none",props.node.text)}
                                    style={ {
                                        dialog: {
                                          fullWidth: true,
                                          maxWidth: 'sm',
                                          p: 0,
                                          m: 0,
                                    
                                        },
                                        titleBox: {
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          position: 'relative',
                                          P: 0
                                        },
                                        closeButton: {
                                          position: 'absolute',
                                          color: (theme) => theme.palette.grey[500],
                                          right: -15,
                                          top: -10,
                                        },
                                        content: {
                                          m: 0,
                                          p: 0
                                        },
                                        inlineContainer: {
                                          p: 0,
                                          m: 0
                                        },
                                      }}
                                >

                                    {/* <CredentialViewCardRFF styles={stylesx}/> */}
                                    <AccessInfoWiz
                                        credential={props.node}
                                        handleError={() => { }}
                                        onClose={handleCloseDialog}
                                        
                                    />
                                </DialogWrapper>
                            </>
                        )}
                        {openEdit && (
                            <DialogWrapper
                                mode="dialog"
                                open={true}
                                onClose={handleCloseDialog}
                                title={opMeunLabel("edit",props.node.parent)}
                            >
                           
                             <Infolet
                                    node={props.node}
                                    type={props.node.parent}
                                    onSubmit={
                                        async (values) => {
                                           // alert(JSON.stringify(values))
                                           setIsLoading(true); 
                                            let newNode = await eventDispatcher.UPDATE_ENTITY_RECORD(props.node.parent.toUpperCase(),values, handleCloseDialog, handleUpdateSubmit);
                                            setIsLoading(false); 
                                          
                                           // props.setTree(newTree);

                                        }}
                                    styles={getInfoletStyles()} />
                            
                            </DialogWrapper>

                        )}
                    </div>
                )}
            </div>
            <LoadingDialog open={isLoading} message={'Processing, please wait...'} />
        </div>
    );
};
