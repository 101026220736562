import dataModels from "../../config/CentralizedDataModel"
import { useUserSession } from '../../lib/session/UserSession';


import { 
  SyncLock as SyncLockIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  AddLink as AddAccessURL,
  SwitchAccount as AssociateToAccount,
  AvTimer as ExpirationInDays,
  Style as Tags,
  ContactEmergency as AdditionalInfo,
  EditNote as NoteIcon,
  DocumentScanner as SecureAccount,
  Pin as PIN,
  DataObject as AccountBackup,
  AddLocationAlt as AddLocationAltIcon,
  PsychologyAlt as PsychologyAltIcon,
  AttachEmail as AttachEmailIcon,
  AddIcCall as AddIcCallIcon
} from "@mui/icons-material";


import Instruction from "../common/form/Instruction";
import NameForm, { getConfig as getNameFormConfig, getStyles as getNameFormStyles } from "../common/form/CustomTextField";
import KeyValueEntriesWiz,{getConfig as getKeyValueConfig, getStyles as  getKeyValueStyles}from "../common/form/KeyValueEntriesWiz";
import AddressWiz,{getConfig as getAddressWizConfig, getStyles as getAddressWizStyles} from "../common/form/AddressWiz"
import TagsForm from "../common/form/TagsWiz";
import AddNoteForm from "../common/form/AddNoteForm";




export const getStyles = () => {
  return {
    card: {
      maxWidth: 600,
      margin: "auto",
      pt: 0
    },
    box: {
      position: "relative",
      height: 300,
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      border: "2px dashed #ccc",
    },
    boxWithImage: {
      border: "none",
    },
    cardMedia: {
      height: "300px",
      width: "auto",
      p: "20px",
      objectFit: "contain",
    },
    addIcon: {
      fontSize: 80,
      color: "#ccc",
    },
    iconButton: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "#fff",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      },
    },
    errorText: {
      textAlign: "center",
      mt: 1,
    },
    description: {
      mt: 2,
    },
    submitButton: {
      margin: 2,
    },
  };
}

const handleError=(hasError) => {
  //console.log(hasError);
};

const InfoletDef =  () => {
  const entityType= "USERS";
  const dataModelName = 'userWidget';
  const userSession = useUserSession();
  const {tagEntities, setTagEntities } = userSession;
  let definition= {
    type: entityType,
    session:userSession,
    form:{
        modelDefintion: dataModels,
        widget:{
            styles:getStyles
        },
        dataModelName:dataModelName,
        formImageFieldName:"urlImage",
        formFields:[
          {
            toolTip: "Required - name for this person",
            icon: <BadgeOutlinedIcon />,
            form: <NameForm config={{
              fieldName: "name",
              label: "Enter Name",
              fullWidth: true,
              margin: "normal",
              variant: "outlined",
              adornmentPosition: "end",
              iconSize: "small",
              clearTip: "Clear Field"
            }} styles={getNameFormStyles()} handleError={handleError} />
          },
          {
            toolTip: "Required - Enter the URL associated to this account.",
            icon: <AddAccessURL />,
            form: <UrlForm config={
              {
                ...getUrlFormConfig(),
                fieldName: "url",
                label: "Group Access Url"
              }
            } 
              styles={getUrlFormStyles()} handleError={handleError} />
          },
          {
            toolTip: "Required - Entering the account number for this acount",
            icon: <SecureAccount/>,
            form:  <CustomSecuredTextField config={{...{
              fieldName: "accountNumber",
              label: "Enter ID Number",
              inputType: {
                text: "text",
                password: "password",
              },
              tooltip: {
                showSecureKey: "Show Group Number",
                hideSecureKey: "Hide Group Number",
                clearSecureKey: "Clear Group Number",
              },
              style: {
                textField: { mt: 2 },
              },
              adornmentPosition: "end",
            }}} styles={getCustomTextFieldStyles()}/>
          },
      
          {
            toolTip: "Required - Entering the PIN number for this account",
            icon: <PIN/>,
            form:  <CustomSecuredTextField 
            config={{
              fieldName: "pin",
              label: "Enter Pin Number",
              inputType: {
                text: "text",
                password: "password",
              },
              tooltip: {
                showSecureKey: "Show PIN  Number",
                hideSecureKey: "Hide PIN Number",
                clearSecureKey: "Clear PIN Number",
              },
              style: {
                textField: { mt: 2 },
              },
              adornmentPosition: "end",
            }} styles={getCustomSecuredTextFieldStyles()}/>
          },
      
          {
            toolTip: "Optional, Enter account backup code associated to this account",
            icon: <AccountBackup />,
            form:  <CustomTextAreaField config={{
              fieldName:"accountBackupCodes",
              labels: {
                textarea: "Group Backup Codes",
              },
              placeholders: {
                textarea: "Copy your account backup codes here",
              }
            }} styles={{
              container: {
                display: "flex",
                flexDirection: "column",
                gap: 0,
               
                padding: "10px",
                margin: "0 auto",
              },
              textarea: {
                width: "100%",
                fontSize: "16px",
                resize: "vertical",
             
              },
              textareaError: {
                border: "2px solid red",
              },
              errorText: {
                mt: 1,
              }
            }} />
          },
      
         
          {
            toolTip: "Optional, enter any addresses associated to this account i.e. home mailing, resident etc.",
            icon: <AddLocationAltIcon />,
            form:  <AddressWiz styles={getAddressWizStyles()}
            />
           
           },
          
          {
            toolTip: "Optional, any emails associated to this account",
            icon: <AttachEmailIcon />,
            form:  <KeyValueEntriesWiz config={{
              header: {
                title: "Emails",
                addTooltip: "Add Email Address",
                removeAllTooltip: "Remove All Email Addresses",
                removeTooltip: "Remove Email Address",
                emptyMessage: "Click the '+' button to add email.",
              },
              entriesConfig:{ name: "Name", value: "Email Address" },
              field: {
                name: "accountEmailAddresses",
              },
              entryLabelPrefix: "Email ",
            }} styles={getKeyValueStyles()}/>
            
          },
         {                     
            toolTip: "Optional, to help you keep track of other essential info.",
            icon: <Tags />,
            form:  <TagsForm handleError={handleError} tags={Object.values(tagEntities).map((entry) => {
              return entry.TAG;
            })}/>,
            howto:<Instruction config={{
              "text": "Choose relevant and easy-to-remember tags to keep everything organized. For example, you can use a bank name as a tag. To add a tag manually, type your tag in the field, then click the + button or press Enter to tag this credential. Alternatively, you can browse the Available Tags list and click on any tag to apply it to your credential.",
            }}/>
            
         },
          {
            toolTip: "Optional, enter any telephone numbers associate to this account.",
            icon: <AddIcCallIcon />,
            form:  <KeyValueEntriesWiz config={ {
              header: {
                title: "Telephone Lines",
                addTooltip: "Add Telephone",
                removeAllTooltip: "Remove All Telphone Lines",
                removeTooltip: "Remove telephone line",
                emptyMessage: "Click the '+' button to add telephone line.",
              },
              entriesConfig:{ name: "Name", value: "Number" },
              field: {
                name: "accountTelephoneLines",
              },
              entryLabelPrefix: "Line ",
            }} styles={getKeyValueStyles()}/>
            
          },
          {
            toolTip: "Optional, to help you keep track of other essential info.",
            icon: <AdditionalInfo />,
            form:   <KeyValueEntriesWiz config={ {
              header: {
                title: "Addition Informaiton",
                addTooltip: "Additional Group Info. Entry",
                removeAllTooltip: "Remove All Group Info. Entries",
                removeTooltip: "Remove Group Info Entry",
                emptyMessage: "Click the '+' button to add account information entry.",
              },
              entriesConfig:{ name: "Name", value: "Information" },
              field: {
                name: "accountNoteEntries",
              },
              entryLabelPrefix: "Entry #",
            }} styles={getKeyValueStyles()}/>
            
          },
          {
            toolTip: "Optional, to help you keep track of other essential info.",
            icon: <NoteIcon />,
            form:  <AddNoteForm handleError={handleError} useTab={true}/>
          }
        ]
       
    },
    displayDef:{
      keyLabelMap:{
          "useChallenge": "Using Challeng",
          "credentials": "Key Value Pairs Info.",
          "note":"Note",
          "url":"URL",
          "name":"Name",
          "tags":"Tabs",
          "challenge":"Challenge Question",
          "accountEmailAddresses":"Email Addresses"
          }, 
        ignoreKeys:["urlImage","id","ivs","securedPassword","useChallenge"],
        orderKeys:["name","url","useChallenge","challenge","credentials","tags","note"]
  }
   
};
 
  return definition;
};

export default InfoletDef;
