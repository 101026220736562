import React from "react";
import { Card, CardContent, Typography, Divider, Chip, Stack, Box} from "@mui/material";
import Grid2 from "@mui/material/Grid2";

import DOMPurify from "dompurify";
import { JavascriptOutlined } from "@mui/icons-material";

// Utility function to escape HTML
const escapeHTML = (str) => {
  const div = document.createElement("div");
  div.textContent = str;
  return div.innerHTML;
};

// Handle arrays of objects with ordering
const handleArrayOfObjects = (array, displayDef, childDef) => {

  switch (childDef._type.toUpperCase()) {
    case "MAP": return (
      array.length > 0 ? (
        array.map((item, index) => (

          <div key={index}>
            <Grid container spacing={2} sx={{
              padding: "20px", paddingBottom: "20px", border: 1, borderRadius: "8px", margin: '10px'
            }}>
              {Object.entries(item)
                .filter(([key]) => !childDef[key]?.ignore && key !== "_type") // Ignore fields based on displayDef
                .sort(([a], [b]) => childDef[a]?.order - childDef[b]?.order) // Apply order from displayDef
                .map(([subKey, subValue], subIndex) => {
                  const childConfig = childDef[subKey] || {}; // Get the child configuration for the current key
                  return (
                    <React.Fragment key={index}>
                      <Grid container spacing={2} alignItems="center">
                        <Grid xs={6} sx={{ textAlign: "right", paddingRight: 1 }}>
                          <Typography key={subIndex} variant="body2" fontWeight="bold">{escapeHTML(childConfig.label)}</Typography>
                        </Grid>
                        <Grid xs={6} sx={{ textAlign: "left", paddingLeft: 1 }}>
                          <Typography key={subIndex} variant="body2">
                            {subValue === "" || subValue === null ? "(Empty)" : escapeHTML(subValue)}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Divider sx={{ marginY: 0, width: "100%" }} />
                    </React.Fragment>
                  );
                })}
            </Grid>

          </div>
        )
        )

      ) : (<Typography variant="body2" color="text.secondary">(Empty)</Typography>)



    );
    case "CHIP": return (
      <Stack direction="row" spacing={1} flexWrap="wrap">
        {array.length > 0 ? (
          array.map((item, index) => (
            <Chip key={index} label={escapeHTML(item)} />
          ))
        ) : (
          <Typography variant="body2" color="text.secondary">(Empty)</Typography>
        )}
      </Stack>
    )

  }

};

// Recursive function to render fields, including nested objects and arrays
const FieldDisplay = ({ label, value, isEven, displayDef, childDef }) => {
  const backgroundColor = isEven ? "#f9f9f9" : "#ffffff"; // Alternating background colors

  // If the value is an object, handle it based on its type
  if (typeof value === "object") {
    if (Array.isArray(value)) {
      // For arrays, display the label for the parent and then the array's elements
      return (
        <div style={{ backgroundColor, padding: "8px", borderRadius: "4px" }}>
          <Typography variant="subtitle1" gutterBottom>
            {label}
          </Typography>
          {handleArrayOfObjects(value, displayDef, childDef)}
        </div>
      );
    } else {
      // Handle nested objects
      return (
        <div style={{ backgroundColor, padding: "8px", borderRadius: "4px" }}>
          <Typography variant="subtitle1" gutterBottom>
            {label}
          </Typography>
          { (value!=null&&value!="" )?Object.entries(value)
            .filter(([key]) => !displayDef[key]?.ignore) // Ignore fields based on displayDef
            .sort(([a], [b]) => displayDef[a]?.order - displayDef[b]?.order) // Apply order from displayDef
            .map(([nestedKey, nestedValue], index) => {
              const childConfig = childDef[nestedKey] || {}; // Get the child configuration for the current key
              return (
                <FieldDisplay
                  key={nestedKey}
                  label={childConfig.label || nestedKey} // Use the label from child configuration
                  value={nestedValue}
                  isEven={index % 2 === 0}
                  displayDef={displayDef}
                  childDef={childConfig} // Pass down the child configuration for the nested field
                />
              );
            }):(
              <Typography variant="body2" color="text.secondary">(Empty)</Typography>
            )
          
          }
        </div>
      );
    }
  }

  // For non-object values, display normally
  return (
    <div style={{ backgroundColor, padding: "8px", borderRadius: "4px" }}>
      <Typography variant="subtitle1" gutterBottom>
        {label}
      </Typography>
      {typeof value === "string" && /<([a-z][a-z0-9]*)\b[^>]*>(.*?)<\/\1>/i.test(value) ? (
        <div
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(value),
          }}
          style={{ padding: "8px", border: "1px solid #ddd", borderRadius: "4px", background: "#f9f9f9" }}
        />
      ) : (
        <Typography variant="body2" color="text.secondary">
          {escapeHTML(value === "" || value === null ? "(Empty)" : value.toString())}
        </Typography>
      )}
    </div>
  );
};

const DataDisplay = ({ data, displayDef }) => {
  // Filter and sort entries based on displayDef
  const sortedEntries = Object.entries(data)
    .filter(([key]) => !displayDef[key]?.ignore && (!!key && key !== "_type")) // Ignore fields based on displayDef
    .sort(([a], [b]) => displayDef[a]?.order - displayDef[b]?.order); // Apply order from displayDef

  return (
    <Card sx={{ maxWidth: 500, padding: 1, margin: "auto" }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Record Details
        </Typography>
        <Divider sx={{ marginBottom: 2 }} />
        {sortedEntries.map(([key, value], index) => {
          const fieldLabel = displayDef[key]?.label || key;
          const childDef = displayDef[key]?.child || { "_type": "string" }; // Get the child configuration if it exists
          return (
            <div key={key} sx={{ border: 1 }} >
              <FieldDisplay
                label={fieldLabel} // Use the label from displayDef for the parent field
                value={value}
                isEven={index % 2 === 0}
                displayDef={displayDef} // Pass down displayDef to FieldDisplay
                childDef={childDef} // Pass down the child configuration for nested fields
              />
              {index !== sortedEntries.length - 1 && <Divider sx={{ marginY: 1 }} />}
              {sortedEntries.length != 0 && <Divider sx={{ marginY: 1 }} />}
            </div>
          );
        })}
      </CardContent>
    </Card>
  );
};

export default DataDisplay;