import React, { useState } from "react";
import { Box, Tab, Tabs, Button, Typography, Tooltip} from "@mui/material";
import { Form, Field, useFormState, useForm } from "react-final-form";

const VerticalTabForm = ({ handlePrev, handleNext, setActiveTab, activeTab, handleError, forms }) => {
  const { submitting, pristine, valid, values } = useFormState();

  return (
    <Box sx={{ display: "flex", height: "100%" }}>
      {/* Vertical Tabs */}
      <Tabs
        orientation="vertical"
        value={activeTab}
        onChange={(e, newValue) => setActiveTab(newValue)}
        sx={{ borderRight: 1, borderColor: "divider" }}
      >
        {forms.map((formInput, index) => (
          <Tooltip
            key={index}
            placement="left-start"
            slotProps={{
              popper: {
                modifiers: [{ name: "offset", options: { offset: [0, -40] } }],
              },
            }}
            title={formInput.toolTip}
          >
            <Tab icon={formInput.icon} />
          </Tooltip>
        ))}
      </Tabs>

      {/* Keep All Forms Mounted, Hide Inactive Ones */}
      <Box sx={{ flex: 1, p: 2 }}>
        {forms.map((formInput, index) => (
          <Box key={index} sx={{ display: activeTab === index ? "block" : "none" }}>
            {formInput.form}   
            {formInput.howto}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default VerticalTabForm;