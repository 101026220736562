import React from 'react';
import { CircularProgress, Box, Typography, Dialog, DialogContent } from '@mui/material';

let prompt = "processing"
export const setMessage=(msg)=>{
  prompt = msg;
}
const LoadingDialog = ({ open, message = "Please wait, processing...", imageSrc = null }) => {
  setMessage(message);
  return (
    <Dialog open={open} PaperProps={{ style: { borderRadius: 8 } }} sx={{ zIndex: 10000 }}>
      <DialogContent>
        <Box display="flex" flexDirection="column" alignItems="center" padding={2}>
          {imageSrc ? (
            <Box
              component="img"
              src={imageSrc}
              alt="Loading indicator"
              sx={{ width: 60, height: 60, objectFit: "contain", mb: 2 }}
            />
          ) : (
            <CircularProgress />
          )}
          <Typography variant="body1" marginTop={2} textAlign="center">
            {prompt}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
// With default spinner
//<LoadingDialog open={isLoading} message="Loading your data, please wait..." />

// With a custom image
//<LoadingDialog open={isLoading} message="Loading your data, please wait..." imageSrc="/path/to/your/image.png" />