import eventDispatcher from "../events/dispatcher";
export const genEntityImageUrl = async (entityType, entityId) => {
  try {
    const url = await eventDispatcher.GET_ENTITY_MEDIA(
      entityType,
      entityId,
      async (response) => {
        //console.log("Received API Response:", response);
        if (!response || !response.data) {
          console.warn("No image data received from API.");
          return;
        }

        // ✅ Convert response to a Blob
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        //console.log("Blob Created:", blob);

        // ✅ Generate Blob URL
        return URL.createObjectURL(blob);

      }
    );
    return url;
  } catch (error) {
    console.error("Error fetching image:", error);
    return null;
  }

};

export const base64ToFile = (base64String, filename) => {
    const byteString = atob(base64String.split(',')[1]);
    const mimeType = base64String.split(',')[0].split(':')[1].split(';')[0];

    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
    }

    return new File([byteArray], filename, { type: mimeType });
};

 /**
* Checks if the value of `urlImage` is an image data string or an image URL.
*
* @param {Object} map - The map containing the `urlImage` key.
* @returns {string} - Returns 'dataString' if it's an image data string,
*                     'url' if it's an image URL,
*                     or 'invalid' if neither.
*/
export const getUrlImageType = async (map) => {

  if (!map || typeof map.urlImage !== "string" || map.urlImage.trim() === "") {
    return 'invalid';
  }

  const value = map.urlImage.trim();

  // Improved regex to check if it's a valid URL
  const urlPattern = /^(https?:\/\/[^\s]+|U)$/i;

  // Regex to detect data URI with any image format
  const dataUriPattern = /^data:(image\/[a-zA-Z0-9.+-]+);base64,([A-Za-z0-9+/]+=*)$/i;

  // Check for data URI format dynamically
  const dataUriMatch = value.match(dataUriPattern);
  if (dataUriMatch) {
    return `dataString (${dataUriMatch[1]})`;
  }

  // If it's a valid URL, check if it's an image by fetching headers
  if (urlPattern.test(value)) {
   
    try {
        return "url";
    } catch (error) {
      return "invalid";
    }
  }
 
  return 'invalid';
};
// Example usage:
// const map1 = { urlImage: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUA' };
// const map2 = { urlImage: 'https://example.com/image.png' };
// const map3 = { urlImage: 'invalid-string' };

// //console.log(checkImageType(map1)); // Output: 'dataString'
// //console.log(checkImageType(map2)); // Output: 'url'
// //console.log(checkImageType(map3)); // Output: 'invalid'

/**
 * Reduce the resolution of a dataString image using async/await
 * @param {string} dataString - The base64 image data string
 * @param {number} targetWidth - The desired width of the output image
 * @param {number} targetHeight - The desired height of the output image
 * @returns {Promise<string>} - The new dataString as a promise
 */
export async function resizeImageDataString(dataString, targetWidth, targetHeight) {
    const img = await loadImage(dataString);
  
    const canvas = document.createElement("canvas");
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext("2d");
  
    // Draw the image onto the canvas at the target resolution
    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
  
    // Convert the canvas to a data URL (dataString)
    return canvas.toDataURL();
  }


  /**
 * Reduce the resolution of a dataString image while maintaining the aspect ratio
 * @param {string} dataString - The base64 image data string
 * @param {number} targetWidth - The desired width of the output image
 * @returns {Promise<string>} - The new dataString as a promise
 */
export async function reduceImageResolution(dataString, targetWidth) {
    const img = await loadImage(dataString);
  
    // Calculate the target height to maintain the aspect ratio
    const aspectRatio = img.height / img.width;
    const targetHeight = Math.round(targetWidth * aspectRatio);
  
    const canvas = document.createElement("canvas");
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext("2d");
  
    // Draw the image onto the canvas at the target resolution
    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
  
    // Convert the canvas to a data URL (dataString)
    return canvas.toDataURL();
  }

export async function reduceImageResolutionByHeight(dataString, targetHeight) {
    const img = await loadImage(dataString);
  
    // Calculate the target width to maintain the aspect ratio
    const aspectRatio = img.width / img.height;
    const targetWidth = Math.round(targetHeight * aspectRatio);
  
    const canvas = document.createElement("canvas");
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext("2d");
  
    // Draw the image onto the canvas at the target resolution
    ctx.drawImage(img, 0, 0, targetWidth, targetHeight);
  
    // Convert the canvas to a data URL (dataString)
    return canvas.toDataURL();
  }
  
/**
 * Utility function to load an image
 * @param {string} src - The source of the image (dataString)
 * @returns {Promise<HTMLImageElement>} - The loaded image element
 */
function loadImage(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = (error) => reject(error);
      img.src = src;
    });
  }


export function transformSearchTags(data) {
  const typeMapping = {
      C: "password",
      A: "account",
      U: "user",
      G: "group",
      P: "contact"
  };

  const result = {};

  for (const key in data) {
      const tag = data[key].TAG; // Extract TAG value
      if (!tag) continue; // Skip if TAG is not present

      if (!result[tag]) {
          result[tag] = {};
      }

      for (const id in data[key]) {
          if (id === "TAG") continue; // Skip TAG field

          const entry = data[key][id];
          result[tag][id] = {
              tag: tag,
              type: typeMapping[entry.t] || entry.t, // Map `t` to `type`
              name: entry.n // Rename `n` to `name`
          };
      }
  }

  return result;
}



export async function reduceFileImageResolution(file, targetWidth, newFileName = "resized_image.jpg", quality = 0.8) {
  return new Promise((resolve, reject) => {
      if (!(file instanceof File)) {
          return reject(new Error("Invalid file input. Expected a File object."));
      }

      const reader = new FileReader();

      reader.onload = function (event) {
          const img = new Image();
          img.onload = function () {
              let width = img.width;
              let height = img.height;

              // Maintain aspect ratio
              if (width > targetWidth) {
                  const aspectRatio = width / height;
                  width = targetWidth;
                  height = Math.round(targetWidth / aspectRatio);
              }

              const canvas = document.createElement("canvas");
              canvas.width = width;
              canvas.height = height;

              const ctx = canvas.getContext("2d");
              ctx.drawImage(img, 0, 0, width, height);

              canvas.toBlob(
                  (blob) => {
                      if (blob) {
                          resolve(new File([blob], newFileName, { type: "image/jpeg" }));
                      } else {
                          reject(new Error("Failed to generate image blob."));
                      }
                  },
                  "image/jpeg",
                  quality // Compression quality
              );
          };
          img.onerror = reject;
          img.src = event.target.result;
      };

      reader.onerror = reject;
      reader.readAsDataURL(file);
  });
}


// // Example usage
// const inputArray = [
//   {
//       "TAG": "Tag2",
//       "b21175f6-3b44-4ae3-a587-5da5b82c89bf": {
//           "t": "C",
//           "n": "My First Credentialsss"
//       }
//   },
//   {
//       "TAG": "Tag3",
//       "b21175f6-3b44-4ae3-a587-5da5b82c89bf": {
//           "t": "A",
//           "n": "My First Credentialsss"
//       }
//   },
//   {
//       "TAG": "Tag1",
//       "b21175f6-3b44-4ae3-a587-5da5b82c89bf": {
//           "t": "U",
//           "n": "My First Credentialsss"
//       }
//   }
// ];

// {
//   Tag2: {
//       "b21175f6-3b44-4ae3-a587-5da5b82c89bf": {
//           tag: "Tag2",
//           type: "password",
//           name: "My First Credentialsss"
//       }
//   },
//   Tag3: {
//       "b21175f6-3b44-4ae3-a587-5da5b82c89bf": {
//           tag: "Tag3",
//           type: "account",
//           name: "My First Credentialsss"
//       }
//   },
//   Tag1: {
//       "b21175f6-3b44-4ae3-a587-5da5b82c89bf": {
//           tag: "Tag1",
//           type: "user",
//           name: "My First Credentialsss"
//       }
//   }
// }
// const reducedObject = reduceToCustomStructure(inputArray);
// //console.log(reducedObject);




export function getFilteredSearchTags(data, type, tag, name) {
  const matches = [];
  const lowerTag = tag.toLowerCase();
  const lowerName = name.toLowerCase();

  for (const tagKey in data) {
      const item = data[tagKey];
      for (const key in item) {
          const { type: itemType, name: itemName } = item[key];

          const isTypeMatch = type === "all" || itemType.toLowerCase() === type.toLowerCase();
          const isTagMatch = tagKey.toLowerCase().includes(lowerTag);
          const isNameMatch = itemName.toLowerCase().includes(lowerName);

          if (isTypeMatch && (isTagMatch || isNameMatch)) {
              matches.push({
                  tag: tagKey,
                  id: key,
                  type: itemType,
                  name: itemName
              });
          }
      }
  }

  return matches;
}

// Example usage
// const data = {
//   Tag2: {
//       "b21175f6-3b44-4ae3-a587-5da5b82c89bf": {
//           type: "password",
//           name: "My First Credentialsss"
//       }
//   },
//   Tag3: {
//       "b21175f6-3b44-4ae3-a587-5da5b82c89bf": {
//           type: "account",
//           name: "My First Credentialsss"
//       }
//   },
//   Tag1: {
//       "b21175f6-3b44-4ae3-a587-5da5b82c89bf": {
//           type: "user",
//           name: "User Credential"
//       }
//   }
// };

// // Search query examples
// const results1 = searchElements(data, "all", "Tag1", "Credential"); // Match any type, partial tag or name
// const results2 = searchElements(data, "password", "Tag2", ""); // Exact match for type, partial tag
// const results3 = searchElements(data, "user", "", "User Credential"); // Exact match for type and name

// //console.log(results1);
// //console.log(results2);
// //console.log(results3);