import React, { useState, useContext, useEffect, useRef } from "react";

import SearchBar from "../search/SearchBar"
import BillingPortalDialog from "../subscription/BillingPortalDialog";
import BillingManagement from "../subscription/BillingManagement";
import StripePaymentDialog from "../subscription/StripePaymentDialog";
import LogoutIcon from "@mui/icons-material/Logout";
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import PasswordOutlinedIcon from '@mui/icons-material/PasswordOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';

//import SearchBar from "../search/SearchBar1" ;
import {
  Typography,
  Button,
  Card,
  CardBody,
  CardHeader,
  Avatar,

} from "@material-tailwind/react";
import { PositionedElements } from "@8d-xyz/ui-react_positioned_element-component";
import { useUserSession } from '../../lib/session/UserSession';
import { UserAccount, api, profile, LazyAccountManagement, LazyPasswordReset, LazyPreferences, LazyUserProfile } from '@8d-xyz/ui-react_account_management-widget';
import { AppUtils } from '@8d-xyz/ui-react_application_f-container';
import logo from "../../assets/logo_200.png"
import eventDispatcher from "../../events/dispatcher";

import dispatcher from "../../events/dispatcher";
import LoadingDialog, { setMessage } from "../../demo/platform/WaitDialog";
import noProfieImage from "../../assets/noProfieImage.png";
import plans from "../../config/Products.jsx"
import { genEntityImageUrl } from "../../utils/common";
const BarWidget = () => {
  let { user, logout, login, setUser, tagEntities, setTagEntities } = useUserSession();
  const [isLoading, setIsLoading] = useState(false);
  const [showBilling, setShowBilling] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  const [promptMessage, setPromptMessage] = useState("");

  const instructRef = useRef(<>   
    <Typography variant="body1" sx={{ textAlign: "center" }}>
        A freemium account has been created for you by default, and we're now directing you to the next step—setting up your subscription payment.
    </Typography>
    <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
        If you don’t complete the payment setup for any reason, no worries! You can log in anytime with your freemium account and use the <strong>Manage Billing</strong> option to finalize your subscription.
    </Typography></> );

    useEffect(() => {
    const fetchData = async () => {
      if(user && user.AVATAR=="U"){ 
       
        user.AVATAR = await genEntityImageUrl("AVATAR","0");
        setUser((prev)=>({
          ...prev,
          ...user
         
        }));
       
      }else if(user && user.AVATAR==""){
        user.AVATAR=noProfieImage;
      }
    };

    fetchData();

  }, [user.AVATAR]);


  useEffect(() => {
    const fetchData = async () => {
      if (!tagEntities) {
        let entities = await eventDispatcher.GET_ORGANIZER_BY_TYPE("tags", {}, () => { });
        setTagEntities(entities);
      }
    };

    fetchData();

  }, []);
  const configx = {
    components: [
      {
        useTailwind: false,
        componentProps: {
          imageSrc: logo,
          labelText: `${process.env.ENV_LABEL}`,
          labelPosition: "bottom",
          size: "90px",
          containerStyle: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center"
          },
          tooltip: "Welcome to Edax",
          tooltipPlacement: "bottom",
          iconStyle: {},
          imageStyle: {
            border: "0px solid blue",
            width: "150px",
            height: "40px"
          },
          labelStyle: {
            color: "Black",
            fontSize: "16px",
            marginTop: "8px"
          },
          onClick: "handleHomeClick"
        }
      },

    ],
    callbacks: {
      handleHomeClick: () => {
        // alert(process.env.ENV_LABEL); 
        //console.log("Home clicked")
      },
      handleProfileClick: () => {
        //console.log("Profile clicked")
        }
    }
  };


  return <div className="bg-white w-full flex-grow flex flex-col">
  {/* Flex container for layout management */}
  <div className="flex flex-wrap md:flex-nowrap items-center justify-between px-4 py-2 gap-4 bg-red-30">
    
    {/* PositionedElements (Always on the Left) */}
    <div className="flex items-center min-w-max flex-shrink-0 w-full md:w-auto">
      <div className="p-2 md:p-4 lg:px-6">
        <PositionedElements config={configx} />
      </div>
    </div>

    {/* SearchBar (Takes Up Remaining Space in the Center) */}
                    
    <div className="flex-grow items-center w-full md:w-auto">
      {user && <SearchBar className="w-full md:w-full lg:max-w-3xl" config={
              {
                globalDisableFilters: true,
                // defaultSearchContext: "All",
                handler: () => {},
                searchContexts: user.APP_SEARCH_CONTEXT
              }
            }
            tags={tagEntities}/>}
    </div>

    {/* UserAccount (Always on the Right End) */}
    <div className="flex items-center gap-2 min-w-max flex-shrink-0 w-full md:w-auto justify-start md:justify-end">
      {user && (
        <div className="flex flex-nowrap items-center gap-2 p-2">
          <UserAccount user={user} 
            accountManagmentConfig={{
            "menuItems": [
              { "id": "account", "label": "Account", "icon": "AccountBoxOutlinedIcon" },
              ...((user.ACCOUNT_INFO.PLAN_ID !== "INDIVIDUAL_MINIMALIST") ? [{ "id": "billing", "label": "Subscription", "icon": "AddTaskOutlinedIcon" }] : []),
              { "id": "signout", "label": "Sign Out", "icon": "LogoutIcon" }
            ],
            "iconMap": {
              "LogoutIcon": LogoutIcon,
              "AddTaskOutlinedIcon": AddTaskOutlinedIcon,
              "PasswordOutlinedIcon": PasswordOutlinedIcon,
              "AccountBoxOutlinedIcon": AccountBoxOutlinedIcon
            },
            "avatar": {
              "src":  noProfieImage,
              "alt": user.ACCOUNT_NAME,
              "label": `Hello ${user.ACCOUNT_NAME}`
            },
            "button": {
              "variant": "text",
              "className": "flex items-center gap-2 pt-1 pb-1"
            },
            "menuItemStyles": {
              "containerClass": "flex items-center gap-2 text-lg",
              "iconClass": "h-5 w-5"
            },
            "dialogComponents": {
              "account": LazyAccountManagement,
              "security": LazyPasswordReset,
              "profile": LazyUserProfile,
              "settings": LazyPreferences
            },
            "dialogSettings": {
              "size": "md",
              "className": "bg-white rounded-lg shadow-md"
            },
            "eventHandlers": {
              "signout": async () => {
                
                await logout();
                let containerAPIs = AppUtils.getAPIs("ApplicationContainer");
                containerAPIs?.AuxPanel?.closeOpen.exec(false);
                containerAPIs?.MainPanel?.closeOpen.exec(true);
                api.logout == null;
              },
              "billing": () => {
                setShowBilling(true);
              }
            },
            "callbacks": {
              "onSuccess": async (data) => {
                //console.log("Success:", data)
              },
              "onError": async (error) => {
                //console.error("Error:", error)

              },
              "onClose": async (data) => {
                //console.log("Dialog Closed:", data)
               },
              "onCancel": async () => {
                //console.log("Dialog Cancelled")
               },
              "onEvent": async (eventType) => {
                //console.log(`Custom Event - ${eventType.eventType}:`);
                switch (eventType.eventType) {
                  case "PASSWORD_RESET_EMAIL":
                    setPromptMessage(() => "Sending Request...");
                    dispatcher.PASSWORD_RESET({ email: user.EMAIL }, setIsLoading);
                    break;
                  case "UPLOAD_AVATAR":
                    setPromptMessage(() => "Saving image...");
                    const { image, setDialogDisplay } = eventType.data;          
                    try {
                      // Upload the new avatar
                      let userProfile = await eventDispatcher.UPLOAD_USER_AVATAR("AVATAR", {
                        urlImage: image,
                        id: null
                      }, setIsLoading);
                  
                      // Ensure React state is updated properly
                      setUser((prevUser) => ({
                        ...prevUser,
                        AVATAR: userProfile.AVATAR // This will trigger the useEffect
                      }));
                  
                      // Close the dialog
                      setDialogDisplay(false);
                    } catch (error) {
                      console.error("Error uploading avatar:", error);
                    }
                    break;
                  case "UPDATE_PROFILE":
                    setPromptMessage(() => "Updating Profile...");
                    let { values } = eventType.data;
                    let data = await dispatcher.UPDATE_ACCOUNT_USER_PROFILE(values, setIsLoading);
                    data.ALIAS = data.ACCOUNT_NAME;
                    setUser({ ...user, ...data });
                    eventType.data.config.avatar.alt = data.ACCOUNT_NAME;
                    eventType.data.setDialogDisplay(false);
                    break;
                  default:
                    break;
                }
              }
            }
          }} />
          {(user.ACCOUNT_INFO.PLAN_ID === "INDIVIDUAL_MINIMALIST") && (
            <BillingManagement products={plans} setPaymentLink={setPaymentLink} setShowPayment={setShowPayment} instructRef={instructRef} />
          )}
        </div>
      )}
    </div>

  </div>
  <BillingPortalDialog open={showBilling} onClose={() => setShowBilling(false)} />
  <StripePaymentDialog paymentLink={paymentLink} open={showPayment} onClose={() => setShowPayment(false)} instruction={instructRef.current} />
  <LoadingDialog open={isLoading} message={!!!promptMessage ? 'Processing, please wait...' : promptMessage} />

</div>



};

export default BarWidget;

