import serviceTaskRunner, { services, _callRestfulService as restful } from "../lib/restful/services/restAPIs";
import { CryptoTransform } from "../lib/util/ClientUtil";
import { SeqCrypto } from "../lib/util/SeqCrypto";
import {
  getUrlImageType,
  resizeImageDataString,
  base64ToFile,
  reduceImageResolution,
  reduceImageResolutionByHeight,
  reduceFileImageResolution
} from "../utils/common";
import { v4 as uuid } from "uuid";
import sessionData from "../lib/session/sessionData.json"
import { ClientMediator } from '@8d-xyz/ui-react_application_f-container';

                                        
export const WEBHOOK  = async (values, login, setIsLoading) => {
  //console.log("Login submitted:", values);
  setIsLoading((prev)=>true);
  let _SignInTaskRunner = await serviceTaskRunner(services.STRIPE_CALL_BACK)(
    values,
    async (response, callParams) => {

      const data = response.data.payload;
      let result = data;
      
      //console.log(data);
    
      return result;
    },
    {
      //callBack Parameters
    },
    (error) => {
    
      console.log(error);
    
      throw { message: "Unable to process webhook event " };

    }
  );

  
  setIsLoading((prev)=>false);
  return true;

};

export const PORTAL_URL = async (values) => {
  //console.log("Sign-up submitted:", values);
  //setIsLoading((prev)=>true);
  let payload = await serviceTaskRunner(services.CUSTOMER_PORTAL)(
    values, 
    async (response, callParams) => {
      //callBack function
      //Get return data from the restufl call
      const data = response.data.payload;
      return data;
    },
    {
      //callBack Parameters
    },
    (error) => {
      console.error(error)
      throw { message: "Unable to generate payment portal" };
    }
  );
  return payload;
};


export const CHECKOUT_URL = async (values) => {
  console.log(" checkout submitted:", values);
  //setIsLoading((prev)=>true);
  let payload = await serviceTaskRunner(services.CUSTOMER_CHECKOUT)(
    values, 
    async (response, callParams) => {
      //callBack function
      //Get return data from the restufl call
      const data = response.data.payload;
      return data;
    },
    {
      //callBack Parameters
    },
    (error) => {
      console.log(error)
      throw { message: "Unable to generate payment portal" };
    }
  );
  return payload;
};
