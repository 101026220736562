const termsOfService = `
    <style>
        body {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            color: #333;
        }
        h1 {
            font-size: 28px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 20px;
        }
        h2 {
            font-size: 22px;
            font-weight: bold;
            margin-top: 30px;
            margin-bottom: 10px;
            border-bottom: 2px solid #ddd;
            padding-bottom: 5px;
        }
        p {
            font-size: 16px;
            margin-bottom: 15px;
        }
        ul {
            padding-left: 25px;
            margin-bottom: 15px;
        }
        li {
            margin-bottom: 8px;
        }
        a {
            color: #0078d4;
            text-decoration: none;
        }
        a:hover {
            text-decoration: underline;
        }
        hr {
            border: 0;
            height: 1px;
            background: #ddd;
            margin: 20px 0;
        }
    </style>
   <p/>

    <p><strong>Effective Date:</strong> March 16, 2025</p>
    <p><strong>Last Updated:</strong> March 16, 2025</p>

    <p>Welcome to <strong>E-DAX</strong> (“Service”), a personal information management application provided by <strong>Ireva Corp.</strong> (“Company”, “we”, “us”, or “our”).</p>
    
    <p>By accessing or using our Service, you agree to be bound by these <strong>Terms of Service</strong> (“Terms”). If you do not agree, do not use the Service.</p>

    <hr />

    <h2>1. Introduction</h2>
    <p>These Terms govern your use of <strong>E-DAX</strong> and any related services. This is a legal agreement between you and <strong>Ireva Corp.</strong>.</p>

    <p>By continuing to use the Service, you confirm that you have read, understood, and agree to comply with these Terms. Violating any of these Terms may result in suspension or termination of your access.</p>

    <h2>2. Definitions</h2>
    <ul>
        <li><strong>“Service”</strong> refers to <strong>E-DAX</strong>, a personal information management application.</li>
        <li><strong>“User”</strong> refers to any individual or entity who accesses or uses the Service.</li>
        <li><strong>“Account”</strong> refers to the registered profile associated with a user’s use of the Service.</li>
        <li><strong>“Personal Data”</strong> refers to information that identifies an individual, as described in our Privacy Policy.</li>
    </ul>

    <h2>3. Use of Services</h2>
    <p>You may use the Service only in compliance with these Terms and applicable laws. Misuse of the Service, including unauthorized access or abuse, may result in termination of your account.</p>

    <h2>4. User Accounts and Security</h2>
    <ul>
        <li>You must be at least <strong>18 years old</strong> or have parental/guardian consent.</li>
        <li>You are responsible for maintaining the confidentiality of your login credentials.</li>
        <li>Unauthorized access or use of your account must be reported immediately.</li>
    </ul>

    <h2>5. Subscription, Fees, and Payment</h2>
    <ul>
        <li>The Service offers <strong>free and paid subscription plans</strong>.</li>
        <li>Paid subscriptions renew automatically unless canceled before the renewal date.</li>
        <li>Payments are processed through third-party providers, and failure to pay may result in account suspension.</li>
    </ul>

    <h2>6. Acceptable Use Policy</h2>
    <p>By using the Service, you agree <strong>not</strong> to:</p>
    <ul>
        <li>Engage in illegal, harmful, or fraudulent activities.</li>
        <li>Distribute malware, spam, or unauthorized personal data.</li>
        <li>Attempt to hack, reverse-engineer, or disrupt the Service.</li>
        <li>Resell, redistribute, or sublicense the Service without permission.</li>
    </ul>

    <h2>7. Data Privacy and Security</h2>
    <ul>
        <li>We collect and process data in accordance with our Privacy Policy.</li>
        <li>We implement industry-standard security measures but do not guarantee absolute security.</li>
        <li>You are responsible for backing up your data.</li>
    </ul>

    <h2>8. Intellectual Property Rights</h2>
    <ul>
        <li>All <strong>software, logos, and content</strong> are owned by <strong>Ireva Corp.</strong>.</li>
        <li>You retain ownership of your uploaded data but grant us a limited license to store and process it.</li>
    </ul>

    <h2>9. Service Availability and Changes</h2>
    <ul>
        <li>We strive for 99.9% uptime but do not guarantee uninterrupted service.</li>
        <li>We reserve the right to modify, discontinue, or restrict access to features.</li>
    </ul>

    <h2>10. Contact Information</h2>
    <p>If you have any questions, contact us:</p>
    <p><strong>Email:</strong> <a href="mailto:support@3-dax.com">contact_us@e-dax.com</a></p>
    <p><strong>Address:</strong> 3370 Prince Street #CA12, Flushing, NY 11354</p></p>

    <h2>Acknowledgment</h2>
    <p>By using <strong>E-DAX</strong>, you acknowledge that you have read, understood, and agreed to these Terms.</p>
`;

export default termsOfService;
