import React, { useState, useEffect, useRef } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from "@mui/material";

const StripePaymentDialog = ({ open, onClose, paymentLink, autoOpenDelay = 20, instruction=<>   
                <Typography variant="body1" sx={{ textAlign: "center" }}>
                    A freemium account has been created for you by default, and we're now directing you to the next step—setting up your subscription payment.
                </Typography>
                <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
                    If you don’t complete the payment setup for any reason, no worries! You can log in anytime with your freemium account and use the <strong>Subscription</strong> option to finalize your subscription.
                </Typography></> }) => {
    const [countdown, setCountdown] = useState(autoOpenDelay);
    const hasOpenedRef = useRef(false); // Use useRef instead of state to prevent re-renders

    useEffect(() => {
        if (open) {
            setCountdown(autoOpenDelay);
            hasOpenedRef.current = false; // Reset when the dialog opens

            const timer = setInterval(() => {
                setCountdown((prev) => {
                    if (prev === 1) {
                        clearInterval(timer);
                        handleProceedToPayment(); // Open payment link & close parent tab
                    }
                    return prev - 1;
                });
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [open, autoOpenDelay]);

    const handleProceedToPayment = () => {
        if (!hasOpenedRef.current) {
            hasOpenedRef.current = true; // Mark as opened to prevent duplicate calls
            const newTab = window.open(paymentLink, "_blank"); // Open payment link in a new tab

            if (newTab) {
                window.open("about:blank", "_self"); // Fix for some browsers
                window.close(); // Close the current parent tab
            } else {
                alert("Unable to open payment page. Please allow pop-ups and try again.");
            }

            onClose(); // Close the dialog
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            maxWidth="sm"
            sx={{
                "& .MuiDialog-paper": {
                    backgroundColor: "#f5f5f5",
                    borderRadius: "12px",
                    padding: "20px",
                },
                "& .MuiBackdrop-root": {
                    backgroundColor: "rgba(0, 0, 0, 0.7)",
                },
            }}
        >
            <DialogTitle sx={{ fontSize: "1.5rem", fontWeight: "bold", textAlign: "center" }}>
                Subscription Setup in Progress
            </DialogTitle>
            <DialogContent>
                {instruction}
                
                <Typography variant="body2" sx={{ mt: 3, fontWeight: "bold", color: "primary.main", fontSize: "1.2rem", textAlign: "center" }}>
                    Redirecting in {countdown} seconds...
                </Typography>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center", padding: "20px" }}>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button onClick={handleProceedToPayment} color="primary" variant="contained" sx={{ fontSize: "1rem", padding: "10px 20px" }}>
                    Proceed Now
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default StripePaymentDialog;
