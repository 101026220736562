import serviceTaskRunner, { services, _callRestfulService as restful } from "../lib/restful/services/restAPIs";
import { CryptoTransform } from "../lib/util/ClientUtil";
import { SeqCrypto } from "../lib/util/SeqCrypto";
import {
  getUrlImageType,
  resizeImageDataString,
  base64ToFile,
  reduceImageResolution,
  reduceImageResolutionByHeight,
  reduceFileImageResolution
} from "../utils/common";
import { v4 as uuid } from "uuid";
import sessionData from "../lib/session/sessionData.json"
import { ClientMediator } from '@8d-xyz/ui-react_application_f-container';

                                        
export const LOGIN  = async (values, login, setIsLoading) => {
  //console.log("Login submitted:", values);
  setIsLoading((prev)=>true);
  let _SignInTaskRunner = await serviceTaskRunner(services.SIGN_IN)(
    values, {},
    async (response, callParams) => {
      //callBack function
      //Get return data from the restufl call
      const data = response.data.payload;
      let result = data;
      //const authToken = 'example-token';
      //const userData = { name: 'John Doe', email };
      login(data, data.idToken);
      //throw "fock"  //show in the widget
      //do something
      return result;
    },
    {
      //callBack Parameters
    },
    (error) => {
    
      //console.log(error);
      setIsLoading((prev)=>false);
      throw { message: "Unable to authenticate user" };

    }
  );

  //   //console.log(_SignInTaskRunner);
  setIsLoading((prev)=>false);
  return true;

};

export const SIGNUP = async (values, login, setIsLoading) => {
  //console.log("Sign-up submitted:", values);
  let _SignUpTaskRunner = await serviceTaskRunner(services.SIGN_UP)(
    values, {},
    async (response, callParams) => {
      //callBack function
      //Get return data from the restufl call
      const data = response.data.payload;
      let result = data;

      //console.log("setSession-mmmmmmmmmmmmmmmmm");
      //console.log(data);
      login(data, data.idToken);

      return result;
    },
    {
      //callBack Parameters
    },
    (error) => {
      //console.log(error)
      throw { message: "Unable to create user account." };
    }
  );
  return true;
};


export const PASSWORD_RESET = async (values,setIsLoading) => {
  //console.log("Password recovery requestedxxxx:", values);
  //alert(setIsLoading);
  setIsLoading(true);
  let _AccountRecoverTaskRunner = await serviceTaskRunner(services.ACCOUNT_RECOVER)(
    values,{},
    async (response, callParams) => {
        //callBack function
        //Get return data from the restufl call
        const data = response.data.payload;
        //console.log(data);
        if(!data) throw {message:"email not recognized"};
        //do something
        return data;
    },
    {
    //callBack Parameters
    },
    (error)=>{
      //console.log("ofuck")
      setIsLoading(false);
     // return true;
      throw {message:"fock"};
    }
    );
    setIsLoading(false);
  return true;
};