let dataModel = {
    "forms": {
      "credentialWidget": {
        "fields":{
          "name":"",
          "password":null,
          "tags":[],
          "note":null,
          "additionalInformation":[],
          "challengePrompt":null,
          "challenge":null,
          "useChallenge":null,
          "secureKey":null,
          "securedPassword":"",
          "url":"",
          "urlImage": ""
        }, 
        "validationSchema": {
          "name": {
            "type": "string",
            "required": true,
            "message": "Name is required."
          },
          "password": {
            "type": "string",
            "required": false,
            "message": "Password is optional."
          },
          // "expiration": {
          //   "type": "string",
          //   "required": false,
          //   "message": "expiration is required"
          // },
          "tags": {
            "type": "array",
            "required": false,
            "message": "tags are optional."
          },
          "note": {
            "type": "string",
            "required": false,
            "message": "Note is optional."
          },
          "additionalInformation": {
            "type": "array",
            "required": false,
            "message": "key/value pairs information is optional."
          },
          "challenge": {
            "type": "string",
            "required": false,
            "message": "challenge is required."
          },
          "challengePrompt":{
            "type": "string",
            "required": false,
            "message": "challenge prompt is optional."
          },
        
          "useChallenge": {
            "type": "boolean",
            "required": false,
            "message": "Use challenge is optional."
          },
          "secureKey": {
            "type": "string",
            "nullable": true, 
            "required": false,
            "message": "Personal/universal secure key optional."
          },
          "securedPassword": {
            "type": "string",
            "nullable": true, 
            "required": false,
            "message": "Secured data (password) is optional."
          },
          // "account": {
          //   "type": "string",
          //   "required": false,
          //   "message": "account is required"
          // },
          // "accounts": {
          //   "type": "array",
          //   "required": false,
          //   "message": "accounts is required"
          // },
          "url": {
            "type": "url",
            "required": false,
            "message": "URL is optional",
            "matches":true,
            "matches.regex":"^(?:$|(https?|ftps?|sftp|smtp|imap|pop3|dns|telnet|ssh|vpn|kerberos|rtsp|mms|file|data|about|magnet):\\/\\/[^\\s]+|data:[^,\\s]+,[^\\s]+|about:[^\\s]+|magnet:\\?xt=urn:[^\\s]+|file:\\/\\/\\/[^\\s]+|about:[^\\s]+)$",
            "matches.errorMessage":"Invalid URL Format"
          },
          "urlImage": {
            "type": "string",
            "nullable": true, 
            "required": false,
            "message": "Image is optional."
          }
        }
      },
      "accountWidget": {
        "fields":{
          "name":"",
          "accountNumber":null,
          "pin": null,
          "accountBackupCodes":"",
          "addresses":[],
          // "accountEmailAddresses":[],
          "tags":[],
          // "accountTelephoneLines":[],
          "accountNoteEntries":[],
          "note":null,
          "url":"",
          "urlImage": ""
        }, 
        "validationSchema": {
          "name": {
            "type": "string",
            "required": true,
            "message": "Name is required."
          },
          "accountNumber": {
            "type": "string",
            "required": false,
            "message": "Account number is optional."
          },
          "pin": {
            "type": "string",
            "required": false,
            "message": "pin is optional."
          },
          "accountBackupCodes": {
            "type": "string",
            "required": false,
            "message": "Account backup code is optional."
          },
          "addresses": {
            "type": "array",
            "required": false,
            "message": "Addresses is optional."
          },
          "accountEmailAddresses": {
            "type": "array",
            "required": false,
            "message": "Eamial addresses is optional."
          },
          "tags": {
            "type": "array",
            "required": false,
            "message": "tags is optional."
          },
          // "accountTelephoneLines": {
          //   "type": "array",
          //   "required": false,
          //   "message": "Phone lines is optional."
          // },
          // "accountNoteEntries": {
          //   "type": "array",
          //   "required": false,
          //   "message": "Entries is optional."
          // },
          "note": {
            "type": "string",
            "required": false,
            "message": "Note is optional."
          },
          "url": {
            "type": "url",
            "required": false,
            "matches":true,
            "matches.regex":"^(?:$|(https?|ftps?|sftp|smtp|imap|pop3|dns|telnet|ssh|vpn|kerberos|rtsp|mms|file|data|about|magnet):\\/\\/[^\\s]+|data:[^,\\s]+,[^\\s]+|about:[^\\s]+|magnet:\\?xt=urn:[^\\s]+|file:\\/\\/\\/[^\\s]+|about:[^\\s]+)$",
            "matches.errorMessage":"Invalid URL Format"
          },
          "urlImage": {
            "type": "string",
            "nullable": true, 
            "required": false,
            "message": "Image is optional."
          }
        }
      },
      "groupWidget": {
        "fields":{
          "urlImage": "",
          "name":"",
          //"groupNumber":null,
          //"pin": null,
          //"groupBackupCodes":"",
          "addresses":[],
          "groupEmailAddresses":[],
          "tags":[],
          "groupTelephoneLines":[],
          "groupNoteEntries":[],
          "note":null,
          "url":""
        }, 
        "validationSchema": {
          "urlImage": {
            "type": "string",
            "nullable": true, 
            "required": false,
            "message": "Image is optional."
          },
          "name": {
            "type": "string",
            "required": true,
            "message": "Name is required."
          },
          // "groupNumber": {
          //   "type": "string",
          //   "required": false,
          //   "message": "Account number is required"
          // },
          // "pin": {
          //   "type": "string",
          //   "required": false,
          //   "message": "pin is required"
          // },
          // "groupBackupCodes": {
          //   "type": "string",
          //   "required": false,
          //   "message": "Account backup code is required"
          // },
          "addresses": {
            "type": "array",
            "required": false,
            "message": "aAddresses is optional."
          },
          "groupEmailAddresses": {
            "type": "array",
            "required": false,
            "message": "Eamial addresses is optional."
          },
          "tags": {
            "type": "array",
            "required": false,
            "message": "Tags is optional."
          },
          "groupTelephoneLines": {
            "type": "array",
            "required": false,
            "message": "Telephone lines is optional."
          },
          "groupNoteEntries": {
            "type": "array",
            "required": false,
            "message": "Entries is optional."
          },
          "note": {
            "type": "string",
            "required": false,
            "message": "Note is optional."
          },
          "url": {
            "type": "url",
            "required": false,
            "matches":true,
            "matches.regex":"^(?:$|(https?|ftps?|sftp|smtp|imap|pop3|dns|telnet|ssh|vpn|kerberos|rtsp|mms|file|data|about|magnet):\\/\\/[^\\s]+|data:[^,\\s]+,[^\\s]+|about:[^\\s]+|magnet:\\?xt=urn:[^\\s]+|file:\\/\\/\\/[^\\s]+|about:[^\\s]+)$",
            "matches.errorMessage":"Invalid URL format"
          }
        }
      },
      "userWidget": {
        "fields":{
          "urlImage": "",
          "name":"",
          "userNumber":null,
          "pin": null,
          "userBackupCodes":"",
          "addresses":[],
          "userEmailAddresses":[],
          "tags":[],
          "userTelephoneLines":[],
          "userNoteEntries":[],
          "note":null,
          "url":""
        }, 
        "validationSchema": {
          "urlImage": {
            "type": "string",
            "nullable": true, 
            "required": false,
            "message": "urlImage is required"
          },
          "name": {
            "type": "string",
            "required": false,
            "message": "Name is required"
          },
          "userNumber": {
            "type": "string",
            "required": false,
            "message": "Account number is required"
          },
          "pin": {
            "type": "string",
            "required": false,
            "message": "pin is required"
          },
          "userBackupCodes": {
            "type": "string",
            "required": false,
            "message": "Account backup code is required"
          },
          "addresses": {
            "type": "array",
            "required": false,
            "message": "addresses is required"
          },
          "userEmailAddresses": {
            "type": "array",
            "required": false,
            "message": "eamial addresses is required"
          },
          "tags": {
            "type": "array",
            "required": false,
            "message": "tags is required"
          },
          "userTelephoneLines": {
            "type": "array",
            "required": false,
            "message": "Phone lines is required"
          },
          "userNoteEntries": {
            "type": "array",
            "required": false,
            "message": "Entries is required"
          },
          "note": {
            "type": "string",
            "required": false,
            "message": "Note is required"
          },
          
          "url": {
            "type": "string",
            "required": false,
            "message": "URL is required"
          }
        }
      },
      "contactWidget": {
        "fields":{
          "urlImage": "",
          "name":"",
     
          "addresses":[],
          "contactEmailAddresses":[],
          "tags":[],
          "contactTelephoneLines":[],
          "contactNoteEntries":[],
          "note":null,
          "url":""
        }, 
        "validationSchema": {
          "urlImage": {
            "type": "string",
            "nullable": true, 
            "required": false,
            "message": "Image is opitional."
          },
          "name": {
            "type": "string",
            "required": false,
            "message": "Name is required."
          },
          // "contactNumber": {
          //   "type": "string",
          //   "required": false,
          //   "message": "Contact number is  opitional."
          // },
          // "pin": {
          //   "type": "string",
          //   "required": false,
          //   "message": "pin is required"
          // },
          // "contactBackupCodes": {
          //   "type": "string",
          //   "required": false,
          //   "message": "Account backup code is required"
          // },
          "addresses": {
            "type": "array",
            "required": false,
            "message": "Addresses is opitional."
          },
          "contactEmailAddresses": {
            "type": "array",
            "required": false,
            "message": "Eamial addresses is opitional."
          },
          "tags": {
            "type": "array",
            "required": false,
            "message": "Tags is is opitional."
          },
          "contactTelephoneLines": {
            "type": "array",
            "required": false,
            "message": "Telephone lines is opitional."
          },
          "contactNoteEntries": {
            "type": "array",
            "required": false,
            "message": "Entries is is opitional."
          },
          "note": {
            "type": "string",
            "required": false,
            "message": "Note is opitional."
          },
          "url": {
            "type": "url",
            "required": false,
            "matches":true,
            "matches.regex":"^(?:$|(https?|ftps?|sftp|smtp|imap|pop3|dns|telnet|ssh|vpn|kerberos|rtsp|mms|file|data|about|magnet):\\/\\/[^\\s]+|data:[^,\\s]+,[^\\s]+|about:[^\\s]+|magnet:\\?xt=urn:[^\\s]+|file:\\/\\/\\/[^\\s]+|about:[^\\s]+)$",
            "matches.errorMessage":"Invalid URL format"
          }
        }
      },
      "accessInfo": {
        "fields":{
          "name":"",
          "password":null,
          "challenge":null,
          "challengePrompt":null,
          "useChallenge":null,
          "secureKey":null,
          "securedPassword":"",
          "url":"",
          "urlImage":""
        }, 
        "validationSchema": {
         
        }
      },
      "contactInfo": {
        "fields":{
          "name":"",
          "email":null,
          "subject":null,
          "message":null
        }, 
        "validationSchema": {
         
        }
      }
    } 
}

export default dataModel;