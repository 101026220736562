import {SeqCrypto} from "./SeqCrypto";
function encodeToBase64(input) {
    return btoa(String.fromCharCode(...new TextEncoder().encode(input)));
}

function decodeFromBase64(base64) {
    return new TextDecoder().decode(Uint8Array.from(atob(base64), c => c.charCodeAt(0)));
}
// function isValidBase64(str) {
//     if (typeof str !== 'string') return false;

//     const base64Regex = /^[A-Za-z0-9+/]*={0,2}$/;
//     return str.length % 4 === 0 && base64Regex.test(str);
// }
function isValidBase64(str) {
    try {
        if (typeof str !== 'string') return false;

        // Base64 strings must have a length divisible by 4
        if (str.length % 4 !== 0) return false;

        // Valid Base64 characters
        const base64Regex = /^[A-Za-z0-9+/]*={0,2}$/;
        return base64Regex.test(str);
    } catch (error) {
        console.error("Validation Error:", error.message);
        return false;
    }
}
function safeAtob(base64) {
    if (!isValidBase64(base64)) {
        console.error(`Invalid Base64 string: "${base64}"`);
        throw new Error(`Invalid Base64 string: "${base64}"`);
    }
    return atob(base64);
}
function normalizeKey(key) {
    const keyBytes = new TextEncoder().encode(key); // Convert string to Uint8Array
    if (keyBytes.length === 16 || keyBytes.length === 24 || keyBytes.length === 32) {
        return keyBytes; // Key is already valid length
    }

    if (keyBytes.length < 16) {
        return new Uint8Array([...keyBytes, ...new Array(16 - keyBytes.length).fill(0)]); // Pad to 16 bytes
    }
    if (keyBytes.length < 24) {
        return new Uint8Array([...keyBytes, ...new Array(24 - keyBytes.length).fill(0)]); // Pad to 24 bytes
    }
    if (keyBytes.length < 32) {
        return new Uint8Array([...keyBytes, ...new Array(32 - keyBytes.length).fill(0)]); // Pad to 32 bytes
    }

    return keyBytes.slice(0, 32); // Trim to 32 bytes
}



async function transformJsonWithEncryption(json, encryptionKey, uid, challengeKey = null, secureKey = null) {
    const iv = crypto.getRandomValues(new Uint8Array(16)); // Generate random IV

    if (Array.isArray(json)) {
        const transformedMap = {};
        for (let i = 0; i < json.length; i++) {
            transformedMap[i] = await transformJsonWithEncryption(json[i], encryptionKey, uid, challengeKey, secureKey);
        }
        return transformedMap;
    }

    if (typeof json === 'object' && json !== null) {
        
        const transformedJson = {};

        for (const [key, value] of Object.entries(json)) {
            transformedJson[key] = await transformJsonWithEncryption(value, encryptionKey, uid, challengeKey, secureKey);
        }

        // Encrypt the password field if it exists
        if ('password' in transformedJson) {
            if (transformedJson.password) {
               
                let keys=await Promise.all([
                    encryptionKey,
                    secureKey,
                    challengeKey, 
                    uid]
                    .filter((key)=>( !!key))
                    .map(async (key)=>{
                        return await SeqCrypto.KEYGEN(new Uint8Array(normalizeKey(key))) 
                    }));
                    const { ciphertext, ivs } = await SeqCrypto.ENCRYPT(transformedJson.password, keys);
                    transformedJson.securedPassword=encodeToBase64(ciphertext);
                    transformedJson.ivs=ivs;
               
                transformedJson.password = ""; // Blank out the original password
                transformedJson.secureKey = ""; // Blank out secureKey field
                if (!!!transformedJson.useChallenge) {
                   transformedJson.challenge = ""; // Blank out challenge field
                   transformedJson.useChallenge = false; // Set useChallenge to false
                }
            } else {
                transformedJson.securedPassword = "";
            }
        }

        return transformedJson;
    }

    // Base64 encode strings
    if (typeof json === 'string') {
        return encodeToBase64(json);
    }
    // Return other primitives as-is
    return json;
}




async function reverseTransformJson(json, encryptionKey, uid, challengeKey = null, secureKey = null) {
    if (typeof json === 'object' && json !== null) {
        // Convert maps back to arrays if they have numeric keys
        const isArrayLike =
            !Array.isArray(json) &&
            Object.keys(json).every(key => !isNaN(parseInt(key, 10))); // Check if all keys are numeric

        if (isArrayLike) {
            const restoredArray = [];
            for (const [key, value] of Object.entries(json)) {
                const index = parseInt(key, 10);
                restoredArray[index] = await reverseTransformJson(value, encryptionKey, uid, challengeKey, secureKey);
            }
            return restoredArray;
        }

        const restoredJson = {};
        for (const [key, value] of Object.entries(json)) {
            restoredJson[key] = await reverseTransformJson(value, encryptionKey, uid, challengeKey, secureKey);
        }
       
        //console.log('securedPassword' in restoredJson);
        // Decrypt securedPassword if it exists
        if ('securedPassword' in restoredJson && 'ivs' in restoredJson) {
           
          
            if (restoredJson.securedPassword) {
                
                try {
                    let keys=await Promise.all([
                        encryptionKey,
                        secureKey,
                        challengeKey, 
                        uid]
                        .filter((key)=>( !!key))
                        .map(async (key)=>{
                          
                            return await SeqCrypto.KEYGEN(new Uint8Array(normalizeKey(key))) 
                        }));

                        restoredJson.securedPassword = decodeFromBase64(await SeqCrypto.DECRYPT(decodeFromBase64(json.securedPassword), keys, json.ivs));

                        restoredJson.ivs=json.ivs;
                } catch (error) {
                    
                    console.error("Decryption failed:", error);
                    restoredJson.password = null;
                }
            }
         
        }

        return restoredJson;
    }

    // Decode Base64 strings
    if (typeof json === 'string' && isValidBase64(json)) {
        return decodeFromBase64(json);
    }

    // Return primitives as-is
    return json;
}





async function decryptToJson(json, encryptionKey, uid, challengeKey = null, secureKey = null) {
    let restoredJson = json;
    challengeKey = null;
     secureKey = null;

        if ('securedPassword' in restoredJson && 'ivs' in restoredJson) {
           
          
            if (restoredJson.securedPassword) {
                // console.log([
                //     encryptionKey,
                //     secureKey,
                //     challengeKey, 
                //     uid]);
                try {
                    let keys=await Promise.all([
                        encryptionKey,
                        secureKey,
                        challengeKey, 
                        uid]
                        .filter((key)=>( !!key))
                        .map(async (key)=>{
                            return await SeqCrypto.KEYGEN(new Uint8Array(normalizeKey(key))) 
                        }));

                        restoredJson.password = await SeqCrypto.DECRYPT(decodeFromBase64(json.securedPassword), keys, json.ivs);
                        
                        restoredJson.ivs=json.ivs;
                } catch (error) {
                    
                    console.error("Decryption failed:", error);
                    restoredJson.password = null;
                }
           }
         
        // }

        return restoredJson;
    }

    return json;
}

async function Encrypt(textToEncrypt, generalKeys){

  let keys=await Promise.all(generalKeys
                    .filter((key)=>( !!key))
                    .map(async (key)=>{
                     
                        return await SeqCrypto.KEYGEN(new Uint8Array(normalizeKey(key)))
                    }));
  return await SeqCrypto.ENCRYPT(textToEncrypt, keys);

}

async function Decyrpt(textToDecrypt, generalKeys,ivs){
    let keys=await Promise.all(generalKeys
        .filter((key)=>( !!key))
        .map(async (key)=>{
            return await SeqCrypto.KEYGEN(new Uint8Array(normalizeKey(key)))
        }));
    let pwd = null;
   try{
    pwd=await SeqCrypto.DECRYPT(textToDecrypt, keys,ivs);
   }catch(error){
    return false;
   }
   return pwd;
  
  }

function debugReversal(json) {
    if (typeof json === 'object' && json !== null) {
        Object.entries(json).forEach(([key, value]) => {
            console.log(`Key: "${key}", Type: ${typeof value}`, value);
            if (typeof value === 'object' && value !== null) {
                debugReversal(value); // Recursively debug nested objects
            }
        });
    }
}
export const CryptoTransform = {
    DECRYPT_JSON: decryptToJson,
    NORMALIZEKEY: normalizeKey,
    SIMPLE_ENCRYPT: Encrypt,
    SIMPLE_DECRYPT: Decyrpt,
    TO_BASE64:transformJsonWithEncryption,
    TO_UTF8:reverseTransformJson,
}
