import React from 'react';
import { Card, CardBody, CardFooter } from '@material-tailwind/react';

const ExpandableCard = ({ config }) => {
  const { text, video, iframe, readmore } = config;

  // Function to render video based on URL
  const renderVideo = (url) => {
    if (url.includes('youtube.com') || url.includes('youtu.be')) {
      const videoId = url.split('v=')[1]?.split('&')[0];
      return (
        <iframe
          width="100%"
          height="315"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="YouTube video"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      );
    }
    if (url.includes('vimeo.com')) {
      const videoId = url.split('/').pop();
      return (
        <iframe
          width="100%"
          height="315"
          src={`https://player.vimeo.com/video/${videoId}`}
          title="Vimeo video"
          frameBorder="0"
          allow="autoplay; fullscreen"
          allowFullScreen
        ></iframe>
      );
    }
    return <p>Video format not supported</p>;
  };

  return (
    <Card className="mt-4 max-w-full">
      <CardBody>
        <p className="text-gray-700">{text}</p>
      </CardBody>

      {/* Render either video or iframe based on the config */}
      {video && !iframe && (
        <CardBody>
          <div className="video-container">{renderVideo(video)}</div>
        </CardBody>
      )}

      {iframe && !video && (
        <CardBody>
          <div className="iframe-container" dangerouslySetInnerHTML={{ __html: iframe }} />
        </CardBody>
      )}

      {readmore && (
        <CardFooter>
          <a
            href={readmore}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            Read More
          </a>
        </CardFooter>
      )}
    </Card>
  );
};

export default ExpandableCard;
