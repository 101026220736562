import React, { useState } from "react";
import {
  Typography,
  Button,
  Card,
  CardBody,
  CardHeader,
} from "@material-tailwind/react";
// import { CheckCircleIcon } from "@heroicons/react/24/solid";
// import { MinusCircleIcon } from "@heroicons/react/24/outline";
import { useUserSession } from '../../lib/session/UserSession';

import * as Icons from "@heroicons/react/24/solid"; // Adjust if using a different icon set
import LoadingDialog from "../../demo/platform/WaitDialog";

import StripePaymentDialog from "../subscription/StripePaymentDialog"
import BillingPortalDialog from "../subscription/BillingPortalDialog";
import Footer from "../../components/common/Footer";

import digital from "../../assets/digital.webp";
import { LoginDialog } from "@8d-xyz/ui-react_account_access-widget";
import logo from "../../assets/logo_200.png";
// import cards from "../../config/plans.json";
import cards from "../../config/Products.jsx";
import serviceTaskRunner, { services, _callRestfulService as restful } from "../../lib/restful/services/restAPIs";

import terms from "../../config/Terms";
import privacy from "../../config/Privacy";
const getIconComponent = (iconName, strokeWidth = 2) => {
  const IconComponent = Icons[iconName];
  return IconComponent ? <IconComponent className="h-5 w-5 text-blue-gray-900" strokeWidth={strokeWidth} /> : null;
};

function PricingCard({setIsLoading, pricePlanId, setPricePlan, setShowPayment, title, desc, price, options, pricePlan, plan, setPaymentLink }) {
  const [signUp, setSignUp] = useState(false);
  const session = useUserSession();
  const { user, logout, login } = session;
  return (
    <Card variant="gradient" color="white">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="!m-0 p-6"
      >
        <Typography
          variant="h6"
          color="blue-gray"
          className="capitalize font-bold mb-1"
        >
          {title}
        </Typography>
        <Typography
          variant="small"
          className="font-normal !text-gray-500"
        >
          {desc}
        </Typography>
        <Typography
          variant="h3"
          color="blue-gray"
          className="!mt-4 flex gap-1 !text-4xl"
        >
          {price[0]}
          {price[1]}
          <Typography
            as="span"
            color="blue-gray"
            className="-translate-y-0.5 self-end opacity-70 text-lg font-bold"
          >
            /{price[2]}
          </Typography>
        </Typography>
      </CardHeader>
      <CardBody className="pt-0">
        <ul className="flex flex-col gap-3 mb-6">
          {options.map((option, key) => (
            <li
              key={key}
              className="flex items-center gap-3 text-gray-700"
            >
              {getIconComponent(option.icon)}
              {/* {option.icon} */}
              <Typography
                variant="small"
                className="font-normal text-inherit"
              >
                {option.info}
              </Typography>
            </li>
          ))}
        </ul>
        {title != "Individual Minimalist" &&
          <Button fullWidth variant="gradient" color="gray"
          onClick={() => {
            setPricePlan((prev)=>pricePlan)
            setSignUp((prev) => true);
          }}
          >
            get started
          </Button>
        }
        {title == "Individual Minimalist" &&
          <Button fullWidth variant="gradient" color="gray"
            onClick={() => {
              setPricePlan((prev)=>pricePlan)
              setSignUp((prev) => true);
            }}
          >
            get started
          </Button>
        }

        {signUp && <LoginDialog
          open={signUp}
          onClose={() => setSignUp(false)}
          onSubmit={async (values) => {
            setIsLoading(true)
            let _SignInTaskRunner = await serviceTaskRunner(services.SIGN_IN)(
              values, {},
              async (response, callParams) => {
                //callBack function
                //Get return data from the restufl call
                const data = response.data.payload;
                let result = data;
                login(data, data.idToken);
                
                return result;
              },
              {
                //callBack Parameters
              },
              (error) => {
                setIsLoading(false);
                console.error(error)
                //throw { message: "Unable to authenticate user" };

              }
            );
            setIsLoading(false);
            return true;

          }}
          onSignup={
            async (values) => {
              setIsLoading(true)
              values.pricePlan=pricePlan;
              values.plan=plan;
              values.pricePlanId = pricePlanId
              
              let _SignUpTaskRunner = await serviceTaskRunner(services.SIGN_UP)(
                values, {},
                async (response, callParams) => {
                  //callBack function
                  //Get return data from the restufl call
                  const data = response.data.payload;
                  let result = data;
              
                  if(data.pricePlanId==="price_freemium" &&  data.PAYMENT_LINK==null){
                    login(data, data.idToken);
                  }else{
                    setPaymentLink(data.PAYMENT_LINK);
                    setShowPayment(true);
                  }

                  return result;
                },
                {
                  //callBack Parameters
                },
                (error) => {
                  setIsLoading(false);
                  console.error(error)
                  //throw { message: "Unable to to create user account." };
                }
              );
              setIsLoading(false)
              return true;
            }
            

          }
          onRecover={async () => { }}
          literals={
            {
              title: "Login or Create Account",
              customTitleLogin: "Welcome Back",
              customTitleSignup: "Create Your Account",
              customTitleRecover: "Reset Your Password",
              recoverInstruction: "We will send you a email to instruct you how to reset your password. Check your spam mail if you don't see it after a while.",
              accountname: "Account Name",
              password: "Password",
              email: "Email",
              confirmPassword: "Confirm Password",
              rememberMe: "Remember Me",
              login: "Sign In",
              promptLogin: "Already Have An Account?",
              returnToLogin: "Return To Login",
              signup: "Create Account",
              promptSignUp: "Create New Account",
              recover: "Forgot Password?",
              cancel: "Cancel",
              required: "This field is required",
              passwordMatch: "Passwords must match",
              termsOfService: "Terms of Service",
              privacyPolicy: "Privacy Policy",
              termsOfServiceContent: terms,
              termsAndPolicyLabel: "Upon signing in, you agree to abide by our ",
              termsOfPolicyContent: privacy
            }

          }
          defaultForm="signup"
          headerLogo={logo}
        />

        }
      </CardBody>
    </Card>
  );
}
export function Plans() {
  const [showPayment, setShowPayment] = useState(false);
  const [pricePlan, setPricePlan] = useState(null);
  const [paymentLink, setPaymentLink] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="flex flex-col min-h-screen">
      {/* Main content grows */}
      <main className="flex-grow flex">
        <section
          className="flex-grow w-full py-24 px-8 bg-red-50"
          style={{
            backgroundImage: `url(${digital})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div className="container mx-auto">
            <Typography
              variant="h2"
              color="blue-gray"
              className="mb-4 font-bold text-lg"
            >
              Take Control — Invest in Simplicity and Security for Your Information.
            </Typography>

            <Typography
              variant="lead"
              className="mb-10 font-normal !text-gray-900 max-w-xl"
            />

            <div className="grid gap-x-10 gap-y-8 md:grid-cols-2 lg:grid-cols-3 max-w-5xl">
              {cards.map(({ title, desc, options, price, pricePlan, plan, pricePlanId }, key) => (
                <PricingCard
                  setIsLoading={setIsLoading}
                  setPricePlan={setPricePlan}
                  setShowPayment={setShowPayment}
                  key={key}
                  title={title}
                  desc={desc}
                  price={price}
                  options={options}
                  pricePlan={pricePlan}
                  plan={plan}
                  setPaymentLink={setPaymentLink}
                  pricePlanId={pricePlanId}
                />
              ))}
            </div>
          </div>
        </section>
      </main>

      {/* Footer */}
      <footer className="bg-black text-white z-10">
        <Footer />
      </footer>

      {/* Modals */}
      <StripePaymentDialog
        paymentLink={paymentLink}
        open={showPayment}
        onClose={() => setShowPayment(false)}
      />
      <LoadingDialog open={isLoading} message="Processing please wait..." />
    </div>
  );
}

export default Plans;