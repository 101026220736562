import React, { useState } from "react";
import { Form, Field, useFormState, useForm } from "react-final-form";
import { Util } from "../../../lib/util/Util";
import {
  Box,
  TextField,
  MenuItem,
  IconButton,
  Tooltip,
  Button,
  Autocomplete,
  Typography
} from "@mui/material";
import { AddCircle, RemoveCircle, Delete } from "@mui/icons-material";
const config = {
  header: {
    title: "Addresses",
    addTooltip: "Add Address",
    removeAllTooltip: "Remove All Addresses",
    removeTooltip: "Remove Address",
    emptyMessage: "Click the '+' button to add a address.",
  },
  fieldLabels: {
    name: "Address Name", // New name field
    addressType: "Address Type",
    streetAddress: "Street Address",
    city: "City",
    state: "State",
    zipCode: "ZIP Code",
  },
  placeholders: {
    name: "Enter the address name", // Placeholder for the name field
    addressType: "Enter or select an address type",
    streetAddress: "Enter your street address",
    city: "Enter your city",
    state: "Select your state",
    zipCode: "Enter your ZIP code",
  },
  buttons: {
    addAddress: "Add Address",
    removeAddress: "Remove Address",
    submit: "Submit",
  },
  tooltips: {
    addAddress: "Add Address",
    removeAddress: "Remove this address",
  },
  predefinedAddressTypes: ["Home", "Work", "Billing", "Shipping", "Other"],
  states: [
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming",
  ],
};


const styles = {
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mb: 2,
  },

  recordBox: {
    mb: 3,
    p: 2,
    pt: 6,
    border: "1px solid #ccc",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    position: "relative",
  },
  addButtonContainer: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  removeButtonContainer: {
    position: "absolute",
    top: 8,
    right: 8,
  },
  removeAllButton: {
    color: "red",
  },
};



// const validate = (values) => {
//   const errors = {};
//   if (values.addressType && !predefinedAddressTypes.includes(values.addressType) && values.addressType.trim() === "") {
//     errors.addressType = "Address type is required";
//   }

//   if (values.streetAddress && values.streetAddress.trim() === "") {
//     errors.streetAddress = "Street address is required";
//   }

//   if (values.city && values.city.trim() === "") {
//     errors.city = "City is required";
//   }

//   if (values.state && values.state.trim() === "") {
//     errors.state = "State is required";
//   }

//   if (values.zipCode && values.zipCode.trim() === "") {
//     errors.zipCode = "ZIP Code is required";
//   }

//   return errors;
// };
export const getConfig = () => {
  return Util.JSON_COPY(config);
}
export const getStyles = () => {
  return Util.JSON_COPY(styles);
}

const USAddressForm = ({ config = getConfig(), styles = getStyles() }) => {
  const { values } = useFormState();
  const form = useForm();
  const [addresses, setAddresses] = useState([]);

  const handleAddAddress = (form) => {
    const currentValues = values.addresses || [];
    // [{ name: "", addressType: "", streetAddress: "", city: "", state: "", zipCode: "" }];

    form.change("addresses", [
      ...currentValues,
      { name: "", addressType: "", streetAddress: "", city: "", state: "", zipCode: "" },
    ]);
    // setAddresses((prev) => [...prev, { ...currentValues }]);
    //form.reset(); // Reset fields after adding
  };

  const handleRemoveAddress = (form, index) => {
    const currentAddresses = values.addresses || [];
    const updatedAddresses = currentAddresses.filter((_, i) => i !== index);
    form.change("addresses", updatedAddresses);
  };

  const handleRemoveAllAddresses = (form) => {
    form.change(addresses, []);
  };

  const handleChangeAddress = (form, index, field, value) => {
    const currentAddresses = values.addresses || [];
    const updatedAddresses = [...currentAddresses];
    updatedAddresses[index][field] = value;
    form.change("addresses", updatedAddresses);
  };

  return (

    <Box sx={{ width: "100%" }}>
      {/* Form Container */}
        <Box sx={styles.container}>
        <Typography variant="h6">{config.header.title}</Typography>
        <Box>
        <Tooltip title={config.header.addTooltip}>
            <IconButton onClick={() => handleAddAddress(form)} color="primary">
              <AddCircle />
            </IconButton>
          </Tooltip>
          <Tooltip title={config.header.removeAllTooltip}>
            <IconButton
              onClick={() => handleRemoveAllAddresses(form)}
              sx={styles.removeAllButton}
              disabled={!(values.addresses || []).length}
            >
              <RemoveCircle />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
        {/* Dynamic Credential Fields */}
        <Field name="addresses" subscription={{ value: true }}>
          {({ meta: { value: addresses } }) =>
            values.addresses && values.addresses.length > 0 ? (
              values.addresses.map((address, index) => (
                <Box
                  key={index}
                  sx={styles.recordBox}
                >

                  <Tooltip title="Remove Address">
                    <IconButton
                      onClick={() => handleRemoveAddress(form, index)}
                      color="secondary"
                      sx={{
                        position: "absolute",
                        top: 8,
                        right: 8,
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Tooltip>
                  <TextField
                    value={address.name}
                    onChange={(e) =>
                      handleChangeAddress(form, index, "name", e.target.value)
                    }
                    label={config.fieldLabels.name}
                    placeholder={config.placeholders.name}
                    fullWidth
                  // error={meta.touched && !!meta.error}
                  // helperText={meta.touched && meta.error}
                  />
                  {/* <Autocomplete
                    freeSolo
                    options={config.predefinedAddressTypes}
                    value={address.addressType || ""}
                    onChange={(_, value) => input.onChange(value)}
                    onInputChange={(_, value) => input.onChange(value)} // Allow freeform
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={address.addressType}
                        onChange={(e) =>
                          handleChangeAddress(form, index, "addressType", e.target.value)
                        }
                        label={config.fieldLabels.addressType}
                        placeholder={config.placeholders.addressType}
                        fullWidth
                      // error={meta.touched && !!meta.error}
                      // helperText={meta.touched && meta.error}
                      />
                    )}
                  /> */}



<Field name={`addresses[${index}].addressType`}>
  {({ input }) => (
    <Autocomplete
      freeSolo
      options={config.predefinedAddressTypes}
      value={input.value || ""}
      onChange={(_, value) => input.onChange(value)}
      onInputChange={(_, value) => input.onChange(value)} // Allow freeform input
      renderInput={(params) => (
        <TextField
          {...params}
          label={config.fieldLabels.addressType}
          placeholder={config.placeholders.addressType}
          fullWidth
        />
      )}
    />
  )}
</Field>

                  <TextField
                    value={address.streetAddress}
                    onChange={(e) =>
                      handleChangeAddress(form, index, "streetAddress", e.target.value)
                    }
                    label={config.fieldLabels.streetAddress}
                    placeholder={config.placeholders.streetAddress}
                    fullWidth
                  // error={meta.touched && !!meta.error}
                  // helperText={meta.touched && meta.error}
                  />
                  <TextField
                    value={address.city}
                    onChange={(e) =>
                      handleChangeAddress(form, index, "city", e.target.value)
                    }
                    label={config.fieldLabels.city}
                    placeholder={config.placeholders.city}
                    fullWidth
                  // error={meta.touched && !!meta.error}
                  // helperText={meta.touched && meta.error}
                  />

                  <TextField
                    value={address.state}
                    onChange={(e) =>
                      handleChangeAddress(form, index, "state", e.target.value)
                    }
                    label={config.fieldLabels.state}
                    placeholder={config.placeholders.state}
                    fullWidth
                    select
                  // error={meta.touched && !!meta.error}
                  // helperText={meta.touched && meta.error}
                  >
                    {config.states.map((state, index) => (
                      <MenuItem key={index} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    value={address.zipCode}
                    onChange={(e) =>
                      handleChangeAddress(form, index, "zipCode", e.target.value)
                    }
                    label={config.fieldLabels.zipCode}
                    placeholder={config.placeholders.zipCode}
                    fullWidth
                  // error={meta.touched && !!meta.error}
                  // helperText={meta.touched && meta.error}
                  />
                </Box>
              ))
            ) : (<Typography variant="body2" color="textSecondary" sx={styles.emptyMessage}>
                          {config.header.emptyMessage}
                        </Typography>
                      )}
        </Field>
      </Box>
    
  );
};

export default USAddressForm;
