import React, { useState } from "react";
import ChallengeWithCheckbox from "./ChallengeInput";
import CustomSecuredTextField,{getConfig,getStyles} from "../../common/form/CustomSecuredTextField";

import CustomPasswordWizField, {getConfig as wizConfig, getStyles as wizStyles}from "../../common/form/CustomPasswordWizField";
import { Form, Field, useFormState, useForm} from "react-final-form";;
const AddPasswordInfo = ({ collapsibleConfig, handleError={handleError}}) => {
  //const [showPasswordField, setShowPasswordField] = useState(false);
 // const [openDialog, setOpenDialog] = useState(false);
  //const [showSecureKey, setShowSecureKey] = useState(false);
 // const { values } = useFormState(); 
 // const form = useForm();
  // const handleToggleSecureKeyVisibility = () => {
  //   setShowSecureKey((prev) => !prev);
  // };

  return (
     <>
      <CustomPasswordWizField config={wizConfig()} styles={wizStyles()} handleError={handleError}/>
      <CustomSecuredTextField config={getConfig()} styles={getStyles()} handleError={handleError}/>
      <ChallengeWithCheckbox/>
   
    </>
  );
};

export default AddPasswordInfo;
