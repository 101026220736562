import React, { useEffect } from "react";
import { Dialog } from "@mui/material";
import dispatcher from "../../events/dispatcher";
import LoadingDialog from "../../demo/platform/WaitDialog";

const BillingPortalDialog = ({open, onClose }) => {
    //const stripePaymentLink = paymentLink; // Replace with your actual Stripe Payment Link

    useEffect(  () => {
        async function fetchData(){
            if (open) {
                let billingPortal = await dispatcher.BILLING_PORTAL_LINK({});
         
                const newTab = window.open(billingPortal.url, "_blank");
                if (newTab) {
                    window.open("about:blank", "_self"); // Fix for some browsers
                    window.close(); // Close the current parent tab
                } else {
                    alert("Unable to open subscription page. Please allow pop-ups and try again.");
                }
            }
        }
         fetchData();
        return () => {
        }
    }, [open, onClose]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
              <LoadingDialog open={true} message="Redirecting please wait..." />
            {/* The dialog will close immediately, so this won't be visible for long */}
        </Dialog>
    );
};

export default BillingPortalDialog;
