import React, { useState, useEffect } from "react";

import { styled } from '@mui/material/styles';


import { red, blue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Avatar,
  CardActions,
  IconButton,
  CardMedia,
  Typography,
  Box

} from "@mui/material";
import PasswordIcon from '@mui/icons-material/Password';
import {
  SyncLock as SyncLockIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  AddLink as AddAccessURL,
  SwitchAccount as AssociateToAccount,
  AvTimer as ExpirationInDays,
  Style as Tags,
  ContactEmergency as AdditionalInfo,
  EditNote as NoteIcon
} from "@mui/icons-material";

import ChallengeWithCheckbox from "./ChallengeInput";
import CustomSecuredTextField, { getConfig, getStyles } from "../../common/form/CustomSecuredTextField";
import CustomTextField, { getConfig as costomConfig, getStyles as customStyles } from "../../common/form/CustomTextField";
import useFormData from "../../../lib/util/useFormData";
import { Visibility, VisibilityOff, Clear } from "@mui/icons-material";
import CypherService from "../../../lib/util/CypherService";
import { CryptoTransform } from "../../../lib/util/ClientUtil"
import Collapsible from "../../common/Collapsible";
import eventDispatcher from "../../../events/dispatcher";
import { Form, Field, useFormState, useForm } from "react-final-form";
import dataModels from "../../../config/CentralizedDataModel"

import {genEntityImageUrl} from "../../../utils/common";

let styles={
  card: {
    maxWidth: 600,
    p: 0,
    marginTop: "10px",
    marginBottom: "25px",
    backgroundColor: "white",
    boxShadow: "0 2px 10px rgba(6, 81, 237, 0.3)",
    borderRadius: "0.375rem",
    color: "#333",

  },
  box: {
 
    pt:3
  
  },


};


const AccessInfoWiz = ({ collapsibleConfig, onSubmit = (values) => {  }, handleError = { handleError }, credential = { credential } }) => {
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [showSecureKey, setShowSecureKey] = useState(false);
  const [pwdUnlock, setPwdUnlock] = useState(false);
  const { formData, updateFormData, getFormValidationCombineSchema } = useFormData(dataModels);
  const validate = getFormValidationCombineSchema(["credentialWidget"]).validate;
  const [expanded, setExpanded] = React.useState(false);
  const [urlImage, setUrlImage] = React.useState(null);

  useEffect(() => {
    const fetchData = async () => {
      //console.log(credential.id)

      if (credential.id) {
        let data = await eventDispatcher.GET_ENTITY_RECORD(credential.parent.toUpperCase(), credential.id, "accessInfo", {}, () => { });

        //console.log(data);
        //console.log(formData.accessInfo)
        formData.accessInfo = { ...formData.accessInfo, ...data }
        await updateFormData("accessInfo",
          formData.accessInfo
        );
        setUrlImage(await genEntityImageUrl(credential.parent.toUpperCase(), credential.id));

      }
    };

    fetchData();
  }, []);
  const handleExpandClick = async (values) => {
    const ivs = !!formData.accessInfo.useChallenge ? formData.accessInfo.ivs.slice(-2) : formData.accessInfo.ivs.slice(-1);
    //console.log(ivs)
    let keys = [];
    if (!!values.secureKey) {
      keys.push(values.secureKey)
    }
    //  else{

    //   setPwdUnlock(false);
    //   setExpanded(false);
    //   return;
    // }

    if (!!formData.accessInfo.useChallenge) {
      if (!!values.challenge) {
        keys.push(values.challenge)
      }
      // }else{
      //     setPwdUnlock(false);
      //     setExpanded(false);
      //   return;
      // }
    }


    values.password = await CryptoTransform.SIMPLE_DECRYPT(formData.accessInfo.securedPassword, keys, ivs)
    
    await updateFormData("accessInfo", values);
    if (!!values.password) {

      setPwdUnlock(true);
      setExpanded(!expanded);

    } else {
      setPwdUnlock(false);
      setExpanded(false);
    }


    await updateFormData("accessInfo", values);

  };



  return (
    <Form
      initialValues={formData.accessInfo}
      validate={validate}
      onSubmit={onSubmit}
      render={({ handleSubmit, submitting, pristine, valid, values }) => (
        <form
          onSubmit={(e) => {
            e.preventDefault(); // Prevent default form submission
            handleSubmit(); // Submit on the last tab

          }}
        >
          <Box sx={styles.box}>
          <Card sx={styles.card}>
            <CardHeader sx={{ 'padding-left': '25px', 'padding-right': '25px' ,pb:0 }}
              avatar={
                <Avatar sx={{ bgcolor: blue[500] }} aria-label="credential">
                  {credential.text.charAt(0).toUpperCase()}
                </Avatar>
              }
              // title={credential.text}
              subheader={!!formData.accessInfo.url && (<a
                href={formData.accessInfo.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'blue', textDecoration: 'underline' }}
              >
               {formData.accessInfo.name}
              </a>)}
            />
            {credential.data.urlImage && (
              <a
                href={formData.accessInfo.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none' }}
              >
                <CardMedia sx={{ padding: "20px" }}
                  component="img"
                  height="100px"
                  image={ urlImage}
                  alt={formData.accessInfo.url}
                />   </a>)}
            {(formData.accessInfo.securedPassword!=null && formData.accessInfo.securedPassword!="") ? <>
            <CardContent>
              
              <CustomSecuredTextField config={getConfig()} styles={getStyles()} handleError={handleError} />
              {formData.accessInfo.useChallenge && (<>
              
              <Typography variant="subtitle1" style={{paddingLeft:"5px", paddingTop: "30px", paddingBottom:"0px" }}>
              {formData.accessInfo.challengePrompt}
               </Typography>  
                <CustomSecuredTextField config={{
                  fieldName: "challenge",
                  label: "Challenge",
                  inputType: {
                    text: "text",
                    password: "password",
                  },
                  tooltip: {
                    showSecureKey: "Show Challenge",
                    hideSecureKey: "Hide Challengeey",
                    clearSecureKey: "Clear Challenge",
                  },
                  style: {
                    textField: { mt: 3 },
                  },
                  adornmentPosition: "end",
                }} styles={getStyles()} handleError={handleError} />

              </>)
              }
             </CardContent>
            <CardActions disableSpacing>

              <Box sx={{
                display: 'flex',
                padding: '5px',
                border: '1px solid #ccc',
                borderRadius: '10px',
                margin: '5px',
                marginBottom: '10px',
                width: 1
              }} alignItems="center" gap={1}>

                <IconButton aria-label="See Password"
                  onClick={() => { handleExpandClick(values) }}>
                  {pwdUnlock && <Visibility />}
                  {!pwdUnlock && <VisibilityOff />}


                </IconButton><PasswordIcon />
                <Typography sx={{ padding: "5px" }}>
                  {pwdUnlock ? ((values.password === false) ?
                    "Invalid Secure Key And/Or Challenge. Try Again" : values.password) : "#####"}

                </Typography>
              </Box>
            </CardActions>
              </> : <><Typography
                variant="subtitle1"
                style={{
                  padding: "30px",
                  textAlign: "center",  // Horizontally center the text
                }}
              >
                No Secure Credential Available
              </Typography>
            </>
            }
          </Card>
          </Box>
        </form>
        
      )}
    />
  );
};

export default AccessInfoWiz;