import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Chip,
  IconButton,
  InputAdornment,
  Tooltip,
  Autocomplete,
} from "@mui/material";
import { AddCircle, Visibility, VisibilityOff, Clear, RestartAlt } from "@mui/icons-material";
import { Field, useFormState, useForm } from "react-final-form";
import { Util } from "../../../lib/util/Util";

const defaultConfig = {
  fieldName: "tags",
  textFieldLabel: "Add or Select Tag",
  tooltips: {
    addTag: "Add Tag",
    showTags: "Show Tags",
    hideTags: "Hide Tags",
    clearTags: "Clear Tags",
    resetTags: "Reset Tags"
  },
  chip: {
    color: "primary",
    variant: "outlined",
  },
  maxTags: 10, 
};

const styles = {
  tagBox: {
    display: "flex",
    flexWrap: "wrap",
    gap: 1,
    mb: 2,
    p: 2,
    border: "1px solid #ccc",
    borderRadius: "8px",
    minHeight: "80px",
  },
};

const initTags = (allTags, assignedTags) => {
  let candidateTags = allTags
    .filter(tag => !assignedTags.includes(tag) && tag.toUpperCase() !== "NO TAG"); // Exclude "NO TAG"

  return {
    candidateTags,
    initialTags: [...assignedTags],
  };
};

const areArraysEqual = (arr1, arr2) =>
  JSON.stringify([...arr1].sort()) === JSON.stringify([...arr2].sort());

export const getConfig = () => Util.JSON_COPY(defaultConfig);
export const getStyles = () => Util.JSON_COPY(styles);

const TagWiz = ({ config = getConfig(), styles = getStyles(), tags = [] }) => {
  const form = useForm();
  const { values } = useFormState();
  const { candidateTags, initialTags } = initTags(tags, values.tags || []);

  const [tagInput, setTagInput] = useState("");
  const [showTags, setShowTags] = useState(true);
  const [availableTags, setAvailableTags] = useState(candidateTags);

  useEffect(() => {
    setAvailableTags(tags.filter(tag => !values.tags?.includes(tag) && tag.toUpperCase() !== "NO TAG"));
  }, [values.tags, tags]);

  const handleAddTag = (newTag) => {
    newTag = newTag.trim();
    if (!newTag || (values.tags || []).length >= config.maxTags) return;
    if (newTag.toUpperCase() === "NO TAG") return; // Prevent adding "NO TAG"

    form.change("tags", [newTag, ...(values.tags || []).filter(tag => tag !== newTag)]);
    setTagInput("");
  };

  const handleDeleteTag = (tagToDelete) => {
    form.change("tags", (values.tags || []).filter(tag => tag !== tagToDelete));
  };

  const clearTags = () => form.change("tags", []);

  const resetTags = () => form.change("tags", [...initialTags]);

  return (
    <Box sx={{ width: "100%" }}>
      {showTags && values.tags?.length > 0 && (
        <>
          <p className="text-sm font-semibold pb-1">Tags:</p>
          <Box sx={styles.tagBox}>
            {values.tags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onDelete={() => handleDeleteTag(tag)}
                color={config.chip.color}
                variant={config.chip.variant}
              />
            ))}
          </Box>
        </>
      )}

      <Field name={config.fieldName} keepDirtyOnReinitialize>
        {({ input }) => (
          <Autocomplete
            freeSolo
            options={availableTags}
            value={tagInput}
            onInputChange={(event, newValue) => setTagInput(newValue)}
            onChange={(event, newValue) => newValue && handleAddTag(newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                label={config.textFieldLabel}
                fullWidth
                onBlur={() => setTagInput("")}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    handleAddTag(tagInput);
                  }
                }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip title={config.tooltips.addTag}>
                        <IconButton
                          onClick={() => handleAddTag(tagInput)}
                          color="primary"
                          disabled={!tagInput.trim() || (values.tags || []).length >= config.maxTags}
                        >
                          <AddCircle />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={showTags ? config.tooltips.hideTags : config.tooltips.showTags}>
                        <IconButton onClick={() => setShowTags(!showTags)} color="primary">
                          {showTags ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={config.tooltips.clearTags}>
                        <IconButton onClick={clearTags} color="secondary" disabled={!values.tags?.length}>
                          <Clear />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title={config.tooltips.resetTags}>
                        <IconButton onClick={resetTags} color="secondary" disabled={areArraysEqual(values.tags, initialTags)}>
                          <RestartAlt />
                        </IconButton>
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}
      </Field>

      {availableTags.length > 0 && (
        <>
          <p className="text-sm font-semibold pt-4 pb-1">Available Tags:</p>
          <Box sx={{ ...styles.tagBox, border: "1px dashed #ccc" }}>
            {availableTags.map((tag, index) => (
              <Chip
                key={index}
                label={tag}
                onClick={() => handleAddTag(tag)}
                color="secondary"
                variant="outlined"
                sx={{ cursor: "pointer" }}
              />
            ))}
          </Box>
        </>
      )}
    </Box>
  );
};

export default TagWiz;
