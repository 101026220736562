import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import {ApplicationContextStoreProvider}  from "@8d-xyz/ui-react_application_f-container"
import ApplicationContextStore from './ApplicationContextStore';
import appConfigObj from './ApplicationContextConfig.json';
import {appInit} from './ApplicationStart';
//import { UserSessionProvider } from '@8d-xyz/ui-react_account_access-widget';
import { UserSessionProvider } from './lib/session/UserSession'; 
import App from "./App";
import "./index.css";
const rootElement = document.getElementById("root");
const root = createRoot(rootElement);
root.render(
  <StrictMode>
      <ApplicationContextStoreProvider
        appContextStore = {ApplicationContextStore}
        //configUrl="/data/dev/ApplicationContextConfig.json"
        appConfigObj={appConfigObj}
        appInit={appInit} 
      >
        <UserSessionProvider >
        <App />
       </UserSessionProvider>
      </ApplicationContextStoreProvider>
  </StrictMode>
);

