import React, { createContext, useContext, useState } from 'react';
import SessionData from "./sessionData.json";
// Create the UserSessionContext
const UserSessionContext = createContext();

// Custom hook to access the UserSessionContext
export const useUserSession = () => {
  return useContext(UserSessionContext);
};

// Provider component to wrap your application
export const UserSessionProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Initial user state is null
  const [token, setToken] = useState(null); // Token for authenticated sessions
  const [tagEntities, setTagEntities] = useState(null); // Token for authenticated sessions
  const [sessionStore, setSessionStore]= useState({...SessionData});
  // Function to log in a user
  const login = (userData, authToken) => {
    setUser(userData);
    setToken(authToken);
    SessionData.SESSION.authToken=authToken;
    SessionData.SESSION.userData=userData;
    setSessionStore({...SessionData});
    // Optionally save session data in localStorage/sessionStorage
    //localStorage.setItem('user', JSON.stringify(userData));
    //localStorage.setItem('token', authToken);
  };
    
  // Function to log out the user
  const logout = () => {
    setUser(null);
    setToken(null);
    SessionData.SESSION.authToken=null;
    SessionData.SESSION.userData=null;
   // localStorage.removeItem('user');
   // localStorage.removeItem('token');
  };

  const sessionData=SessionData;
  // Initialize the session from localStorage
//   React.useEffect(() => {
//    //const storedUser = localStorage.getItem('user');
//    // const storedToken = localStorage.getItem('token');
//    // if (storedUser && storedToken) {
//    //   setUser(JSON.parse(storedUser));
//    //   setToken(storedToken);
//    // }
//   }, []);

  return (
    <UserSessionContext.Provider value={{ user, token,
      setUser,
      login, 
      logout, 
      tagEntities,
      setTagEntities,
      sessionData, 
      sessionStore, 
    setSessionStore}}>
      {children}
    </UserSessionContext.Provider>
  );
};
