import serviceTaskRunner, { services, _callRestfulService as restful } from "../lib/restful/services/restAPIs";
import { CryptoTransform } from "../lib/util/ClientUtil";
import { SeqCrypto } from "../lib/util/SeqCrypto";
import {
  getUrlImageType,
  resizeImageDataString,
  base64ToFile,
  reduceImageResolution,
  reduceImageResolutionByHeight,
  reduceFileImageResolution
} from "../utils/common";
import { v4 as uuid } from "uuid";
import sessionData from "../lib/session/sessionData.json"
import { ClientMediator } from '@8d-xyz/ui-react_application_f-container';

                                        
export const UPLOAD_USER_AVATAR = async (type, values, setIsLoading) => {
  let payload=null;
  //sessionData.USER_PROFILE.avatar = "";
  let isBase64ImageFile = await getUrlImageType(values);
  //alert(setIsLoading);
  setIsLoading(true);

  values.id = "0";

  if (isBase64ImageFile != "invalid" && isBase64ImageFile.startsWith("url")) {

  } else if (values.urlImage instanceof File ||  isBase64ImageFile.startsWith("dataString") ) { //it is base 64 image url or a image file
    let fileName = type + "_" + values.id;
    
    // let fileName = values.id;
    let file = null;
    if(isBase64ImageFile.startsWith("dataString")){ //base 64 image string
      file = base64ToFile(await reduceImageResolution(values.urlImage, 200), fileName)
    }else{
      //actual image file
      file = await reduceFileImageResolution(values.urlImage, 200, fileName , 0.8) ;
    }
   
    const formData = new FormData();
    formData.append('files', file);
    payload = await serviceTaskRunner(services.UPLOAD_FILES)(
      formData, { 'content-type': "multipart/form-data" },
      async (response, callParams) => {
      
        const data = response.data.payload;
       
        values.urlImage = data[0].url;
       
        return await serviceTaskRunner(services.UPDATE_ACCOUNT_PROFILE)(
        {avatar: values.urlImage },
          async (response, callParams) => {
              //callBack function
              //Get return data from the restufl call
              const data = response.data.payload;
           
              //do something
              return data;
          }, 
          {
          //callBack Parameters
          }, 
          (error)=>{
            //alert("what")
          }
          );

      
      },
      {
        //callBack Parameters
      },
      (error)=>{
        console.error(error);
        setIsLoading(false);
      }
    );
   
  }
  setIsLoading(false);
 return payload
}

                                    
export const UPDATE_ACCOUNT_USER_PROFILE = async (values, setIsLoading) => {
  setIsLoading(true);
       let data = await serviceTaskRunner(services.UPDATE_ACCOUNT_PROFILE)(
          values,
          async (response, callParams) => {
              //callBack function
              //Get return data from the restufl call
              const data = response.data.payload;
              
              return data;
          }, 
          {
          //callBack Parameters
          }, 
          (error)=>{
            console.error(data);
            setIsLoading(false)
          }
          );
          setIsLoading(false)
       return data;
}
