import React, { useState, useRef } from "react";
import styles from "./ToggleMenu.module.css";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import MenuList from "@mui/material/MenuList";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/QueueSharp";
import RenameIcon from "@mui/icons-material/DriveFileRenameOutline";
import CopyIcon from "@mui/icons-material/FileCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from '@mui/icons-material/EditNote';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import SyncLockIcon from '@mui/icons-material/SyncLock';
export default function PositionedMenu({ ...props }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    props.setHover(false);
  };

  return (
    <div>
      <div>
        <IconButton size="small" onClick={handleClick}>
          <MoreVertIcon fontSize="small" />
        </IconButton>
      </div>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}  //when anchorE1 is not null imply true thus show Menu component
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
       {/* {props.node.droppable &&  (
        
        <MenuItem
          onClick={() => {
            // props.onAdd(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <EditNoteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Overview</ListItemText>
        </MenuItem>
      )} */}
   
        
        {props.node.data.fileType.toUpperCase()=="PASSWORD" && (
         <MenuItem
          onClick={() => {
            props.onInfo(true);
            handleClose();
          }}
        >
          <ListItemIcon>
            <SyncLockIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Access</ListItemText>
        </MenuItem>)}
    
        <MenuItem
      
          onClick={() => {
            props.onEdit(props.node);
           // props.onAdd(true);
     
            handleClose();
          }}
        >
          <ListItemIcon>
          <EditNoteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
           
            props.onCopy(props.node);
            handleClose();
          }}
        >
          <ListItemIcon>
            <CopyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Copy</ListItemText>
        </MenuItem>

        <MenuItem
          onClick={() => {
            props.onDelete(props.node);
            handleClose();
          }}
        >
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  );
}
