import React, { useState, useContext, useEffect } from "react";
import { useUserSession } from './lib/session/UserSession';
import { Card, CardBody, CardHeader, Tabs, Tab, Typography } from '@material-tailwind/react';
import DialogAlert from "./demo/platform/DialogAlert";
import InfoManager from "./components/organizer/infoManager/App";
import SessionData from "./lib/session/sessionData.json";
import SiteAnnouncement from "./demo/platform/SiteAnnouncement"
import { ApplicationContainer, AppDataFetcher, AppUtils, ApplicationContextStoreProvider, ClientMediator } from '@8d-xyz/ui-react_application_f-container';
import eventDispatcher from "./events/dispatcher";
import NavWidget from "./components/containers/NavWidget"
import BarWidget from "./components/containers/BarWidget"
import Footer from "./components/common/Footer";
import { IconSelector } from "./components/organizer/common/IconSelector";

import {
  Tree,
  MultiBackend,
  getBackendOptions,
  DndProvider,
  getDescendants,
  isAncestor,
  hasChildNodes,
} from "@minoru/react-dnd-treeview";

const {
  publishEvent,
  broadcastEvent,
  listenForEvent,
  getEventConfig,
  setEventConfig,
  startMediator } = ClientMediator;



const ApplicationModalWidgetAlert = () => {
  let applicationModalPanelContainerAPIs = AppUtils.getAPIs("ApplicationContainer").ModalPanel;
  return <DialogAlert
    apis={applicationModalPanelContainerAPIs}
    modalConfig={promptMessage}
    onClose={() => {
      applicationModalPanelContainerAPIs.closeOpen.exec(false);
    }} />
};


const adWidgetL = () => {
  return <></>

};
const adWidgetR = () => {
  //return <div className="bg-red-300 flex flex-grow items-center justify-center h-full" />
  return <div/>
};

const adWidgetT = () => {
  //return <div className="bg-orange-300 w-full flex-grow flex flex-col h-20" />
  return <div />
};
const adWidgetB = () => {
  //return <div className=" bg-green-300 flex items-center justify-center flex-grow h-full" />;
  return <div />;
};

const ApplicationBarWidget = () => {
  const { user, logout, login, sessionData, setTagEntities } = useUserSession();
  // if(user)//console.log(user.APP_FEATURES);
  return user ? <BarWidget /> : <NavWidget />
};

const AuxiliaryWidget = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const { user, logout, login, sessionData } = useUserSession();
  const [tabs, setTabs] = useState((user) ? user.APP_TABS : []);

  return <div className="flex flex-grow ">
    {user &&
      <DndProvider backend={MultiBackend} options={getBackendOptions()}>
        <Card className="flex flex-grow overflow-none rounded-none">
          <div className="bg-gray-100 h-full w-full overflow-auto">
            <Tabs
              value={selectedTab}
              onChange={(index) => setSelectedTab(index)}
              className="rounded-t-lg flex w-90 ml-4 mr-4 mt-2 mb-1 border-b bg-gray-100 border-gray-300 min-w-[200px]"
            >
              {tabs.map((tab, index) => (
                <Tab

                  value={index}
                  className="capitalize flex-1 text-center py-2 bg-gray-100 border-gray-300"
                  onClick={() => setSelectedTab(index)}

                >
                  <div className="flex items-center justify-center">
                    {IconSelector(tab.id, "TABS", (selectedTab == index))}
                    <Typography className="ml-2">{tab.label}</Typography>
                  </div>
                </Tab>

              ))}
            </Tabs>

            {/* Tab content */}
            {tabs.map((tab, index) => (
              <div
                key={tab.id}
                className={`w-90 bg-gray-50 h-full ml-4 mr-4 mb-4 ${selectedTab === index ? '' : 'hidden'}`}
              >
                <InfoManager nodes={eventDispatcher.GET_ORGANIZER_BY_TYPE(tab.id, {}, () => { })} />
              </div>
            ))}
          </div>
          <Footer />
        </Card>
      </DndProvider>
    }
  </div>

};



const MainPanelWidget = () => {
  const [eventMessage, setEventMessage] = useState("");
  const { user, logout, login, sessionData, setSessionStore } = useUserSession();

  // ClientMediator.listenForEvent(`SC_S-APP-ENTITY_CHANGED`, async (data) => {
  //   let entities = await eventDispatcher.GET_ORGANIZER_BY_TYPE("tags", {}, () => { });
  //   SessionData.ORGANIZERS.TAGS = Object.values(entities).map((entry) => {
  //     return entry.TAG;
  //   });
  //   setSessionStore({ ...SessionData });

  // });

  let containerAPIs = AppUtils.getAPIs("ApplicationContainer");

  if (!user) {
    containerAPIs.LeftPanel.closeOpen.exec(false);
    containerAPIs.TopPanel.closeOpen.exec(false);
    containerAPIs.AuxPanel.closeOpen.exec(false);
    containerAPIs.AppBarPanel.closeOpen.exec(true);
    containerAPIs.BottomPanel.closeOpen.exec(false);
    containerAPIs.RightPanel.closeOpen.exec(false);
  } else {
    containerAPIs.RightPanel.closeOpen.exec(false);
    containerAPIs.AuxPanel.closeOpen.exec(true);
    containerAPIs.MainPanel.closeOpen.exec(false);
    //this is set to be used by non react component; 
    SessionData.USER_PROFILE.uid = user.uid;
  }
  return (

    <div className="bg-[#fcfcfc]  h-full w-full p-0 overflow-none flex-grow flex">
      <div className="bg-[#fcfcfc] min-h-screen p-0" style={{
        height: '100vh',
        width: '100%',
      }}>

        {!user && (AppUtils.getRouteContext().context)}

        <SiteAnnouncement open={process.env.APP_STATUS !== "ACTIVE"} message="We're currently performing scheduled maintenance.
We'll be back shortly — thanks for bearing with us!" mode="maintenance" />
      </div>
    </div>
  );
};

const loadWidgets = () => {
  let applicationWidgets = AppUtils.getAppWidgets("ApplicationContainer")?.containers;
  applicationWidgets.AdContainers.Left = adWidgetL;
  applicationWidgets.AdContainers.Right = adWidgetR;
  applicationWidgets.AdContainers.Top = adWidgetT;
  applicationWidgets.AdContainers.Bottom = adWidgetB;
  applicationWidgets.ApplicationBarContainer.ApplicationBarPanelContainer = ApplicationBarWidget;
  applicationWidgets.MainContainer.AuxiliaryPanelContainer = AuxiliaryWidget;
  applicationWidgets.MainContainer.MainPanelContainer = MainPanelWidget;
  applicationWidgets.ApplicationModalContainer.ApplicationModalPanelContainer = ApplicationModalWidgetAlert;
  return applicationWidgets;
}
export const appInit = () => {
  loadWidgets();
  let mediatorConfig = AppUtils.getAppConfigs("Mediator");
  if (mediatorConfig.enabled) {
    loadMediator();
  }

}
const loadMediator = () => {
  let mediatorConfig = AppUtils.getAppConfigs("Mediator");
  AppUtils.getAppContextNodeByCat("app_context").ApplicationContext["clientMediator"] = ClientMediator;
  setEventConfig(mediatorConfig.configs);
  startMediator(true);
}

