// src/context/MyContext.js
import { createContext,useContext } from 'react';

const ApplicationContextStore = createContext(null);


export const useApplicationContextStore = () => {
    const context = useContext(ApplicationContextStore);
    if (!context) {
      throw new Error('useApplicationContextStore must be used within a ApplicationContextStoreProvider');
    }
    return context;
  };

export default ApplicationContextStore;