import { useState } from "react";
import { AppUtils } from "@8d-xyz/ui-react_application_f-container";
import * as Yup from "yup";
import cloneDeep from "lodash/cloneDeep";
const {
    getAppConfigs,
} = AppUtils;


function useFormData(centralizedDataModel) {
    if(!!!centralizedDataModel){
      centralizedDataModel = cloneDeep(getAppConfigs().Configs.formDataModel);
    }
    const [formData, setFormData] = useState(() => {
        const initialData = {};
        for (const formKey in centralizedDataModel.forms) {
            initialData[formKey] = centralizedDataModel.forms[formKey].fields;
        }
        return initialData;
    });
    const updateFormData =  async (formKey, updatedValues) => {
        setFormData((prevData) => ({
            ...prevData,
            [formKey]: { ...prevData[formKey], ...updatedValues },
        }));
    };
    const generateYupSchema = (fieldsConfig) => {
      const schemaFields = {};
    
      Object.keys(fieldsConfig).forEach((fieldKey) => {
        const field = fieldsConfig[fieldKey];
    
        // Start with base validation type
        let validator = null;
        
        switch (field.type) {
          case 'string':
            validator = Yup.string();
            break;
          case 'number':
            validator = Yup.number();
            break;
          case 'boolean':
            validator = Yup.boolean();
            break;
          case 'date':
            validator = Yup.date();
            break;
          case 'array':
            validator = Yup.array();
            break;
          case 'url':
            validator =Yup.string();
            break;
          default:
            throw new Error(`Unsupported field type: ${field.type}`);
        }
    
        // Apply additional options
        if (field.matches) {
          if(field.type==="url"){
            validator = validator.matches(field["matches.regex"], field["matches.errorMessage"]);
          }
        }
        if (field.nullable) {
          validator = validator.nullable(); // Allow null values
        }
        if (field.required) {
          validator = validator.required(field.errorMessage || `${fieldKey} is required`);
        } else {
          validator = validator.notRequired(); // Explicitly mark as not required
        }
        if (field.min) {
          validator = validator.min(field.min, field.errorMessage || `${fieldKey} must be at least ${field.min}`);
        }
        if (field.max) {
          validator = validator.max(field.max, field.errorMessage || `${fieldKey} must be at most ${field.max}`);
        }
        schemaFields[fieldKey] = validator;
      });
    
      return Yup.object().shape(schemaFields);
    };
    
    const getFormValidationCombineSchema = (formKeys) => {
     
      if (!Array.isArray(formKeys)) {
        throw new Error("formKeys must be an array of form names");
      }
  
      const schemas = formKeys.reduce((acc, formKey) => {
        const form = centralizedDataModel.forms[formKey];
        if (!form) {
          throw new Error(`Form with name "${formKey}" does not exist in the centralized data model.`);
        }
        acc[formKey] = generateYupSchema(form.validationSchema);
        return acc;
      }, {});
  
      return {
        validate: async (values) => {
          let errors = {};
          for (const formKey of formKeys) {
           
            try {
              await schemas[formKey].validate(values, { abortEarly: false });
            } catch (err) {
              if (!errors) errors = {};
          
                err.inner.forEach((error) => {
                console.error(formKey+" - "+error)
                errors[error.path] = error.message;
              });
           
            }
          }
  
          return errors;
        }
      };
    };

    const getFormValidationSchema = (formKeys) => {
     
        if (!Array.isArray(formKeys)) {
          throw new Error("formKeys must be an array of form names");
        }
    
        const schemas = formKeys.reduce((acc, formKey) => {
          const form = centralizedDataModel.forms[formKey];
          if (!form) {
            throw new Error(`Form with name "${formKey}" does not exist in the centralized data model.`);
          }
          acc[formKey] = generateYupSchema(form.validationSchema);
          return acc;
        }, {});
    
        return {
          validate: async (values) => {
          
            let errors = {};
            for (const formKey of formKeys) {
              try {
                errors[formKey] = errors[formKey]?errors[formKey]:{};
                await schemas[formKey].validate(values[formKey], { abortEarly: false });
              } catch (err) {
                if (!errors) errors = {};     
                  err.inner.forEach((error) => {
                  errors[formKey][error.path] = error.message;
                });
             
              }
            }
    
            return errors;
          }
        };
      };
    return {formData, updateFormData, getFormValidationSchema,getFormValidationCombineSchema}

}

export default useFormData;
