import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import Credentials from '@mui/icons-material/AssuredWorkload';
import Groups from '@mui/icons-material/Diversity2';
import Users from '@mui/icons-material/Person2';
import Credential from '@mui/icons-material/Password';
import User from '@mui/icons-material/Portrait';
import Accounts from '@mui/icons-material/AccountBalance';
import Account from '@mui/icons-material/AccountBalanceWallet';
import Group from '@mui/icons-material/SupervisedUserCircle';
import Contact from '@mui/icons-material/Contacts';
import Contacts from '@mui/icons-material/RecentActors';
export const TypeIcon = (props) => {
  if (props.droppable) {
    switch (props.fileType) {
      case "credentials":
        return <Credentials style={{ color: "blue" }} />;
      case "users":
        return <Users style={{ color: "green" }} />;
      case "groups":
        return <Groups style={{ color: "grey" }} />;
      case "accounts":
        return <Accounts style={{ color: "orange" }} />;
      case "contacts":
        return <Contacts style={{ color: "red" }} />;
      default:
        <FolderIcon />
    }

  }

  switch (props.fileType) {
    case "password":
      return <Credential style={{ color: "blue" }} />;
    case "user":
      return <User style={{ color: "green" }} />;
    case "contact":
        return <Contact style={{ color: "red" }} />;
    case "account":
      return <Account style={{ color: "orange" }} />;
    case "group":
      return <Group style={{ color: "grey" }} />;
    case "image":
      return <ImageIcon />;
    case "csv":
      return <ListAltIcon />;
    case "text":
      return <DescriptionIcon />;
    default:
      return null;
  }
};

