import React, { useState } from "react";
import { TextField, IconButton, InputAdornment, Box, Tooltip, Typography } from "@mui/material";
import { Visibility, VisibilityOff, Lock, Security, Shield, Autorenew } from "@mui/icons-material";
import {Field, useFormState, useForm} from "react-final-form";
import zxcvbn from "zxcvbn";
import CloseIcon from "@mui/icons-material/Close";
import {Util} from "../../../lib/util/Util";



// Configuration object to hold all literals
const config  = {
  fieldName: "password",
  label: "Enter Password",
  tooltip: {
    passwordStrength: "Password strength",
    generatePassword: "Generate Password",
    clearPassword: "Clear Password",
  },
  inputType: {
    text: "text",
    password: "password",
  },
  variant: "outlined",
  margin: "normal",
};
const styles = {
  box: { width: "100%" },
  field:{
    backgroundColor: "#FFFFFF", // Input background
    borderRadius: "4px", // Input border radius
    pr: "10px", // Adjust padding
  }
  
};
export const getConfig=()=>{
  return Util.JSON_COPY(config);
}
export const getStyles=()=>{
  return Util.JSON_COPY(styles);
}




const CustomPasswordWizField = ({config=config, styles=styles,   handleError}) => {

  const { values } = useFormState(); 
  const form = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  // Generate a Random Password
  const generatePassword = () => {
    const length = 12;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@$!%*?&";
    let password = "";
    for (let i = 0; i < length; i++) {
      password += charset.charAt(Math.floor(Math.random() * charset.length));
    }

    form.change("password",password);
  
  };

  // Evaluate Password Strength using zxcvbn
  const evaluatePasswordStrength = (password) => {
    const result = zxcvbn(password);
    const score = result.score; // Score ranges from 0 (weak) to 4 (very strong)
    const feedback = result.feedback.suggestions.join(" ") || "Good password.";
    let strengthInfo;

    switch (score) {
      case 0:
        strengthInfo = { label: "Weak", color: "red", icon: <Lock />, feedback };
        break;
      case 1:
        strengthInfo = { label: "Weak", color: "orange", icon: <Lock />, feedback };
        break;
      case 2:
        strengthInfo = { label: "Fair", color: "yellow", icon: <Shield />, feedback };
        break;
      case 3:
        strengthInfo = { label: "Strong", color: "green", icon: <Security />, feedback };
        break;
      case 4:
        strengthInfo = { label: "Very Strong", color: "darkgreen", icon: <Security />, feedback };
        break;
      default:
        strengthInfo = { label: "Unknown", color: "gray", icon: <Lock />, feedback: "Unknown strength." };
    }

    return strengthInfo;
  };

  const passwordStrength = evaluatePasswordStrength(values?.password || "");

  return (
    <Box sx={styles.box}>
    <Field name={config.fieldName} keepDirtyOnReinitialize={true}   subscription={{ value: true, pristine: true, error: true, touched: true }}>
      {({ input, meta }) => {
             if(handleError){
              handleError(!!meta.error);
              }
        return (
          <TextField
            {...input}
            label={config.label}
            fullWidth
            margin={config.margin}
            error={!!meta.error && meta.touched}
            helperText={meta.touched && meta.error}
            type={showPassword ? config.inputType.text : config.inputType.password}
            variant={config.variant}
            InputProps={{ 
              passwordStrengthIcon: (color) => ({ color }),
              style:{...styles.field},
              endAdornment: (
                <InputAdornment position="end">
                 
                  {/* Password Strength Indicator */}
                  <Tooltip title={config.tooltip.passwordStrength}>
                    <IconButton edge="end" sx={{ color: passwordStrength.color }}>
                      {passwordStrength.icon}
                    </IconButton>
                  </Tooltip>

                  {/* Toggle Password Visibility */}
                  <IconButton onClick={togglePasswordVisibility} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>

                  {/* Generate Password */}
                  <Tooltip title={config.tooltip.generatePassword}>
                    <IconButton onClick={generatePassword} edge="end">
                      <Autorenew />
                    </IconButton>
                  </Tooltip>

                  {/* Clear Password */}
                  <Tooltip title={config.tooltip.clearPassword}>
                    <IconButton onClick={() => form.change(config.fieldName, "")} edge="end">
                      <CloseIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        );
      }}
    </Field>
  </Box>
   );
};

export default CustomPasswordWizField;
