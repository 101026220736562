import * as React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function DialogDemo({apis,onClose, modalConfig={
  openPrompt:true,
  backgroundColor:'bg-red-100',  // default background color
  title:'Set title',  // default title
  message:'Set message',
  buttonLabel:'Set Button Label',
  buttonColor:'error',
  size:"sm",
  headerStyle:"text-red-600",
  bodyDividerStyle:"text-gray-800"
}}) {

  const [config, setConfig] = React.useState(modalConfig);
  const handleClose = () => {
    onClose();
  };

  return (
    <React.Fragment>
          <Dialog 
          open={apis.isShown.exec()} 
          onClose={()=>handleClose()} 
          aria-labelledby="modal-prompt-title" 
          aria-describedby="modal-prompt-description"
          maxWidth={config.size} // sets max width for medium screens
          fullWidth // makes the dialog take full width up to maxWidth
    >
          {/* <Box
        sx={{
          width: { xs: '90%', sm: '70%', md: '50%' },  // Responsive width
          maxWidth: 600,  // Maximum width for larger screens
          p: 2,  // Padding
          bgcolor: backgroundColor,
          borderRadius: 2,
        }}
      > */}
      {/* Dialog Container */}
      <div className={`${config.backgroundColor}`}>
        
        {/* Dialog Header */}
        <DialogTitle id="modal-prompt-title" className="font-semibold">
          {config.title}
        </DialogTitle>

        {/* Dialog Body */}
        {/* <DialogContent dividers>
          <p id="modal-prompt-description" className="text-gray-800">
            {message}
          </p>
        </DialogContent> */}
        <DialogContent >
        {/* <p id="modal-prompt-description" className="text-gray-800">
            {message}
          </p> */}
          <DialogContentText id="alert-dialog-description"  className="text-gray-800" >
          {config.message}
          </DialogContentText>
        </DialogContent>

        {/* Dialog Footer */}
        <DialogActions>
          <Button variant="contained" color={config.buttonColor} onClick={handleClose} className={`capitalize`}>
            {config.buttonLabel}
          </Button>
        </DialogActions>
      </div>
      {/* </Box> */}
    </Dialog>
      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Use Google's location service?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog> */}
      
    </React.Fragment>
  );
}