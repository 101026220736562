import React, { useState } from "react";
import {
  Box,
  Button,
  Tooltip,
  IconButton,
  Typography,
} from "@mui/material";

import PrimaryIcon from "@mui/icons-material/Style";
import CloseIcon from "@mui/icons-material/CloseFullscreen";

export const getConfig =()=> { return {
  visible: false,
  instructions:
    "This feature provides an optional way to enhance your data organization. You can add additional components to make navigation and retrieval easier.",
  button: {
    variant: "contained",
    color: "primary",
    label: "hello", // Dynamically change this to an empty string to test
    description: "Enhance your data organization with additional components.",
    icon:""
  },
  tooltip: {
    close: "Close"
  },
  styles: {
    container: {width:"100%"},
    buttonWrapper: {
      display: "flex",
      alignItems: "center",
      gap: 2, // Space between button and description
      mb: 0

    },
    button: {
      // minWidth: 40,
     //  minHeight: 40
    },
    componentHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      mb: 2
      
    },
    componentBox: {
      p: 1,
      pt: 3,
      border: "1px solid #ccc",
      borderRadius: "8px",
      position: "relative"
     
    
    },
    componentLabel: {
      position: "absolute",
      top: -15,
      left: 8,
      background: "#fff",
      px: 1
    }
  },
  labels: {
    componentHeader: "Components",
    componentLabel: "Add Component"
  }
}};

const buttonSx = (hasLabel) => ( {
  display: "flex",
  alignItems: "center",
  justifyContent: hasLabel ? "flex-start" : "center" // Align content dynamically
});
const collapsible = ({children,config}) => {
  config = config?config:getConfig();
 
  //const [showComponentField, setShowComponentField] = useState(config.visible?config.visible:false);
  const [showComponentField, setShowComponentField] = useState(false);
  const handleShowComponentField = () => setShowComponentField(true);

  const handleCloseComponentField = () => setShowComponentField(false);

  const hasLabel = Boolean(config.button.label);

  let buttonStyle = {...buttonSx(hasLabel),...config.styles.button};

  return (
    <Box sx={config.styles.container}>
      {/* Button and Description Row */}
      {!showComponentField && (
        <Box sx={config.styles.buttonWrapper}>
          <Tooltip title={config.instructions}>
            <Button
              variant={config.button.variant}
              color={config.button.color}
              onClick={handleShowComponentField}
              startIcon={hasLabel && (config.button.icon)} // Add startIcon only if there's a label
              sx={buttonStyle}
            >
              {config.button.label || (config.button.icon)} {/* Render icon if no label */}
            </Button>
          </Tooltip>
          {config.button.description && (
            <Typography variant="body2" color="textSecondary">
              {config.button.description}
            </Typography>
          )}
        </Box>
      )}

      {/* Component Field Section */}
      {showComponentField && (
         <>
          <Box sx={config.styles.componentHeader}>
            <Typography variant="h6">{config.labels.componentHeader}</Typography>
            <Tooltip title={config.tooltip.close}>
              <IconButton onClick={handleCloseComponentField} color="secondary">
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box sx={config.styles.componentBox}>
            <Typography variant="subtitle1" sx={config.styles.componentLabel}>
              {config.labels.componentLabel}
            </Typography>
      
            {children||  (
              <Typography variant="body2" color="textSecondary">
                No custom component provided.
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default collapsible;
