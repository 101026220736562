import React, { useState } from "react";
import { TextField, IconButton, InputAdornment, Tooltip } from "@mui/material";
import { Visibility, VisibilityOff, Clear } from "@mui/icons-material";
import { Field, useFormState, useForm } from "react-final-form";

// Configuration object for the field
const defaultConfig = {
  fieldName: "secureKey",
  label: "Secure Key",
  inputType: {
    text: "text",
    password: "password",
  },
  tooltip: {
    showSecureKey: "Show Secure Key",
    hideSecureKey: "Hide Secure Key",
    clearSecureKey: "Clear Secure Key",
  },
  style: {
    textField: { mt: 2 },
  },
  adornmentPosition: "end",
};

const defaultStyles = {
  backgroundColor: "#FFFFFF", // Input background
  borderRadius: "4px", // Input border radius
  pr: "10px", // Adjust padding
};

// Export the default config and styles
export const getConfig = () => {
  return { ...defaultConfig };
};
export const getStyles = () => {
  return { ...defaultStyles };
};

const CustomSecuredField = ({ config = getConfig(), styles = getStyles(), handleError }) => {
  const [showSecureKey, setShowSecureKey] = useState(false); // Local state for visibility toggle
  const { values } = useFormState();
  const form = useForm();

  // Toggle visibility of the secure key
  const handleToggleSecureKeyVisibility = () => {
    setShowSecureKey((prev) => !prev);
  };

  return (
    <Field name={config.fieldName} keepDirtyOnReinitialize={true}   subscription={{ value: true, pristine: true, error: true, touched: true }}>
      {({ input, meta }) => {
        if (handleError) {
          handleError(!!meta.error);
        }
        return (
          <TextField
            {...input}
            label={config.label}
            type={showSecureKey ? config.inputType.text : config.inputType.password}
            onChange={(e) => form.change(config.fieldName, e.target.value)}
            error={!!meta.error && meta.touched}
            helperText={meta.touched && meta.error}
            fullWidth
            sx={config.style.textField}
            InputProps={{
              style: { ...styles },
              endAdornment:
                values?.[config.fieldName] && (
                  <InputAdornment position={config.adornmentPosition}>
                    {/* Show/Hide Secure Key */}
                    <Tooltip title={showSecureKey ? config.tooltip.hideSecureKey : config.tooltip.showSecureKey}>
                      <IconButton onClick={handleToggleSecureKeyVisibility} edge={config.adornmentPosition}>
                        {showSecureKey ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </Tooltip>

                    {/* Clear Secure Key */}
                    <Tooltip title={config.tooltip.clearSecureKey}>
                      <IconButton onClick={() => form.change(config.fieldName, "")} edge={config.adornmentPosition}>
                        <Clear />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
            }}
          />
        );
      }}
    </Field>
  );
};

export default CustomSecuredField;
