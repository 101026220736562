import React from "react";
import FolderIcon from "@mui/icons-material/Folder";
import ImageIcon from "@mui/icons-material/Image";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DescriptionIcon from "@mui/icons-material/Description";
import Credentials from '@mui/icons-material/AssuredWorkload';
import Groups from '@mui/icons-material/Diversity2';
import Users from '@mui/icons-material/Person2';
import Credential from '@mui/icons-material/Password';
import User from '@mui/icons-material/Portrait';
import Accounts from '@mui/icons-material/AccountBalance';
import Account from '@mui/icons-material/AccountBalanceWallet';
import Group from '@mui/icons-material/SupervisedUserCircle';
import Contact from '@mui/icons-material/Contacts';
import Contacts from '@mui/icons-material/RecentActors';
export const IconSelector = (id, size, change) => {
  const iconStyle = { fontSize: `'${size}'` }; // Set the icon size to small
   
    switch (id) {
      case "credentials":
        return <Credential style={{ color: `${change? 'blue' :'#7faeff'}`,...iconStyle}} />;
      case "users":
        return <User  style={{ color: `${change ? 'green' :'#a0e5a0'}`,...iconStyle }}  />;
      case "groups":
        return <Group  style={{ color: `${change? 'grey' :'#d3d3d3'}`,...iconStyle }}  />;
      case "accounts":
        return <Account  style={{ color: `${change? 'orange' :'#ffcc80'}`,...iconStyle }}  />;
      case "contacts":
        return <Contact   style={{ color: `${change? 'red' :'#ffb3b3'}`,...iconStyle }}/>;
      default:
        return <FolderIcon />
    
  }
  return <FolderIcon />
  }

  // switch (props.fileType) {
  //   case "password":
  //     return <Credential style={{ color: "blue" }} />;
  //   case "user":
  //     return <User style={{ color: "green" }} />;
  //   case "contact":
  //       return <Contact style={{ color: "red" }} />;
  //   case "account":
  //     return <Account style={{ color: "orange" }} />;
  //   case "group":
  //     return <Group style={{ color: "grey" }} />;
  //   case "image":
  //     return <ImageIcon />;
  //   case "csv":
  //     return <ListAltIcon />;
  //   case "text":
  //     return <DescriptionIcon />;
  //   default:
  //     return null;
  // }
//};

