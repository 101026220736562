import React from 'react';
import {ApplicationContainer} from "@8d-xyz/ui-react_application_f-container"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Splash from "./components/pages/Splash";
import Plans from "./components/pages/Plans";
import About from "./components/pages/AboutUs";

// import Contact from "./components/common/form/ContactDialog";
 
  function NotFound() {
    return <h1>404 - Page Not Found</h1>;
  }
const App = () => {
    return (
        <Router>
          {/* <nav>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/about">About</Link></li>
              <li><Link to="/contact">Contact</Link></li>
            </ul>
          </nav> */}
          
          <Routes>
            {/* <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} /> */}
            <Route path="/about" element={<ApplicationContainer context={<About />} />} />
            <Route path="/plans" element={<ApplicationContainer context={<Plans/>} />} />
            {/* <Route path="/contact" element={<ApplicationContainer context={<Contact/>} />} /> */}
            {/* <Route path="/*" element={ <ApplicationContainer context={<Home/>}/>} /> */}
            <Route path="/*" element={ <ApplicationContainer context={<Splash/>}/>} />
          </Routes>
        </Router>
      );
   
};

export default App;
