import React from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import ReactQuill from "react-quill";
import { Box, Typography } from "@mui/material";
import { Form, Field, useFormState, useForm } from "react-final-form";
import {Util} from "../../../lib/util/Util";
const config = {
  fieldName: "note",
  quill: {
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"], // Formatting buttons
        [{ list: "ordered" }, { list: "bullet" }], // List buttons
        [{ header: [1, 2, 3, false] }], // Header dropdown
        ["link"],
       // ["link", "image"], // Link and image buttons
      ],
    },
    formats: [
      "header",
      "bold",
      "italic",
      "underline",
      "strike",
      "list",
      "bullet",
      "link",
      "image",
    ],
  },
  error: {
    variant: "body2",
    color: "error",
  },
};

const styles = {
  quillEditor: {
    height: "100%",
    marginBottom: "2px",
    border: "1px solid #ccc",
    borderRadius: "8px",
  },
  errorText: {
    mt: 1,
  },
};

export const getConfig = () => {
  return Util.JSON_COPY(config);
}
export const getStyles = () => {
  return Util.JSON_COPY(styles);
}
const WYSIWYGInputField = ({ config = getConfig(), styles = getStyles() }) => {
  const { values } = useFormState();
  const form = useForm();

  return (
    <Box sx={{ width: "100%", mb: 2, mr: 1 }}>
      {/* WYSIWYG Editor */}
      <Typography variant="h6">{config?.header?.title}</Typography>
      <Field name={config.fieldName}>
        {({ input, meta }) => (
          <>
            <ReactQuill
              value={input.value}
              onChange={input.onChange}
              onBlur={input.onBlur}
              modules={config.quill.modules}
              formats={config.quill.formats}
              style={styles.quillEditor}
            />

            {/* Error Display */}
            {meta.touched && meta.error && (
              <Typography
                variant={config.error.variant}
                color={config.error.color}
                sx={styles.errorText}
              >
                {meta.error}
              </Typography>
            )}
          </>
        )}
      </Field>
    </Box>
  );
};

export default WYSIWYGInputField;
