import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import { red, blue } from '@mui/material/colors';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Avatar,
  CardActions,
  IconButton,
  CardMedia,
  Typography,
  Box

} from "@mui/material";
import PasswordIcon from '@mui/icons-material/Password';
import {
  SyncLock as SyncLockIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  AddLink as AddAccessURL,
  SwitchAccount as AssociateToAccount,
  AvTimer as ExpirationInDays,
  Style as Tags,
  ContactEmergency as AdditionalInfo,
  EditNote as NoteIcon
} from "@mui/icons-material";

import ChallengeWithCheckbox from "../infolets/credential/ChallengeInput";
import CustomSecuredTextField, { getConfig, getStyles } from "../common/form/CustomSecuredTextField";
import CustomTextField, { getConfig as costomConfig, getStyles as customStyles } from "../common/form/CustomTextField";
import useFormData from "../../lib/util/useFormData";
import { Visibility, VisibilityOff, Clear } from "@mui/icons-material";
import CypherService from "../../lib/util/CypherService";
import { CryptoTransform } from "../../lib/util/ClientUtil"
import Collapsible from "../common/Collapsible";
import eventDispatcher from "../../events/dispatcher";
import { Form, Field, useFormState, useForm } from "react-final-form";
import dataModels from "../../config/CentralizedDataModel"
import CredentialSearchViewCard from "../infolets/credential/archived/CredentialSearchViewCard"
import { IconSelector } from "./EntityIconSelector"
import DataDisplay from "../common/DataDisplay";
import InfoDef from "../infolets/infoletDefs";
import {genEntityImageUrl} from "../../utils/common";
import LoadingDialog from "../../demo/platform/WaitDialog";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginRight: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: 'rotate(0deg)',
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: 'rotate(180deg)',
      },
    },
  ],
}));



const SarchResultCard = ({ handleError = { handleError }, entry = {}, entityRecord, entityCode}) => {
  const [expanded, setExpanded] = React.useState(false);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [urlImage, setUrlImage] = useState(null);
  let displayDef = InfoDef(entityCode.toUpperCase()).displayDef;
  useEffect(() => {
    const loadData = async () => {
      try {
        const result = await entityRecord();
        if(result.urlImage==="U"){
          setUrlImage(await genEntityImageUrl(entityCode.toUpperCase(),result.id));
        }
        setData(result);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    loadData();
  }, []);  // Use effect will run when the fetchData function changes

  if (loading) {
    return   <LoadingDialog open={loading} message="Loading please wait..." />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  function handleExpandClick() {
    setExpanded((prev) => !prev);
  }

  return (

    <Card sx={
      {
        maxWidth: 600,
        pt: 0,
        marginTop: "12px",
        marginBottom: "25px",
        backgroundColor: "white",
        boxShadow: "0 2px 10px rgba(6, 81, 237, 0.3)",
        borderRadius: "0.375rem",
        color: "#333",
  
      }
    } >
      <CardHeader  sx={{ 'padding-left': '25px', 'padding-right': '25px' }}
        avatar={
          <Avatar aria-label="{entry.type}">
            {IconSelector(entityCode, "18px", true)}
          </Avatar>
        }

        title={!!!data.url ? entry.name : (<><a
          href={data.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
      {entry.name}
        </a>
     
        </>
      
      
      )}



      />
      {!!!data.url && !!data.urlImage && (
        <CardMedia sx={{ 'padding-left': "60px", 'padding-right': "60px", }}
          component="img"
          height="100px"
          image={urlImage}
        />)
      }

      {!!data.url && !!data.urlImage && (

        <a
          href={data.url}
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: 'blue', textDecoration: 'underline' }}
        >
          <CardMedia sx={{ 'padding-left': "60px", 'padding-right': "60px", paddingTop:0 }}
            component="img"
            height="100px"
            image={urlImage}

          />
        </a>
      )
      }
      <Box
        display="flex"
        justifyContent="flex-end"
        sx={{ margin: "0px", padding:0}}>
        <Typography  sx={{ margin: "0px", padding:0}}>
          <ExpandMore
            expand={expanded}
            onClick={() => { handleExpandClick() }}
            aria-expanded={expanded}
            aria-label="see detail"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </Typography>
      </Box>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <DataDisplay data={data} 
            displayDef={displayDef}
          />

        </CardContent>
      </Collapse>
    </Card>



  );
};

export default SarchResultCard;
