import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Button } from "@material-tailwind/react";
import SearchBar from "../search/SearchBar";
import { PositionedElements } from "@8d-xyz/ui-react_positioned_element-component";
import { LoginDialog } from "@8d-xyz/ui-react_account_access-widget";
import { useUserSession } from '../../lib/session/UserSession';
import { UserAccount } from '@8d-xyz/ui-react_account_management-widget';
import LoadingDialog from "../../demo/platform/WaitDialog";
import eventDispatcher from "../../events/dispatcher";
import logo from "../../assets/logo_200.png";
import terms from "../../config/Terms";
import privacy from "../../config/Privacy";

const NavWidget = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const navigate = useNavigate();
  const { user, login } = useUserSession();
 

  const configx = {
    components: [
      {
        useTailwind: false,
        componentProps: {
          imageSrc: logo,
          labelText: `${process.env.ENV_LABEL}`,
          labelPosition: "bottom",
          size: "90px",
          containerStyle: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
          tooltip: "Welcome to Edax",
          tooltipPlacement: "bottom",
          imageStyle: {
            width: "150px",
            height: "40px",
          },
          labelStyle: {
            color: "Black",
            fontSize: "16px",
            marginTop: "8px",
          },
          onClick: "handleHomeClick",
        },
      },
    ],
    callbacks: {
      handleHomeClick: () => navigate("/Splash"),
    },
  };

  return (
    <div className="w-full flex flex-col" >
      {/* Navbar Container */}
      <Navbar className="sticky top-0 z-10 h-max max-w-full rounded-none border-0 px-4 py-2 lg:px-8 lg:py-3 bg-red-30" >
        <div className="flex flex-wrap items-center justify-between w-full gap-2">
          
          {/* Left - PositionedElements (Logo) */}
          <div className="min-w-max flex items-center">
            <PositionedElements config={configx} />
          </div>

          {/* Middle - SearchBar (Expands to take available space) */}
          <div className="flex-grow flex items-center px-4">
            {user && <SearchBar className="w-full max-w-3xl" />}
          </div>

          {/* Right - UserAccount & Buttons (Responsive) */}
          <div className="flex items-center gap-2 min-w-max flex-wrap justify-end">
            {user ? (
              <UserAccount user={user} />
            ) : (
              <>

                <Button
                  variant="outlined"
                  size="sm"
                  onClick={() => setShowLoginDialog(true)}
                >
                  Log In
                </Button>
                <Button
                  variant="gradient"
                  size="sm"
                  disabled={window.location.href.endsWith('/plans')||window.location.href.endsWith('/plans#')}
                  onClick={() => {
                    navigate("/plans")}                 
                  }
                >
                  Plans
                </Button>
              </>
            )}
          </div>

        </div>
      </Navbar>

      {/* Login Dialog */}
      {showLoginDialog && (
        <LoginDialog
          open={showLoginDialog}
          onClose={() => setShowLoginDialog(false)}
          onSubmit={async (values) => {
            eventDispatcher.LOGIN(values, login, setIsLoading);
          }}
          onSignup={async (values) => {
            eventDispatcher.SIGNUP(values, login, setIsLoading);
          }}
          onRecover={async (values) => {
            eventDispatcher.PASSWORD_RESET(values, login, setIsLoading);
          }}
          literals={{
            title: "Login or Create Account",
            customTitleLogin: "Welcome Back",
            customTitleSignup: "Create Your Account",
            customTitleRecover: "Reset Your Password",
            recoverInstruction:
              "We will send you an email with instructions to reset your password. Check your spam folder if you don't receive it.",
            accountname: "Account Name",
            password: "Password",
            email: "Email",
            confirmPassword: "Confirm Password",
            rememberMe: "Remember Me",
            login: "Sign In",
            promptLogin: "Already Have An Account?",
            returnToLogin: "Return To Login",
            signup: "Create Account",
            promptSignUp: "Create New Account",
            recover: "Forgot Password?",
            cancel: "Cancel",
            required: "This field is required",
            passwordMatch: "Passwords must match",
            termsOfService: "Terms of Service",
            privacyPolicy: "Privacy Policy",
            termsOfServiceContent: terms,
            termsAndPolicyLabel:"Upon signing up/in, you agree to abide by our ",
            termsOfPolicyContent: privacy,
          }}
          defaultForm="login"
          headerLogo={logo}
        />
      )}

      {/* Loading Dialog */}
      <LoadingDialog open={isLoading} message="Loading your data, please wait..." />
    </div>
  );
};

export default NavWidget;
