import React, { useEffect, useState, useRef } from "react";
import { Field, useForm } from "react-final-form";
import {
  CardMedia,
  Typography,
  IconButton,
  Box,
  Dialog,
  Button
} from "@mui/material";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import AddIcon from "@mui/icons-material/Add";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';

import Collapsible, { getConfig } from "../Collapsible";
import useCameraCapture, { isMobileDevice } from "../../../lib/util/useCameraCapture";

const URLImageCaptureForm = ({ config, urlImage, styles, values = {} }) => {
  const form = useForm();
  const [screenCapture, setScreenCapture] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const fileInputRef = useRef();

  const {
    videoRef,
    photo,
    startCapture,
    stopStream,
    takePhoto,
    toggleCamera,
    triggerPlayback,
    showStartButton
  } = useCameraCapture();

  useEffect(() => {
    if (urlImage) setScreenCapture(urlImage);
  }, [urlImage]);

  useEffect(() => {
    if (photo) {
      setScreenCapture(photo);
      form.change(config.fieldName, photo);
    }
  }, [photo, form, config.fieldName]);

  const handleOpenCapture = async () => {
    const success = await startCapture();
    if (success && isMobileDevice()) {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    stopStream();
    setOpenDialog(false);
  };

  const clearScreenCapture = () => {
    setScreenCapture(null);
    form.change(config.fieldName, null);
  };

  const restDefaultImage = () => {
    if (urlImage) {
      setScreenCapture(urlImage);
    } else {
      clearScreenCapture();
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageData = e.target.result;
        setScreenCapture(imageData);
        form.change(config.fieldName, imageData);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (event) => event.preventDefault();

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageData = e.target.result;
        setScreenCapture(imageData);
        form.change(config.fieldName, imageData);
      };
      reader.readAsDataURL(file);
    }
  };

  const openFileDialog = () => {
    fileInputRef.current?.click();
  };

  const collapsibleConfig = {
    ...getConfig(),
    visible: true,
    instructions: "Drag and drop an image here, or click the plus icon to open camera or screen capture.",
    button: {
      label: "",
      description: "Add an image.",
      icon: <AddAPhotoIcon />
    },
    labels: {
      componentHeader: "Image",
      componentLabel: "Load Image"
    }
  };

  return (
    <Collapsible config={collapsibleConfig}>
      {collapsibleConfig.visible && !screenCapture && (
        <Typography variant="body2" color="text.secondary" sx={{ pl: 1, pb: 2 }}>
          Drag and drop an image here, or click the plus icon to open
          the camera (on mobile) or screen capture (on desktop).
        </Typography>
      )}

      <Field
        name={config.fieldName}
        keepDirtyOnReinitialize
        subscription={{ value: true, pristine: true, error: true, touched: true }}
      >
        {({ meta }) => (
          <>
            <Box
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              sx={{
                ...styles.box,
                ...(screenCapture ? styles.boxWithImage : {})
              }}
              onClick={
                !screenCapture
                  ? () => {
                      if (isMobileDevice()) {
                        openFileDialog(); // Mobile: open file input
                      } else {
                        handleOpenCapture(); // Desktop: screen capture
                      }
                    }
                  : undefined
              }
            >
              {screenCapture ? (
                <CardMedia
                  component="img"
                  image={screenCapture}
                  alt="Captured Image"
                  sx={styles.cardMedia}
                  onClick={() => {
                    if(isMobileDevice()){
                      openFileDialog();
                    }else{
                      handleOpenCapture();
                    }
                    
                  
                  }}
                />
              ) : (
                <>
                  {isMobileDevice() ? (
                    <label htmlFor="mobile-upload" style={{ cursor: "pointer", display: "flex" }}>
                      <AddIcon sx={styles.addIcon} />
                    </label>
                  ) : (
                    <AddIcon sx={styles.addIcon} />
                  )}
                </>
              )}

              {(screenCapture || urlImage) && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 10,
                    right: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    gap: 1
                  }}
                >
                  {!isMobileDevice() && (
                    <IconButton onClick={handleOpenCapture} sx={styles.iconButton}>
                      <PhotoCameraFrontIcon />
                    </IconButton>
                  )}

                  <IconButton onClick={openFileDialog} sx={styles.iconButton}>
                    <UploadFileIcon />
                  </IconButton>
                  <input
                    type="file"
                    accept="image/*"
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    style={{ display: "none" }}
                  />

                  {urlImage && (
                    <IconButton onClick={restDefaultImage} sx={styles.iconButton}>
                      <RestartAltIcon />
                    </IconButton>
                  )}

                  <IconButton onClick={clearScreenCapture} sx={styles.iconButton}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </Box>

            {/* Hidden input for mobile file selection */}
            {isMobileDevice() && (
              <input
                id="mobile-upload"
                type="file"
                accept="image/*"
                onChange={handleFileSelect}
                style={{ display: "none" }}
              />
            )}

            {meta.touched && meta.error && (
              <Typography color="error" sx={styles.errorText}>
                {meta.error}
              </Typography>
            )}
          </>
        )}
      </Field>

      {/* Mobile camera dialog */}
      {isMobileDevice() && (
        <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="lg">
          <Box sx={{ position: "relative", padding: 2 }}>
            <IconButton
              onClick={handleCloseDialog}
              sx={{
                position: "absolute",
                top: 10,
                right: 10,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "rgba(0, 0, 0, 0.8)"
                }
              }}
            >
              <CloseIcon />
            </IconButton>

            <video
              ref={videoRef}
              autoPlay
              playsInline
              style={{
                width: "100%",
                height: "auto",
                maxHeight: "80vh",
                borderRadius: "8px"
              }}
            />

            {showStartButton ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button variant="contained" color="primary" onClick={triggerPlayback}>
                  Start Camera
                </Button>
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                <Button onClick={takePhoto} variant="contained" color="primary">
                  Take Photo
                </Button>
                <IconButton onClick={toggleCamera} color="secondary">
                  <FlipCameraAndroidIcon />
                </IconButton>
              </Box>
            )}
          </Box>
        </Dialog>
      )}
    </Collapsible>
  );
};

export default URLImageCaptureForm;
