import dataModels from "../../config/CentralizedDataModel"
import { useUserSession } from '../../lib/session/UserSession';
import {
  SyncLock as SyncLockIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  AddLink as AddAccessURL,
  SwitchAccount as AssociateToAccount,
  AvTimer as ExpirationInDays,
  Style as Tags,
  ContactEmergency as AdditionalInfo,
  EditNote as NoteIcon,
  DocumentScanner as SecureAccount,
  Pin as PIN,
  DataObject as AccountBackup,
  AddLocationAlt as AddLocationAltIcon,
  PsychologyAlt as PsychologyAltIcon,
  AttachEmail as AttachEmailIcon,
  AddIcCall as AddIcCallIcon
} from "@mui/icons-material";

import Instruction from '../common/form/Instruction';
import NameForm, { getConfig as getNameFormConfig, getStyles as getNameFormStyles } from "../common/form/CustomTextField";
import UrlForm, { getConfig as getUrlFormConfig, getStyles as getUrlFormStyles } from "../common/form/CustomTextField";
import TagsForm from "../common/form/TagsWiz";
import AddNoteForm from "../common/form/AddNoteForm";
import CustomSecuredTextField, { getConfig as getCustomSecuredTextFieldConfig, getStyles as getCustomSecuredTextFieldStyles } from "../common/form/CustomSecuredTextField";
import KeyValueEntriesWiz, { getConfig as getKeyValueConfig, getStyles as getKeyValueStyles } from "../common/form/KeyValueEntriesWiz";
import AddressWiz, { getConfig as getAddressWizConfig, getStyles as getAddressWizStyles } from "../common/form/AddressWiz";
import CustomTextAreaField from "../common/form/CustomTextAreaField";


export const getStyles = () => {
  return {
    card: {
      maxWidth: 600,
      pt: 0,
      margin: "30px auto",
      backgroundColor: "white",
      boxShadow: "0 2px 10px rgba(6, 81, 237, 0.3)",
      borderRadius: "0.375rem",
      color: "#333",

    },
    box: {
      position: "relative",
      height: 300,
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      border: "2px dashed #ccc",
    },
    boxWithImage: {
      border: "none",
    },
    cardMedia: {
      height: "300px",
      width: "auto",
      p: "20px",
      objectFit: "contain",
    },
    addIcon: {
      fontSize: 80,
      color: "#ccc",
    },
    iconButton: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "#fff",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      },
    },
    errorText: {
      textAlign: "center",
      mt: 1,
    },
    description: {
      mt: 2,
    },
    submitButton: {
      margin: 2,
    },
  };
}

const handleError = (hasError) => {
  //console.log(hasError);
};

const InfoletDef = () => {
  const entityType="ACCOUNTS";
  const dataModelName = 'accountWidget';
  const userSession = useUserSession();
  const { tagEntities, setTagEntities } = userSession;
  let definition = {
    type: entityType,
    session: userSession,
    form: {
      modelDefintion: dataModels,
      widget: {
        styles: getStyles
      },
      dataModelName: dataModelName,
      formImageFieldName: "urlImage",
      formFields: [
        {
          toolTip: "Required - Account name for this account",
          icon: <BadgeOutlinedIcon />,
          form: <NameForm config={{
            fieldName: "name",
            label: "Enter Account Name",
            fullWidth: true,
            margin: "normal",
            variant: "outlined",
            adornmentPosition: "end",
            iconSize: "small",
            clearTip: "Clear Field"
          }} styles={getNameFormStyles()} handleError={handleError} />,
          howto: <Instruction config={{
            "text": "Give this account a name that's easy to remember, so you can quickly identify it."
          }} />
        },
        {
          toolTip: "Optional - Enter the URL associated to this account.",
          icon: <AddAccessURL />,
          form: <UrlForm config={
            {
              ...getUrlFormConfig(),
              fieldName: "url",
              label: "Account Web Address"
            }
          }
            styles={getUrlFormStyles()} handleError={handleError} />,
          howto: <Instruction config={{
            "text": "Does this account have a website associated with it? Enter the website URL like http://... .  Then you can access it whenever you need.",
          }} />
        },
        {
          toolTip: "Optional - Entering the account number for this acount",
          icon: <SecureAccount />,
          form: <CustomSecuredTextField config={{
            ...{
              fieldName: "accountNumber",
              label: "Enter Account Number",
              inputType: {
                text: "text",
                password: "password",
              },
              tooltip: {
                showSecureKey: "Show Account Number",
                hideSecureKey: "Hide Account Number",
                clearSecureKey: "Clear Account Number",
              },
              style: {
                textField: { mt: 2 },
              },
              adornmentPosition: "end",
            }
          }} styles={getCustomSecuredTextFieldStyles()} />,
          howto: <Instruction config={{
            "text": "If there is an account number, please enter the one associated with this account.",
          }} />
        },

        {
          toolTip: "Optional - Entering the PIN number for this account",
          icon: <PIN />,
          form: <CustomSecuredTextField
            config={{
              fieldName: "pin",
              label: "Enter PIN Number",
              inputType: {
                text: "text",
                password: "password",
              },
              tooltip: {
                showSecureKey: "Show PIN  Number",
                hideSecureKey: "Hide PIN Number",
                clearSecureKey: "Clear PIN Number",
              },
              style: {
                textField: { mt: 2 },
              },
              adornmentPosition: "end",
            }} styles={getCustomSecuredTextFieldStyles()} />,
          howto: <Instruction config={{
            "text": "Please enter the PIN number associated with this account if there is one.",
          }} />
        },

        {
          toolTip: "Optional - Enter account backup code associated to this account",
          icon: <AccountBackup />,
          form: <CustomTextAreaField config={{
            fieldName: "accountBackupCodes",
            labels: {
              textarea: "",
            },
            placeholders: {
              textarea: "Account Backup Codes",
            }
          }} styles={{
            container: {
              display: "flex",
              flexDirection: "column",
              gap: 0,
              padding: "0px",
              margin: "0 auto",
            },
            textarea: {
              marginTop: "12px",
              padding: "10px",
              width: "100%",
              fontSize: "16px",
              resize: "vertical",
              border: "1px solid grey",
            },
            textareaError: {
              border: "2px solid red",
            },
            errorText: {
              mt: 0,
            }
          }} />,
          howto: <Instruction config={{
            "text": "If you have backup codes, please copy them here for safe keeping.",
          }} />
        },

        {
          toolTip: "Optional, to help you keep track of other essential info. Tag it!",
          icon: <Tags />,
          form: <TagsForm handleError={handleError} tags={Object.values(tagEntities ? tagEntities : []).map((entry) => {
            return entry.TAG;
          })} />,
          howto: <Instruction config={{
            "text": "Choose relevant and easy-to-remember tags to keep everything organized. To add a tag manually, type your tag in the field, then click the + button or press Enter. Alternatively, you can browse the available tags list and click on any tag to apply it to your account.",
          }} />

        },

        {
          toolTip: "Optional, enter any addresses associated to this account i.e. office mailing, resident etc.",
          icon: <AddLocationAltIcon />,
          form: <AddressWiz
            //default field name is addresses
            styles={getAddressWizStyles()}
          />,
          howto: <Instruction config={{
            "text": "Enter the addresses associated with this account.",
          }} />

        },

        {
          toolTip: "Optional, to help you keep track of other essential info.",
          icon: <AdditionalInfo />,
          form: <KeyValueEntriesWiz config={{
            header: {
              title: "Addition Informaiton",
              addTooltip: "Additional Account Info. Entry",
              removeAllTooltip: "Remove All Account Info. Entries",
              removeTooltip: "Remove Account Info Entry",
              emptyMessage: "Click the '+' button to add account information entry.",
            },
            entriesConfig: { name: "Name", value: "Value" },
            field: {
              name: "accountNoteEntries",
            },
            entryLabelPrefix: "Entry #",
          }} styles={getKeyValueStyles()} />,
          howto: <Instruction config={{
            "text": "If you have any key/value pairs, like a routing number for a bank account, you can add them here.",
          }} />

        },
        {
          toolTip: "Optional, to help you keep track of other essential info.",
          icon: <NoteIcon />,
          form: <AddNoteForm handleError={handleError} useTab={true} />,
          howto: <Instruction config={{
            "text": "Enter any other notes you'd like to remember.",
          }} />
        }
      ]
    },
    displayDef: {
      "name": {
        "label": "Account Name:",
        "ignore": false,
        "order": 1
      },
      "url": {
        "label": "Account Website URL:",
        "ignore": false,
        "order": 2
      },
      "accountNumber": {
        "label": "Account Number:",
        "ignore": false,
        "order": 3
      },
      "pin": {
        "label": "Account PIN:",
        "ignore": false,
        "order": 4
      },
      "accountBackupCodes": {
        "label": "Account Backup Codes:",
        "ignore": false,
        "order": 5
      },
      "addresses": {
        "label": "Account Related Addresses:",
        "ignore": false,
        "order": 6,
        "_type": "array",
        "child": {
          "_type": "map",
          "name": {
            "label": "Name:",
            "ignore": false,
            "order": 1
          },
          "addressType": {
            "label": "Address Type:",
            "ignore": false,
            "order": 2
          },
          "streetAddress": {
            "label": "Street Address:",
            "ignore": false,
            "order": 3
          },
          "city": {
            "label": "City:",
            "ignore": false,
            "order": 4
          },
          "state": {
            "label": "State:",
            "ignore": false,
            "order": 5
          },
          "zipCode": {
            "label": "Zip Code:",
            "ignore": false,
            "order": 6
          }
        }
      },
      "tags": {
        "label": "Tags:",
        "ignore": false,
        "order": 7,
        "_type": "array",
        "child": {
          "_type": "chip",
        }

      },
      "accountNoteEntries": {
        "label": "Account Key/Value Pair Information:",
        "ignore": false,
        "order": 8,
        "_type": "map",
        "child": {
          "_type": "map",
          "name": {
            "label": "",
            "ignore": false,
            "order": 1
          },
          "value": {
            "label": "",
            "ignore": false,
            "order": 2
          }

        }
      },
      "note": {
        "label": "Note:",
        "ignore": false,
        "order": 9,
        "_type": "string",
        
      },
      "id": {
        "label": "Unique Id:",
        "ignore": true,
        "order": 10,
        "_type": "sting",
        "child": {

        }
      },
      "urlImage": {
        "label": "Image Url:",
        "ignore": true,
        "order": 11,
        "_type": "sting",
        "child": {

        }
      }
   
    }

  };

  return definition;
};

export default InfoletDef;
