import React, { useState } from "react";
import {
  Box,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { Form, Field, useFormState, useForm } from "react-final-form";;
import { Visibility, VisibilityOff, Clear } from "@mui/icons-material";
import CustomSecuredField,{getConfig, getStyles} from "../../common/form/CustomSecuredTextField";
import CustomTextField,{getConfig as challengePromptConfig, getStyles as challengePromptStyle} from "../../common/form/CustomTextField";

const ChallengeWithCheckbox = ({ }) => {
  const { values } = useFormState();
  const form = useForm();
 
  if(!values?.useChallenge){
   // form.change('useChallenge', false);
    form.change('challenge',null);
  }
  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      {/* Checkbox to Toggle Challenge Field */}
    
      <Field
        name="useChallenge"
        type="checkbox"
        render={({ input }) => (
          <FormControlLabel
            control={
              <Checkbox
                {...input}
                checked={!!values?.useChallenge} // Ensure it works with boolean values
              />
            }
            label="Enable Credential Challenge"
          />
        )}
      />

      {/* Challenge Input Field */}
      {values?.useChallenge && (
       <>
        <Field
          keepDirtyOnReinitialize={true}   
          subscription={{ value: true, pristine: true, error: true, touched: true }}
          name="challengePrompt"
          render={({ input, meta}) => (
            <CustomTextField
            config={
            (()=>{
            let config = challengePromptConfig(); 
            config.fieldName="challengePrompt";
            config.label="Challenge Prompt";
            return config;
          })()
        }
      />
          )}
        />

        <Field
          keepDirtyOnReinitialize={true}   
          subscription={{ value: true, pristine: true, error: true, touched: true }}
          name="challenge"
          render={({ input, meta}) => (
            <CustomSecuredField
            
            config={
            (()=>{
            let config = getConfig(); 
            config.fieldName="challenge";
            config.label="Challenge Response";
            config.tooltip.showSecureKey="Show Challenge";
            config.tooltip.hideSecureKey="Hide Challenge";
            config.tooltip.clearSecureKey="Clear Challenge";
            return config;
          })()
        }
      />
          )}
        />
        </> )}

    </Box>
  );
};
export default ChallengeWithCheckbox;
