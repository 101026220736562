import React, { useState, useMemo, useEffect, useRef, useCallback } from "react";
import { Lock, AccountCircle, Person } from "@mui/icons-material";
import { Formik, Form, setIn } from 'formik';
import debounce from "lodash/debounce";
import * as Yup from 'yup';
import DialogWrapper from "../common/DialogWrapper";
import eventDispatcher from "../../events/dispatcher";
import SearchResultCard from "./SearchResultCard";
import CredentialAddCard, { getStyles as getCredentialAddCardStyles } from "../infolets/credential/archived/CredentialAddCard";
import { useUserSession } from '../../lib/session/UserSession';
import {
  Box,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Chip,
  Typography,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
  Button,
  Grid2 as Grid,
  OutlinedInput,
  ClickAwayListener,
  Autocomplete,

} from '@mui/material';
import Password from '@mui/icons-material/Password';
import User from '@mui/icons-material/Portrait';
import Account from '@mui/icons-material/AccountBalanceWallet';
import Group from '@mui/icons-material/SupervisedUserCircle';
import Contact from '@mui/icons-material/Contacts';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FilterList as FilterListIcon, Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material';
import { transformSearchTags } from "../../utils/common";
import { ApplicationContainer, AppDataFetcher, AppUtils, ApplicationContextStoreProvider, ClientMediator } from '@8d-xyz/ui-react_application_f-container';


const searchConfig = {
  globalDisableFilters: true,
  // defaultSearchContext: "All",
  handler: () => {

  },
  searchContexts: [
    {
      name: "Credentials",
      disableFilters: true,

    },
    {
      name: "Accounts",
      disableFilters: true,

    },

    {
      name: "Users",
      disableFilters: true,

    },
    {
      name: "Groups",
      disableFilters: true,

    }
  ]
};

function SearchBar({ config = searchConfig, tags }) {
  const { user, logout, login, sessionData, tagEntities, setTagEntities } = useUserSession();
  const [typeFilter, setTypeFilter] = useState("all"); // Type filter state
  const [inputValue, setInputValue] = useState(""); // Search input state
  const [isOpen, setIsOpen] = useState(false); // Dropdown open state
  // const [flatData, setFlatData] = useState([]);
  // const [filterSearch, setFilterSearch] = useState({});
  // const [showSearchInput, setShowSearchInput] = useState({});
  // const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  // const [hover, setHover] = useState(false);
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
 
  const [searchTags, setSearchTags] = useState({});
  const searchIconRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAnyFilterSelected, setIsAnyFilterSelected] = useState(false)
  const [showSearchResultCard, setShowSearchResultCard] = useState(false);
  
  const [selectedOption, setSelectedOption] = useState();

  const [selectedContext, setSelectedContext] = useState(
    config.defaultSearchContext
      ? config.searchContexts.find(ctx => ctx.name === config.defaultSearchContext) || {
        name: "Credentials",
        disableFilters: true,
        filters: {
          type: {
            sex: ['system', 'account', 'personal'],
          }
        }
      }
      : {
        name: "all",
        disableFilters: true
      }
  );

  ClientMediator.listenForEvent(`SW_S-APP-ENTITY_CHANGED`, async (data) => {

    let entities = await eventDispatcher.GET_ORGANIZER_BY_TYPE("tags", {}, () => { });
    tags = entities;
    //update this for other to know
    setTagEntities(prev => entities);

  });

  
  // Flatten the nested JSON structure into an array
  let flatData = useMemo(() => {
    let sTags = transformSearchTags(tags)
    setSearchTags(sTags);
    return Object.entries(sTags).flatMap(([tag, items]) =>
      Object.entries(items).map(([id, item]) => ({
        id: id,
        tag, // Include the tag for searching
        ...item,
      }))
    );
  }, [tags]);

  // Filter the data based on the type and search input
  const filteredOptions = useMemo(() => {
    if (flatData.length == 0) {
      return [];
    }
    if (inputValue.trim() === "") {
      return []; // Return an empty array when the input is empty
    }
    const input = inputValue.toLowerCase();
    const isExactMatch = input.length < 1; // Exact match for input < 4 characters
    //console.log(flatData)
    if (flatData.length <= 0) return [];
    return flatData.filter((item) => {
      const matchesName =
        isExactMatch
          ? item.name.toLowerCase() === input // Exact match for name
          : item.name.toLowerCase().includes(input); // Partial match for name

      const matchesTag =
        isExactMatch
          ? item.tag.toLowerCase() === input // Exact match for tag
          : (item.tag !== "No Tag" && item.tag.toLowerCase().includes(input)); // Partial match for tag
      const matchesType = typeFilter.toLowerCase() === "all" || item.type === typeFilter;
      return (matchesName || matchesTag) && matchesType; // Match either name or tag and ensure type matches
    });
  }, [flatData, inputValue, typeFilter, tags, selectedContext]);

  const initialValues = { search: inputValue };
  const validationSchema = selectedContext
    ? Yup.object().shape({
      dateRange: Yup.object({
        yearRange: Yup.object({
          start: Yup.date().nullable(),
          end: Yup.date()
            .nullable()
            .min(Yup.ref('start'), 'End date must be greater than or equal to the start date'),
        }),
      }),
    })
    : Yup.object();


  const getLabel = () => (selectedContext && selectedContext.name.toUpperCase() !== "ALL" ? `Search - ${selectedContext.name}` : 'Search');

  const handleFilterDialogOpen = (event) => {
    if (!config.globalDisableFilters && selectedContext && !selectedContext.disableFilters) {
      setAnchorEl(event.currentTarget); // Set the IconButton as the anchor
      setIsFilterDialogOpen(true);
    }
  };

  const handleFilterDialogClose = () => {
    setIsFilterDialogOpen(false);
    setAnchorEl(null); // Close the popover
  }

  const handleContextMenuToggle = () => setIsContextMenuOpen(!isContextMenuOpen);

  const handleContextSelection = (context) => {

    setSelectedContext(context);
    setIsContextMenuOpen(false);
    if (getTypeFilter(context.name) !== typeFilter) {
      setTypeFilter(getTypeFilter(context.name));
    }
    if (context.name.toUpperCase() !== "ALL") {
      setIsAnyFilterSelected(true);
    } else {
      setIsAnyFilterSelected(false);
    }
  };
  // const handleSearchChange = (category, optionKey, value) => {

  //   setFilterSearch((prev) => ({
  //     ...prev,
  //     [category]: { ...prev[category], [optionKey]: value },
  //   }));
  // };

  // const toggleSearchInput = (category, optionKey) => {
  //   const hasEntries = filterSearch[category]?.[optionKey]?.length > 0;
  //   setShowSearchInput((prev) => ({
  //     ...prev,
  //     [category]: {
  //       ...prev[category],
  //       [optionKey]: hasEntries || !prev[category]?.[optionKey],
  //     },
  //   }));
  // };

  // const clearSearchInput = (category, optionKey) => {
  //   setFilterSearch((prev) => ({
  //     ...prev,
  //     [category]: { ...prev[category], [optionKey]: '' },
  //   }));
  //   setShowSearchInput((prev) => ({
  //     ...prev,
  //     [category]: { ...prev[category], [optionKey]: false },
  //   }));
  // };

  let isFilterDisabled = config.globalDisableFilters || !selectedContext || selectedContext?.disableFilters;

  function getTypeFilter(type) {
    //console.log("calling typeFilter: " + type);
    switch (type) {
      case "Credentials": return "password";
      case "Accounts": return "account";
      case "Groups": return "group";
      case "Users": return "user";
      case "Contacts": return "contact";
      default: return "all";
    }
  }
  const getTypeIcon = (type) => {

    switch (type) {
      case "password":
        return <Password sx={{ color: 'blue' }} />;
      case "account":
        return <Account sx={{ color: 'orange' }} />;
      case "user":
        return <User sx={{ color: 'green' }} />;
      case "group":
        return <Group sx={{ color: 'grey' }} />;
      case "contact":
        return <Contact sx={{ color: 'red' }} />;
      default:
        return null;
    }
  };

  const entityTypeCode = {
    password: "CREDENTIALS",
    account: "ACCOUNTS",
    group: "GROUPS",
    user: "USERS",
    contact: "CONTACTS",
    all: "all"
  }

  return (

    <>

      <Formik
        initialValues={initialValues}
      //  validationSchema={validationSchema}
      // validateOnChange={false}
      // validateOnBlur={false}
      //  onSubmit={(values) => {
      //    //console.log('Filtered Values:', values); // Log the filter selections
      //    handleFilterDialogClose();
      //   }}
      >

        {({ values, setFieldValue, resetForm, errors, touched, validateForm }) => {
          // const isAnyFilterSelected = Object.entries(values).some(([category, options]) =>
          //   Object.entries(options).some(([key, value]) =>
          //     Array.isArray(value) ? value.length > 0 : value.start || value.end
          //   )
          // );
          //    setIsAnyFilterSelected(selectedContext.name.toUpperCase()!=="ALL");
          const handleResetFilters = () => {

            setSelectedContext({
              name: "all",
              disableFilters: true
            });

            setTypeFilter(prv => "all");
            setIsAnyFilterSelected(false);

            // if(context.name.toUpperCase()!=="ALL"){
            //   setIsAnyFilterSelected(false);
            // }else{
            //   setIsAnyFilterSelected(true);
            // }




            // setSelectedContext({
            //   name: "all",
            //   disableFilters: true
            // });
            // setIsAnyFilterSelected(false);
            // setTagEntities(prev=>(data))

          };

          return (
            <>
              {showSearchResultCard &&
                <DialogWrapper
                  mode="dialog"
                  open={showSearchResultCard}
                  onClose={() => { setShowSearchResultCard(false) }}
                  title={selectedOption.type.charAt(0).toUpperCase() + selectedOption.type.slice(1)}
                >
                  <SearchResultCard
                    entityCode={entityTypeCode[selectedOption.type].toLowerCase()}
                    entry={selectedOption}
                    entityRecord={async () => await eventDispatcher.GET_ENTITY_RECORD(
                      entityTypeCode[selectedOption.type].toLowerCase(),
                      selectedOption.id,
                      "all",
                      {},
                      () => { }
                    )}
                    handleError={() => { }}
                    onClose={() => { setShowSearchResultCard(false) }}

                  />

                </DialogWrapper>
              }

              <Form>
                <Autocomplete
                  freeSolo={false} // Disable freeSolo to strictly adhere to options
                  open={isOpen && inputValue.trim() !== ""} // Control dropdown visibility
                  groupBy={(option) => option.tag}
                  onOpen={() => setIsOpen(true)} // Open dropdown
                  onClose={() => setIsOpen(false)} // Close dropdown
                  options={filteredOptions.length > 0 ? filteredOptions : []} // Explicitly pass an empty array when no matches
                  getOptionLabel={(option) => (option.name ? `${option.name} (${option.tag})` : "")} // Label for input
                  noOptionsText="No matches found" // Message when no matches
                  renderOption={(props, option) => (
                    <Box
                      {...props}
                      key={`${option.id}-${option.tag}`}
                      onClick={(event) => {
                        setSelectedOption(option)
                        setShowSearchResultCard(true);

                        event.stopPropagation(); // Prevent default dropdown behavior
                        // alert(`You selected: ${option.name} (${option.tag})`);
                        //console.log(option.name);
                        setInputValue("");
                        // setIsOpen(false); // Close the dropdown
                        //setIsOpen(true); 
                      }}

                      sx={{
                        padding: "2px",
                        margin: "4px",
                        display: "flex",
                        alignItems: "center",
                        p: 1,
                        cursor: "pointer",
                        ":hover": { bgcolor: "action.hover" },
                      }}
                    >
                      {option.type && getTypeIcon(option.type)}
                      <Typography sx={{ marginLeft: 2 }}>{option.name}</Typography>
                      <Typography
                        sx={{
                          marginLeft: 2,
                          fontSize: "0.8rem",
                          color: "text.secondary",
                          ml: "auto"

                        }}
                      >
                        <Chip label={option.tag} variant="outlined" size="small" />
                      </Typography>
                      {/* <Typography
                  sx={{
                    marginLeft: 2,
                    fontSize: "0.8rem",
                    color: "text.secondary",
                    ml: "auto"
                  }}
                 
                >
                  <MoreVertIcon />
                </Typography> */}
                    </Box>

                  )}
                  renderInput={(params) => (

                    <TextField
                      {...params}
                      label={getLabel()}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      onChange={useCallback(
                        debounce(async (e) => {
                          // if(getTypeFilter(selectedContext.name)!==typeFilter){
                          //   //console.log("chagne context:"+selectedContext.name);
                          //   setTypeFilter(getTypeFilter(selectedContext.name));
                          // }
                          setInputValue(prev => {
                            //console.log(params)
                            return e.target.value
                          });

                          //setInputValue(prev=>{return e.target.value});
                        }, 250), // Delay of 500ms
                        []
                      )}
                      // onChange={async (e) => {
                      // //console.log(selectedContext.name);
                      //  //console.log(e.target.value);
                      //   setTypeFilter(getTypeFilter(selectedContext.name));
                      //   setTagEntities(await eventDispatcher.GET_ORGANIZER_BY_TYPE("tags",{}, () => {}));
                      //   setInputValue(e.target.value)
                      //   // setAutocompleteOptions(getFilteredSearchTags(searchTags,"all",e.target.value,e.target.value));

                      // }}
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <ClickAwayListener onClickAway={() => {
                              //setInputValue("");
                              setIsContextMenuOpen(false);
                            }
                            }>
                              <div ref={searchIconRef} style={{ position: 'relative' }}>
                                <IconButton onClick={handleContextMenuToggle}>
                                  <SearchIcon />
                                </IconButton>
                                {isContextMenuOpen && (
                                  <div
                                    style={{
                                      position: 'absolute',
                                      top: '120%',
                                      left: -10,
                                      zIndex: 9000,
                                      backgroundColor: 'white',
                                      border: '1px solid #ccc',
                                      borderRadius: '4px',
                                      padding: '4px 0',
                                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                      width: '150px',
                                    }}
                                  >
                                    <MenuItem onClick={() => handleContextSelection({
                                      name: "All",
                                      disableFilters: true
                                    })}>All</MenuItem>
                                    {config.searchContexts.map((context) => (
                                      <MenuItem
                                        key={context.name}
                                        onClick={() => handleContextSelection(context)}
                                      >
                                        {context.name}
                                      </MenuItem>
                                    ))}
                                  </div>
                                )}
                              </div>
                            </ClickAwayListener>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end"
                            sx={{
                              position: "absolute", // Keeps the adornment fixed
                              whiteSpace: "nowrap", // Prevent shrinking
                              flexShrink: 0,       // Prevent flex behavior causing shrinkin
                              right: 10,            // Positions the adornment to the far right

                            }}>
                            {isAnyFilterSelected && (
                              <Chip size="small" color="grey" label={selectedContext.name} className="ml-1" />
                            )}
                            {isAnyFilterSelected && (
                              <IconButton onClick={handleResetFilters}>
                                <ClearIcon />
                              </IconButton>
                            )}
                            {!isFilterDisabled && (

                              <IconButton onClick={handleFilterDialogOpen}>
                                <FilterListIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Form>
            </>);
        }}
      </Formik>
    </>
  );
}

export default SearchBar;
