import React from "react";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import ReactQuill from "react-quill";
import { Box, Typography } from "@mui/material";
import { Form, Field, useFormState, useForm} from "react-final-form";
const WYSIWYGInputField = ({handleError}) => {
    const { values } = useFormState(); 
    const form = useForm();
    
    return (
        <Box sx={{ width: "100%", mb: 2, mr: 1 }}>
            {/* WYSIWYG Editor */}
            <Field name="note">
              {({ input, meta }) => {
                if(handleError){
                  handleError(!!meta.error);
                  }

                return (
                <>
                  <ReactQuill
                    value={input.value}
                    onChange={input.onChange}
                    onBlur={input.onBlur}
                    modules={{
                      toolbar: [
                        ["bold", "italic", "underline", "strike"], // Formatting buttons
                        [{ list: "ordered" }, { list: "bullet" }], // List buttons
                        [{ header: [1, 2, 3, false] }], // Header dropdown
                       // ["link", "image"], // Link and image buttons
                        ["link"], // Link and image buttons
                      ],
                    }}
                    formats={[
                      "header",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "list",
                      "bullet",
                      "link",
                      // "image",
                    ]}
                    style={{
                      height: "100%",
                      marginBottom: "2px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                    }}
                  />

                  {/* Error Display */}
                  {meta.touched && meta.error && (
                    <Typography variant="body2" color="error" sx={{ mt: 1 }}>
                      {meta.error}
                    </Typography>
                  )}
                </>
              )}}
            </Field>
          </Box>
    );
};

export default WYSIWYGInputField;
