import dataModels from "../../config/CentralizedDataModel"

import { useUserSession } from '../../lib/session/UserSession';

import { 
  SyncLock as SyncLockIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  AddLink as AddAccessURL,
  SwitchAccount as AssociateToAccount,
  AvTimer as ExpirationInDays,
  Style as Tags,
  ContactEmergency as AdditionalInfo,
  EditNote as NoteIcon
} from "@mui/icons-material";


import Instruction from '../common/form/Instruction';
import NameForm, { getConfig as getNameFormConfig, getStyles as getNameFormStyles } from "../common/form/CustomTextField";
import UrlForm, { getConfig as getUrlFormConfig, getStyles as getUrlFormStyles } from "../common/form/CustomTextField";
//import ExpirationForm, {getConfig as getExpirdFormConfig, getStyles as getExpiredFormStyles}from "../../common/form/CustomSoloField"
import SecurePasswordForm from "./credential/AddSecurePasswordTabWiz"; 
//import AccountBindingForm from "./AddAccountBindingFormRFF";
import TagsForm from "../common/form/TagsWiz";
//import CredentialKeyValuePairs from "./credential/CredentialKeyValuePairs";
import KeyValueEntriesWiz,{getConfig as getKeyValueConfig, getStyles as  getKeyValueStyles}from "../common/form/KeyValueEntriesWiz";
//import AddNoteForm,{getConfig as getNoteFormConfig} from "../common/form/AddNoteForm";
import AddNoteForm,{getConfig as getNoteFormConfig} from "../common/form/WYSIWYGInputform";

const getStyles = () => {
  return {
   card:{
    maxWidth: 600,
    pt: 0,
    margin: "30px auto",
    backgroundColor: "white",
    boxShadow: "0 2px 10px rgba(6, 81, 237, 0.3)",
    borderRadius: "0.375rem",
    color: "#333",
   
  },
    box: {
      position: "relative",
      height: 300,
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      border: "2px dashed #ccc",
    },
    boxWithImage: {
      border: "none",
    },
    cardMedia: {
      height: "300px",
      width: "auto",
      p: "20px",
      objectFit: "contain",
    },
    addIcon: {
      fontSize: 80,
      color: "#ccc",
    },
    iconButton: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "#fff",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      },
    },
    errorText: {
      textAlign: "center",
      mt: 1,
    },
    description: {
      mt: 2,
    },
    submitButton: {
      margin: 2,
    },
  };
}

const handleError=(hasError) => {
  //console.log(hasError);
};

const InfoletDef =  () => {
  const entityType="CREDENTIALS";
  const dataModelName = 'credentialWidget';
  const userSession = useUserSession();
  const {tagEntities, setTagEntities } = userSession;
  let definition= {
    type: entityType,
    session:userSession,
    form:{
        modelDefintion: dataModels,
        widget:{
            styles:getStyles
        },
        dataModelName:dataModelName,
        formImageFieldName:"urlImage",
        formFields:[
            {
              toolTip: "Required - Enter a name this credential.",
              icon: <BadgeOutlinedIcon />,
              form: <NameForm config={{
                ...getNameFormConfig(),
                fieldName: "name",
                label: "Enter Credential Name",
                fullWidth: true,
                margin: "normal",
                variant: "outlined",
                adornmentPosition: "end",
                iconSize: "small",
                clearTip: "Clear Field"
              }} 
              styles={getNameFormStyles()} 
              handleError={handleError} 
              />,
              howto:<Instruction config={{
                "text": "Give this credential a name that's easy to remember, so you can quickly recognize it when needed.",
                // "video": "https://youtube.com/playlist?list=PLnWTBEGZoaErtqbnta-FozmKicc90AyG2&si=_3KKZHv78Uo4OhqA", 
                // "iframe": "<iframe src='https://www.example.com'></iframe>",
                // "readmore": "http://www.example.com"
              }}/>
            },
            {
              toolTip: "Optional - Enter the URL associated to this credential.",
              icon: <AddAccessURL />,
              form: <UrlForm config={
                {
                  ...getUrlFormConfig(),
                  fieldName: "url",
                  label: "Enter Access Url"
                }
              } 
                styles={getUrlFormStyles()} handleError={handleError} />,
                howto:<Instruction config={{
                  "text": "Does this credential have a URL associated with it? If it’s for a web application, enter the URL here like http://... .  Then you can access it whenever you need.",
                }}/>
            },
            {
              toolTip: "Optional - Entering the password to secure.",
              icon: <SyncLockIcon />,
              form: <SecurePasswordForm 
                //default fields names useChallenge, secureKey, securedPassword, challenge and challengePrompt
                handleError={handleError}/>,
                howto:<Instruction config={{
                  "text": "Do you need to secure a password or something else? You can secure it with your own personal or universal password and in addition a challenge quesiton and answer for extra security.  This field can not be updated once secured.  It is immutable thus when in edit context, it means to recreate.",
                }}/>
            },
        
            {
              toolTip: "Optional, you can associate this credential to Optional, to help you keep track of other essential info. Tag it!tags.",
              icon: <Tags />,
              form: <TagsForm 
                //default fieldname is 'tags'
                handleError={handleError} 
                tags={Object.values(tagEntities?tagEntities:[]).map((entry) => {
                return entry.TAG;
              })}/>,
                howto:<Instruction config={{
                "text": "Choose relevant and easy-to-remember tags to keep everything organized. To add a new tag manually, type your tag in the field, then remember click the + button or press Enter to associated this tag to the credential. Alternatively, you can browse the available tags list and click on any tag to associate it to your credential.",
                }}/>
              
            },
            {
                        toolTip: "Optional, other essential information",
                        icon: <AdditionalInfo />,
                        form:  <KeyValueEntriesWiz config={{
                          header: {
                            title: "Additional Informaiton",
                            addTooltip: "Add any additional key/value paired information such as question and answers",
                            removeAllTooltip: "Remove All Information",
                            removeTooltip: "Remove Information",
                            emptyMessage: "Click the '+' button to add key value paired information.",
                          },
                          entriesConfig:{ name: "Name", value: "Value" },
                          field: {
                            name: "additionalInformation",
                          },
                          entryLabelPrefix: "Additional Information ",
                        }} styles={getKeyValueStyles()}/>,
                        howto:<Instruction config={{
                          "text": "If you have any key/value pairs information, like question and answer pairs, you can add them here.",
                        }}/>
                        
                      },
            {
              toolTip: "Optional, for long text note about this credential.",
              icon: <NoteIcon />,
              form: <AddNoteForm 
              // default field name is 'note'
              // config={{
              //   ...getNoteFormConfig(),
              //   header: {
              //   title: "Notes"
              //   }
              // }}
              handleError={handleError} useTab={true}/>,
              howto:<Instruction config={{
                "text": "Enter any other notes you'd like to remember.",
              }}/>
              
            }
          ]
    },

    displayDef: {

      "name": {
        "label": "Credential Name:",
        "ignore": false,
        "order": 1
      },
      "url": {
        "label": "Credential Access URL:",
        "ignore": false,
        "order": 2
      },
      "useChallenge": {
        "label": "Use Challenge:",
        "ignore": true,
        "order": 3,
        "_type": "sting",
        "child": {

        }
      },
      "challengePrompt": {
        "label": "Challenge Question:",
        "ignore": true,
        "order": 4
      },
      "challenge": {
        "label": "Challenge Answer:",
        "ignore": true,
        "order": 5
      },
      
      "additionalInformation": {
        "label": "Credential Key/Value Pair Information:",
        "ignore": false,
        "order": 6,
        "_type": "map",
        "child": {
          "_type": "map",
          "name": {
            "label": "",
            "ignore": false,
            "order": 1
          },
          "value": {
            "label": "",
            "ignore": false,
            "order": 2
          }

        }
      },
      
      "tags": {
        "label": "Tags:",
        "ignore": false,
        "order": 7,
        "_type": "array",
        "child": {
          "_type": "chip",
        }

      },
    
      "note": {
        "label": "Note:",
        "ignore": false,
        "order": 8,
        "_type": "string",
        
      },

      "id": {
        "label": "Unique Id:",
        "ignore": true,
        "order": 9,
        "_type": "sting",
        "child": {

        }
      },
      "urlImage": {
        "label": "urlImage:",
        "ignore": true,
        "order": 10,
        "_type": "sting",
        "child": {

        }
      },
      "securedPassword": {
        "label": "securedPassword:",
        "ignore": true,
        "order": 11,
        "_type": "sting",
        "child": {

        }
      },
    

      "secureKey": {
        "label": "secureKey:",
        "ignore": true,
        "order": 12,
        "_type": "sting",
        "child": {

        }
      },
      
      "accounts": {
        "label": "accounts:",
        "ignore": true,
        "order": 13,
        "_type": "sting",
        "child": {

        }
      },
      "password": {
        "label": "Image Url:",
        "ignore": true,
        "order": 14,
        "_type": "sting",
        "child": {

        }
      },
      "ivs": {
        "label": "ivs:",
        "ignore": true,
        "order": 15,
        "_type": "sting",
        "child": {

        }
      }
    }

};
 
  return definition;
};

export default InfoletDef;
