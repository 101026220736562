import dataModels from "../../config/CentralizedDataModel"
import { useUserSession } from '../../lib/session/UserSession';




import {
  SyncLock as SyncLockIcon,
  BadgeOutlined as BadgeOutlinedIcon,
  AddLink as AddAccessURL,
  SwitchAccount as AssociateToAccount,
  AvTimer as ExpirationInDays,
  Style as Tags,
  ContactEmergency as AdditionalInfo,
  EditNote as NoteIcon,
  DocumentScanner as SecureAccount,
  Pin as PIN,
  DataObject as AccountBackup,
  AddLocationAlt as AddLocationAltIcon,
  PsychologyAlt as PsychologyAltIcon,
  AttachEmail as AttachEmailIcon,
  AddIcCall as AddIcCallIcon
} from "@mui/icons-material";


import Instruction from "../common/form/Instruction";
import NameForm, { getConfig as getNameFormConfig, getStyles as getNameFormStyles } from "../common/form/CustomTextField";
import KeyValueEntriesWiz, { getConfig as getKeyValueConfig, getStyles as getKeyValueStyles } from "../common/form/KeyValueEntriesWiz";
import AddressWiz, { getConfig as getAddressWizConfig, getStyles as getAddressWizStyles } from "../common/form/AddressWiz"
import TagsForm from "../common/form/TagsWiz";
import AddNoteForm from "../common/form/AddNoteForm";
import UrlForm, { getConfig as getUrlFormConfig , getStyles as getUrlFormStyles } from "../common/form/CustomTextField";

export const getStyles = () => {
  return {
    card: {
      maxWidth: 600,
      margin: "auto",
      pt: 0
    },
    box: {
      position: "relative",
      height: 300,
      backgroundColor: "transparent",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      border: "2px dashed #ccc",
    },
    boxWithImage: {
      border: "none",
    },
    cardMedia: {
      height: "300px",
      width: "auto",
      p: "20px",
      objectFit: "contain",
    },
    addIcon: {
      fontSize: 80,
      color: "#ccc",
    },
    iconButton: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      color: "#fff",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.8)",
      },
    },
    errorText: {
      textAlign: "center",
      mt: 1,
    },
    description: {
      mt: 2,
    },
    submitButton: {
      margin: 2,
    },
  };
}


const handleError = (hasError) => {
  //console.log(hasError);
};

const InfoletDef = () => {
  const entityType="GROUPS";
  const dataModelName = 'groupWidget';
  const userSession = useUserSession();
  const { tagEntities, setTagEntities } = userSession;
  let definition = {
    type: entityType,
    session: userSession,
    form: {
      modelDefintion: dataModels,
      widget: {
        styles: getStyles
      },
      dataModelName: dataModelName,
      formImageFieldName: "urlImage",
      formFields: [
        {
          toolTip: "Required - Group name for this group",
          icon: <BadgeOutlinedIcon />,
          form: <NameForm config={{
            fieldName: "name",
            label: "Enter Group Name",
            fullWidth: true,
            margin: "normal",
            variant: "outlined",
            adornmentPosition: "end",
            iconSize: "small",
            clearTip: "Clear Field"
          }} styles={getNameFormStyles()} handleError={handleError} />,
          howto: <Instruction config={{
            "text": "Give this group a name that is easy to remember, so you can quickly recognize it when needed."
          }} />
        },
        {
          toolTip: "Optional, to help you keep track of other essential info. Tag it!",
          icon: <Tags />,
          form: <TagsForm handleError={handleError} tags={Object.values(tagEntities).map((entry) => {
            return entry.TAG;
          })} />,
          howto: <Instruction config={{
            "text": "Choose relevant and easy-to-remember tags to keep everything organized. To add a tag manually, type your tag in the field, then click the + button or press Enter. Alternatively, you can browse the available tags list and click on any tag to apply it to your account.",
          }} />

        },
        {
          toolTip: "Optional, enter any addresses associated to this account i.e. home mailing, resident etc.",
          icon: <AddLocationAltIcon />,
          form: <AddressWiz styles={getAddressWizStyles()}
          />,
          howto: <Instruction config={{
            "text": "Enter any addresses associated with this group.",
          }} />

        },
        {
          toolTip: "Optional, any email addresses associated with this group.",
          icon: <AttachEmailIcon />,
          form: <KeyValueEntriesWiz config={{
            header: {
              title: "Emails",
              addTooltip: "Add Email Address",
              removeAllTooltip: "Remove All Email Addresses",
              removeTooltip: "Remove Email Address",
              emptyMessage: "Click the '+' button to add email.",
            },
            entriesConfig: { name: "name", value: "value" },
            field: {
              name: "groupEmailAddresses",
            },
            entryLabelPrefix: "Email ",
          }} styles={getKeyValueStyles()} />,
          howto: <Instruction config={{
            "text": "Enter the contact emails associated with this group.",
          }} />

        },
        {
          toolTip: "Optional, enter any telephone numbers associated with this group.",
          icon: <AddIcCallIcon />,
          form: <KeyValueEntriesWiz config={{
            header: {
              title: "Telephone Lines",
              addTooltip: "Add Telephone",
              removeAllTooltip: "Remove All Telphone Lines",
              removeTooltip: "Remove telephone line",
              emptyMessage: "Click the '+' button to add telephone line.",
            },
            entriesConfig: { name: "name", value: "value" },
            field: {
              name: "groupTelephoneLines",
            },
            entryLabelPrefix: "Line ",
          }} styles={getKeyValueStyles()} />,
          howto: <Instruction config={{
            "text": "Enter the telephone/fax/digitial lines associated with this group.",
          }} />

        },
        {
          toolTip: "Optional - Enter an ULR/website associated to this group.",
          icon: <AddAccessURL />,
          form: <UrlForm config={
            {
              ...getUrlFormConfig(),
              fieldName: "url",
              label: "Group URL Address"
            }
          } 
            styles={getUrlFormStyles()} handleError={handleError} />,
            howto:<Instruction config={{
              "text": "Does this group have a website? Enter the website URL like http://... .  Then you can access it whenever you need.",
            }}/>
        },
        {
          toolTip: "Optional, to help you keep track of other essential info.",
          icon: <PsychologyAltIcon />,
          form: <KeyValueEntriesWiz config={{
            header: {
              title: "Group Informaiton",
              addTooltip: "Additional Group Info. Entry",
              removeAllTooltip: "Remove All Group Info. Entries",
              removeTooltip: "Remove Group Info Entry",
              emptyMessage: "Click the '+' button to add group information entry.",
            },
            entriesConfig: { name: "name", value: "value" },
            field: {
              name: "groupNoteEntries",
            },
            entryLabelPrefix: "Entry #",
          }} styles={getKeyValueStyles()} />,
          howto: <Instruction config={{
            "text": "If you have any infomation, like a LinkedIn Url, bookmarks, or any key/value pair information you can add them here.",
          }} />

        },
        
        {
          toolTip: "Optional, to help you keep track of other essential info.",
          icon: <NoteIcon />,
          form: <AddNoteForm handleError={handleError} useTab={true} />,
          howto: <Instruction config={{
            "text": "Enter any other notes you'd like to remember.",
          }} />
        }
      ]

    },
    displayDef: {
      "name": {
        "label": "Group Name:",
        "ignore": false,
        "order": 1
      },
      "tags": {
        "label": "Tags:",
        "ignore": false,
        "order": 2,
        "_type": "array",
        "child": {
          "_type": "chip",
        }

      },
  
      "groupEmailAddresses": {
        "label": "Email Addresses:",
        "ignore": false,
        "order": 3,
        "_type": "map",
        "child": {
          "_type": "map",
          "name": {
            "label": "Name:",
            "ignore": false,
            "order": 1
          },
          "value": {
            "label": "Email Address:",
            "ignore": false,
            "order": 2
          }

        }
      },
      // "accountNumber": {
      //   "label": "Account Number:",
      //   "ignore": false,
      //   "order": 3
      // },
      // "pin": {
      //   "label": "Account PIN:",
      //   "ignore": false,
      //   "order": 4
      // },
      // "accountBackupCodes": {
      //   "label": "Account Backup Codes:",
      //   "ignore": false,
      //   "order": 5
      // },

      "groupTelephoneLines": {
        "label": "Digital Lines:",
        "ignore": false,
        "order": 4,
        "_type": "map",
        "child": {
          "_type": "map",
          "name": {
            "label": "Name:",
            "ignore": false,
            "order": 1
          },
          "value": {
            "label": "Line:",
            "ignore": false,
            "order": 2
          }

        }
      },
      "addresses": {
        "label": "Group Related Addresses:",
        "ignore": false,
        "order": 5,
        "_type": "array",
        "child": {
          "_type": "map",
          "name": {
            "label": "Name:",
            "ignore": false,
            "order": 1
          },
          "addressType": {
            "label": "Address Type:",
            "ignore": false,
            "order": 2
          },
          "streetAddress": {
            "label": "Street Address:",
            "ignore": false,
            "order": 3
          },
          "city": {
            "label": "City:",
            "ignore": false,
            "order": 4
          },
          "state": {
            "label": "State:",
            "ignore": false,
            "order": 5
          },
          "zipCode": {
            "label": "Zip Code:",
            "ignore": false,
            "order": 6
          }
        }
      },
      "url": {
        "label": "Group URL:",
        "ignore": false,
        "order": 6
      },
      "groupNoteEntries": {
        "label": "Group Key/Value Pair Information:",
        "ignore": false,
        "order": 7,
        "_type": "map",
        "child": {
          "_type": "map",
          "name": {
            "label": "",
            "ignore": false,
            "order": 1
          },
          "value": {
            "label": "",
            "ignore": false,
            "order": 2
          }

        }
      },
      "note": {
        "label": "Note:",
        "ignore": false,
        "order": 8,
        "_type": "string",

      },
      "id": {
        "label": "Unique Id:",
        "ignore": true,
        "order": 9,
        "_type": "sting",
        "child": {

        }
      },
      "urlImage": {
        "label": "Image Url:",
        "ignore": true,
        "order": 10,
        "_type": "sting",
        "child": {

        }
      }


    }

  };

  return definition;
};

export default InfoletDef;
