const priceplanIds={
  INDIVIDUAL_MINALIST_FREE: process.env.IMF,
  INDIVIDUAL_FREEDOM_MONTHLY: process.env.IFM,
  INDIVIDUAL_PLUS_MONTHLY: process.env.IPM,
  INDIVIDUAL_FREEDOM_YEARLY: process.env.IFY,
  INDIVIDUAL_PLUS_YEARLY: process.env.IPY,
}
let plans = [
    {
      "title": "Individual Minimalist",
      "desc": "Freemium Subscription",
      "price": ["$", "0", "month"],
      "pricePlan":"Individual Minimalist Freemium",
      "plan":"Individual Minimalist",
      "planId": "INDIVIDUAL_MINALIST",
      "pricePlanId":priceplanIds.INDIVIDUAL_MINALIST_FREE,
      "options": [
        { "icon": "CheckCircleIcon", "info": "Manage Credentials" },
        { "icon": "CheckCircleIcon", "info": "Manage Accounts" },
        { "icon": "CheckCircleIcon", "info": "Searches" },
        { "icon": "MinusCircleIcon", "info": "Manage Groups", "strokeWidth":"2.5"},
        { "icon": "MinusCircleIcon", "info": "Manage Contacts", "strokeWidth":"2.5" },
        { "icon": "MinusCircleIcon", "info": "Data Sharing", "strokeWidth":"2.5" }
      ]
    },
    {
      "title": "Individual Plus",
      "desc": "Individual Plus Monthly Subscription",
      "price": ["$4", "", "month"],
      "pricePlan":"Individual Plus Monthly",
      "plan":"Individual Plus",
      "planId": "INDIVIDUAL_PLUS",
      "pricePlanId":priceplanIds.INDIVIDUAL_PLUS_MONTHLY,
      "options": [
        { "icon": "CheckCircleIcon", "info": "Manage Credentials" },
        { "icon": "CheckCircleIcon", "info": "Manage Accounts" },
        { "icon": "CheckCircleIcon", "info": "Manage Contacts" },
        { "icon": "CheckCircleIcon", "info": "Searches" },
    
        { "icon": "MinusCircleIcon", "info": "Manage Groups", "strokeWidth":"2.5" },
        { "icon": "MinusCircleIcon", "info": "Data Sharing", "strokeWidth":"2.5" }
      ]
    },
    {
      "title": "Individual Plus",
      "desc": "Individual Plus Yearly Subscription Plan ",
      "price": ["$36", "", "year ($3/month)"],
      "pricePlan":"Individual Plus Yearly",
      "plan":"Individual Plus",
      "planId": "INDIVIDUAL_PLUS",
      "pricePlanId":priceplanIds.INDIVIDUAL_PLUS_YEARLY,
      "options": [
        { "icon": "CheckCircleIcon", "info": "Manage Credentials" },
        { "icon": "CheckCircleIcon", "info": "Manage Accounts" },
        { "icon": "CheckCircleIcon", "info": "Manage Contacts" },
        { "icon": "CheckCircleIcon", "info": "Searches" },
        { "icon": "MinusCircleIcon", "info": "Manage Groups", "strokeWidth":"2.5" },
        { "icon": "MinusCircleIcon", "info": "Data Sharing", "strokeWidth":"2.5" }
      ]
    },
    {
      "title": "Individual Freedom",
      "desc": "Individual Freedom Monthly Subscription Plan",
      "price": ["$6.25", "", "month"],
      "pricePlan":"Individual Freedom Monthly",
      "plan":"Individual Freedom",
      "planId": "INDIVIDUAL_FREEDOM",
      "pricePlanId":priceplanIds.INDIVIDUAL_FREEDOM_MONTHLY,
      "options": [
        { "icon": "CheckCircleIcon", "info": "Manage Credentials" },
        { "icon": "CheckCircleIcon", "info": "Manage Accounts" },
        { "icon": "CheckCircleIcon", "info": "Manage Contacts" },
        { "icon": "CheckCircleIcon", "info": "Manage Groups" },
        { "icon": "CheckCircleIcon", "info": "Searches" },
        { "icon": "MinusCircleIcon", "info": "Data Sharing", "strokeWidth":"2.5" }
      ]
    },

    {
      "title": "Individual Freedom",
      "desc": "Individual Freedom Yearly Subscription Plan",
      "price": ["$60", "", "year ($5/month)"],
      "pricePlan":"Individual Freedom Yearly",
      "plan":"Individual Freedom",
      "planId": "INDIVIDUAL_FREEDOM",
      "pricePlanId":priceplanIds.INDIVIDUAL_FREEDOM_YEARLY,
      "options": [
        { "icon": "CheckCircleIcon", "info": "Manage Credentials" },
        { "icon": "CheckCircleIcon", "info": "Manage Accounts" },
        { "icon": "CheckCircleIcon", "info": "Manage Contacts" },
        { "icon": "CheckCircleIcon", "info": "Manage Groups" },
        { "icon": "CheckCircleIcon", "info": "Searches" },
        { "icon": "MinusCircleIcon", "info": "Data Sharing", "strokeWidth":"2.5" }
      ]
    }
    
  ]

export default plans;