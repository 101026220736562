import React, { useState } from "react";
import ContactUsForm from "./ContactUsForm";
import DialogWrapper from "../common/DialogWrapper";

const Contact = ({ showContactUs, setShowContactUs }) => {
  return (
    <DialogWrapper
      mode="dialog"
      open={showContactUs}
      onClose={() => { setShowContactUs(false) }}
      title="Contact Us"
      style={ {
        dialog: {
          fullWidth: true,
          maxWidth: 'sm',
          p: 0,
          m: 0,
    
        },
        titleBox: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          P: 0
        },
        closeButton: {
          position: 'absolute',
          color: (theme) => theme.palette.grey[500],
          right: -15,
          top: -10,
        },
        content: {
          m: 0,
          p: 0
        },
        inlineContainer: {
          p: 0,
          m: 0
        },
      }}
    >
      <ContactUsForm dialogOn={setShowContactUs} />
    </DialogWrapper>
  )
    ;
}

export default Contact;