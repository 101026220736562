import React, { useState } from "react";
import { Menu, MenuItem, Button, Box,Typography } from "@mui/material";
import serviceTaskRunner, { services, _callRestfulService as restful } from "../../lib/restful/services/restAPIs";

// const products = [
//   {
//     title: "Individual Minimalist",
//     desc: "Freemium Subscription",
//     price: ["$", "0", "month"],
//     pricePlan: "Individual Minimalist Freemium",
//     plan: "Individual Minimalist",
//     planId: "INDIVIDUAL_MINALIST",
//     pricePlanId: "price_freemium",
//   },
//   {
//     title: "Individual Plus",
//     desc: "Individual Plus Monthly Subscription",
//     price: ["$4", "", "month"],
//     pricePlan: "Individual Plus Monthly",
//     plan: "Individual Plus",
//     planId: "INDIVIDUAL_PLUS",
//     pricePlanId: "price_1QxMylD6IjOeZ3YQBVsHnVI2",
//   },
//   {
//     title: "Individual Freedom",
//     desc: "Individual Freedom Monthly Subscription Plan",
//     price: ["$", "6.25", "month"],
//     pricePlan: "Individual Freedom Monthly",
//     plan: "Individual Freedom",
//     planId: "INDIVIDUAL_FREEDOM",
//     pricePlanId: "price_1QxO1QD6IjOeZ3YQRW43gaYK",
//   },
//   {
//     title: "Individual Plus",
//     desc: "Individual Plus Yearly Subscription Plan ",
//     price: ["$36", "", "year ($3/month)"],
//     pricePlan: "Individual Plus Yearly",
//     plan: "Individual Plus",
//     planId: "INDIVIDUAL_PLUS",
//     pricePlanId: "price_1QxMUWD6IjOeZ3YQHkYjKPrV",
//   },
//   {
//     title: "Individual Freedom",
//     desc: "Individual Freedom Yearly Subscription Plan",
//     price: ["$", "60", "year ($5/month)"],
//     pricePlan: "Individual Freedom Yearly",
//     plan: "Individual Freedom",
//     planId: "INDIVIDUAL_FREEDOM",
//     pricePlanId: "price_1QxNN5D6IjOeZ3YQWQ4EuGve",
//   },
// ];


const CheckoutSession = ({products, setPaymentLink, setShowPayment, instructRef}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleUpgradeClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handlePlanSelect = async (plan) => {
  
    if(plan.pricePlanId==="price_freemium") return true;

    let _SignUpTaskRunner = await serviceTaskRunner(services.CUSTOMER_CHECKOUT)(
        {plan:plan.plan,
        pricePlan:plan.pricePlan,
        pricePlanId:plan.pricePlanId}
        ,
        async (response, callParams) => {
          //callBack function
          //Get return data from the restufl call
          const data = response.data.payload;
          let result = data;
         
          instructRef.current=(<>   
          <Typography variant="body1" sx={{ textAlign: "center" }}>
              We're now directing you to set up your subscription plan.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, textAlign: "center" }}>
              If you don’t complete the payment setup for any reason, no worries! You can log in anytime with your freemium account and use the <strong>UPGRADE</strong> option to finalize your subscription.
          </Typography></>);
          setPaymentLink(data.url); 
          setShowPayment(true) ;
          return result;
        },
        {
          //callBack Parameters
        },
        (error) => {

          console.log(error);
          handleMenuClose();
         // throw { message: "Unable to to signup user." };
        }
      );

      handleMenuClose();
  
    
    
  };

  return (
    <Box sx={{ p: 1, maxWidth: 100,  textAlign: "center" }}>
      <Button variant="contained" color="primary" onClick={handleUpgradeClick}>
        UPGRADE
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        {products.filter((plan)=>plan.title!="Individual Minimalist").map((plan) => (
          <MenuItem key={plan.pricePlanId} onClick={async () => {await handlePlanSelect(plan)}}>
            {plan.title} ({plan.price.join(" ")})
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default CheckoutSession;
