import React from "react";
import { Form, Field } from "react-final-form";
import { Box, Button, Typography } from "@mui/material";
import {Util} from "../../../lib/util/Util";
// Configuration and Styles
const config = {
  fieldName:"fieldID",
  labels: {
    textarea: "Your Message:",
  },
  placeholders: {
    textarea: "Type your message here",
  }
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 2,
    maxWidth: 400,
    margin: "0 auto",
  },
  textarea: {
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    border: "1px solid rgb(182, 178, 178)",
    borderRadius: "5px",
    resize: "vertical",
   
  },
  textareaError: {
    border: "2px solid red",
  },
  errorText: {
    mt: 1,
  }
};


export const getConfig=()=>{
  return  Util.JSON_COPY(config);
}
export const getStyles=()=>{
  return  Util.JSON_COPY(styles);
}

const TextAreaForm = ({config=getConfig(), styles=getStyles()}) => {
  return (
          <Box sx={styles.container}>
            {/* Textarea Field */}
            <Field name={config.fieldName}>
              {({ input, meta }) => (
                <Box>
                  <label htmlFor="textarea">
                    <Typography variant="body1">{config.labels.textarea}</Typography>
                  </label>
                  <textarea
                    {...input}
                    id="textarea"
                    rows="5"
                    placeholder={config.placeholders.textarea}
                    style={{
                      ...styles.textarea,
                      ...(meta.touched && meta.error ? styles.textareaError : {}),
                    }}
                  />
                  {meta.touched && meta.error && (
                    <Typography variant="body2" color="error" sx={styles.errorText}>
                      {meta.error}
                    </Typography>
                  )}
                </Box>
              )}
            </Field>
          </Box>
  );
};

export default TextAreaForm;
