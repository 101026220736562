import Credential from "./Credential";
import Account from "./Account";
import Contact from "./Contact";
import Group from "./Group";
import User from "./User";
const InfoletDefs = (type) => {
  type = type.toUpperCase();
  switch(type){
    case "CREDENTIALS": return Credential();
    case "ACCOUNTS": return Account();
    case "CONTACTS": return Contact();
    case "GROUPS": return Group();
    case "USERS": return User();
    default:;
  }
  
};

export default InfoletDefs;
